import React from "react";

import {
  Container,
  // Customizable Area Start
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
  Box,
  Button,
  Typography,
  styled,
  IconButton
  // Customizable Area End
} from "@mui/material";

// Customizable Area Start
import { ArrowDropDown, ArrowDropUp, VisibilityOutlined } from "@mui/icons-material";
import { createTheme, ThemeProvider } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import AuditTrailDetailController, {
  Props,
} from "./AuditTrailDetailController";

export default class AuditTrailDetail extends AuditTrailDetailController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderSortableTableCell = (property: string, label: string) => {
    return (
      <CustomTableCell key={property}>
        <Box display="flex" alignItems="center" justifyContent="center">
              {label}
          <Box display="flex" flexDirection="column" marginLeft={"10px"}>
            <CustomIconButtonUp
              id="ascendingIcon"
              onClick={() => this.handleSortRequest(property, "asc")}
            >
              <ArrowDropUp />
            </CustomIconButtonUp>
            <CustomIconButtonDown
              id="descendingIcon"
              onClick={() => this.handleSortRequest(property, "desc")}
            >
              <ArrowDropDown />
            </CustomIconButtonDown>
          </Box>
        </Box>
      </CustomTableCell>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <TableRow key="auditLog">
        <TableCell colSpan={11}>
          <div
            style={{
              padding: "16px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Grid
              container
              sx={{ backgroundColor: "#F2F2F2", padding: "30px" }}
            >
              <Grid item xs={8}>
                <Box sx={{display: "flex", justifyContent: "space-between", marginBottom: "10px"}}>
                  <Typography variant="h6" sx={{fontWeight: 700}}>What's going on</Typography>
                  {this.props.seeProjectDetails && 
                    <Button
                      id="projectDetailsButton"
                      sx={webStyles.text_button}
                      onClick={this.handleProjectDetailsClick}
                    >
                      <VisibilityOutlined sx={webStyles.text_button_icon}/>
                      See project details
                    </Button>
                  }
                </Box>
                <Table>
                  <TableHead>
                    <TableRow key="tableHead">
                      {this.props.headers.map((header, index) => {
                        if(header === "Details"){
                          return <CustomTableCell key={index} align="center">
                              Details
                          </CustomTableCell>
                        }
                        return this.renderSortableTableCell(this.props.dataProperties[index], header)
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody sx={{background: "white"}}>
                    {this.state.workOrderAudits.map((row, index) => {
                      return (
                        <TableRow
                          tabIndex={-1}
                          key={`row-${index}`}
                        >
                          {this.props.dataProperties.map((property, propIndex) => {
                            return <TableCell key={`cell-${row}-${propIndex}`} align="center">
                              <Typography>{row[property]}</Typography>
                            </TableCell>
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={2}>
                    <Typography sx={{fontWeight: 700, marginBottom: "15px"}} variant="h6">Images</Typography>
                    <Grid key="imageContainer" container spacing={2}>
                      {this.props.documents.map((image, imageIndex) => {
                        return <Grid key={imageIndex} item xs={6}>
                          <img
                            src={image.file_url}
                            height="100%"
                            width="100%"
                          />
                        </Grid>
                      })}
                    </Grid>
              </Grid>
              <Grid item xs={1}></Grid>
            </Grid>
          </div>
        </TableCell>
      </TableRow>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyles = {
  text_button: {
    color: "#DAA520",
    fontSize: "17px",
    fontWeight: "700",
    marginLeft: "10px",
    textTransform: "capitalize",
  },
  text_button_icon: {
    color: "#DAA520",
    height: "20px",
    marginTop: "-5px",
    marginRight: "5px"
  },
};

const CustomTableCell = styled(TableCell)({
  color: "#A3978F",
  textAlign: "center",
  fontSize: "15px",
  fontWeight: 700,
  padding: "0px 10px 0px 10px !important",
  margin: "auto",
});

const CustomIconButtonUp = styled(IconButton)({
  cursor: "pointer",
  height: "12px",
  width: "12px",
});

const CustomIconButtonDown = styled(IconButton)({
  cursor: "pointer",
  height: "12px",
  width: "12px"
});
// Customizable Area End
