import React, { Component } from 'react';
import { TextField, Box } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { styled } from "@mui/material/styles";
import { CalendarToday } from "@mui/icons-material";

const CustomTextField = styled(TextField)({
  '& .MuiInputBase-input': {
    marginLeft: '25px',
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: 'none'
  }
});

const CalendarIconToday = styled(CalendarToday)({
  color: "#CC9200",
  height: "20px",
  width: "20px"
});

const MainContainer = styled(Box)({
  position: "relative",
  display: "inline-block",
  width: "80px"
});

interface CustomPickerProps {
  value: Date | null;
  onChange: (date: Date | null) => void;
  required?: boolean;
  open?: boolean;
}

class CustomPicker extends Component<CustomPickerProps> {

  render() {
    const { value, onChange, open, required } = this.props;

    return (
      <MainContainer>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            components={{ OpenPickerIcon: CalendarIconToday }}
            value={value}
            onChange={onChange}
            open={open}
            renderInput={(params) => (
              <CustomTextField
                required={required}
                size="small"
                {...params}
              />
            )}
            inputFormat="MM-dd-yyyy"
            disablePast
          />
        </LocalizationProvider>
      </MainContainer>
    );
  }
}

export default CustomPicker;