export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const keasyLogo = require("../assets/keasyLogo.svg");
export const facebook = require("../assets/facebook.svg");
export const instagram = require("../assets/instagram.svg");
export const twitter = require("../assets/twitter.svg");
export const keasyFont = require("../assets/keasyFont.svg");
export const backgroundForgetPassword = require("../assets/backgroundForgetPassword.svg");
export const backgroundRecoveryPassword = require("../assets/backgroundRecoveryPassword.svg");
export const fullHexagon = require("../assets/fullHexagon.svg");
export const backgroundPassImg = require("../assets/backgroundPassImg.svg");
