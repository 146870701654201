import React, {Component} from 'react';
import { TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { styled } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AccessTime } from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";

const CustomTextField = styled(TextField)(({ theme }) => ({
    '& .MuiInputBase-input': {
      marginLeft: '25px',
    },
  }));

  interface CustomTimePickerProps {
    value: Date | null;
    onChange: (date: Date | null) => void;
    required?: boolean;
  }
  
  class CustomTimePicker extends Component<CustomTimePickerProps> {
    state = {
      open: false,
    };

    handleOpen = () => {
      this.setState({ open: true });
    };
    
    render() {
      const { value, onChange, required } = this.props;
  
      return (
        <ClickAwayListener onClickAway={() => this.setState({ open: false })} mouseEvent="onMouseDown">
        <div style={{ position: "relative", display: "inline-block", width: "100%" }}>
          <AccessTime
            style={{
              position: "absolute",
              left: "10px",
              top: "-5px",
              transform: "translateY(50%)",
              pointerEvents: "none",
              color: "#757575",
              backgroundColor: "white",
            }}
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
              value={value}
              onChange={onChange}
              open={this.state.open}
              onClose={() => this.setState({ open: false })}
              renderInput={(params) => (
                <CustomTextField
                  required={required}
                  size="small"
                  fullWidth
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    style: { borderRadius: 8 },
                  }}
                  onClick={this.handleOpen}
                />
              )}
              inputFormat="hh:mm a"
            />
          </LocalizationProvider>
          <KeyboardArrowDownIcon
            style={{
              position: "absolute",
              right: "10px",
              top: "50%",
              transform: "translateY(-50%)",
              pointerEvents: "none",
              color: "#757575",
              backgroundColor: "white",
            }}
          />
        </div>
        </ClickAwayListener>
      );
    }
  }
  
  export default CustomTimePicker;