Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "automatedworkflow";
exports.labelBodyText = "automatedworkflow Body";
exports.dashboardLandloard1 = "Dashboard"
exports.serviderequest = "Service Request"
exports.tenentNR = "Lease"
exports.financialandloardnewR = "Payment History"
exports.chatLandloard1 = "Check with keasy"
exports.btnExampleTitle = "CLICK ME";

exports.dashboardLandloard2 = "Dashboard"
exports.serviderequest2 = "Service Request"
exports.tenentNR2 = "Lease"
exports.financialandloardnewR2 = "Payment History"
exports.chatLandloard2 = "Check with keasy"

exports.selectedCount = "No";

exports.idNamesd = "ID";
exports.createdNamesd = "Requested by";
exports.categoryNamesd = "Category";
exports.workNeedNamesd = "Work needed";
exports.assignVendorNamesd = "Assigned vendor";
exports.estimatedCostNamesd = "Estimated cost";
exports.statusNamesd = "Current status";
exports.descriptionNamesd = "Description";
exports.goingOnNamesd = "What's going on";
exports.propertiesHeadsd = "Properties";
exports.newWorkOrderssd = "New work order";

exports.dateName = "Date";
exports.updatedName = "Updated by";
exports.viewCompleted = "View completed";
exports.viewScheduled = "View scheduled";
exports.scheduledDate = "Scheduled date";
exports.detailsName = "Details";
exports.costName = "Cost";
exports.statusChangeName = "Status Change";
exports.imagesUpload = "Images";
exports.completed = "Complete";
exports.transfer = "Transfer to another vendor";
exports.orderOpen = "Open work order";
exports.editWork = "Edit work order";
exports.decline = "Decline work order";


// Customizable Area End