import React from "react";
// Customizable Area Start
import { Box, Grid } from "@mui/material";
import NavigationSidebar from "../../../components/src/NavigationSidebar.web";
import { activeMainDashboard, activeMainSetting, activeMainAccount, activeMainOrder, activeMainVendor, activeMainReminder, activePropertyMain, activeMainFinancial, activeMainReport, keyMainChain, activeKeyMain, settingsMain, keasyIcon, accountsMain, dashboardLandLogo, financialMain, ordersMain, propertyMain, remindersMain, reportMain, vendorMain } from "./assets";
// Customizable Area End
import DashboardController, {
  Props,
  webConfigJSON
} from "./DashboardController.web";

export default class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      //Merge Engine DefaultContainer
      <Box>
        <Grid container>
          <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
            <NavigationSidebar
              data-test-id={"sidebarMenuId"}
              activeItem={this.state.activeMainItem}
              sidebarItems={[
                { label: webConfigJSON.dashboardLandloard, labelKey: webConfigJSON.dashboardLandlabel, icon: dashboardLandLogo, activeIcon: activeMainDashboard, pathName: webConfigJSON.dashboardLandlabel },
                { label: webConfigJSON.myAccountlandloard, labelKey: webConfigJSON.myAccountlandlabel, icon: accountsMain, activeIcon: activeMainAccount, pathName: webConfigJSON.myAccountlandlabel },
                { label: webConfigJSON.worklandloard, labelKey: webConfigJSON.workOrderslandlabel, icon: ordersMain, activeIcon: activeMainOrder, pathName: webConfigJSON.workOrderslandlabel },
                { label: webConfigJSON.preferredlandloard, labelKey: webConfigJSON.prevendorlandlabel, icon: vendorMain, activeIcon: activeMainVendor, pathName: webConfigJSON.prevendorlandlabel },
                { label: webConfigJSON.reminderslandloard, labelKey: webConfigJSON.reminlandlabel, icon: remindersMain, activeIcon: activeMainReminder, pathName: webConfigJSON.reminlandlabel },
                { label: webConfigJSON.myproplandloard, labelKey: webConfigJSON.myproplandlabel, icon: propertyMain, activeIcon: activePropertyMain, pathName: webConfigJSON.myproplandlabel },
                { label: webConfigJSON.financialandloard, labelKey: webConfigJSON.financlandlabel, icon: financialMain, activeIcon: activeMainFinancial, pathName: webConfigJSON.financlandlabel },
                { label: webConfigJSON.reportlandloard, labelKey: webConfigJSON.reportlandlabel, icon: reportMain, activeIcon: activeMainReport, pathName: webConfigJSON.reportlandlabel },
                { label: webConfigJSON.chatLandloard, labelKey: webConfigJSON.chatlandlabel, icon: keyMainChain, activeIcon: activeKeyMain, pathName: webConfigJSON.chatlandlabel },
                { label: webConfigJSON.settingLandlord, labelKey: webConfigJSON.settinglandlabel, icon: settingsMain, activeIcon: activeMainSetting, pathName: webConfigJSON.settinglandlabel }
              ]}
              onClickSidebar={this.handleMainItemClick}
              keasylogo={keasyIcon}
              openDrawer={this.state.openLandlordDrawer}
              onClickDrawer={this.handleLandlordDrawer}
            />
          </Grid>
          <Grid item xl={10} lg={10} md={10} sm={12} xs={12}>
            {webConfigJSON.landlord}
          </Grid>
        </Grid>
      </Box>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

// Customizable Area End
