import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config.js");
// Customizable Area Start
const navigation = require("react-navigation");
// Customizable Area End

export interface Props {
  navigation: typeof navigation;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  activeSelected: string;
  openSnack: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}
export default class SelectUserProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      activeSelected: "",
      openSnack: false,
      // Customizable Area End
    };
  }
  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleUserProfile = (isSelected: string) => {
    this.setState({ activeSelected: isSelected });
  };

  handleSignUpProfile = () => {
    const { activeSelected } = this.state;
    localStorage.setItem('activeSelected', activeSelected);
    if (activeSelected === "") {
      this.setState({
        openSnack: true
      })
    } else {
      const toSendMessage = new Message(getName(MessageEnum.NavigationMessage));
      toSendMessage.addData(
        getName(MessageEnum.NavigationTargetMessage),
        "EmailAccountRegistration"
      );
      toSendMessage.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
      );
      this.send(toSendMessage);
    }
  };

  handleClose = () => {
    this.setState({
      openSnack: false,
    });
  };
  // Customizable Area End
}
