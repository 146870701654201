// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "framework/src/Utilities";

export interface IListItem  {
  id: string,
  type: string,
  attributes:{
    filename: null,
    file:{
      id: number,
      url: string,
      type: string
    },
    file_format: string
  }
}

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  totalItems: number;
  itemsPerPage: number;
}

interface PropertyAttributes {
  property_type: string;
  property_name: string;
  property_marketing_name: string | null;
  marketing_description: string | null;
  lising_type: string;
  vacancy_posting_email_address: string | null;
  warranty_coverage: boolean;
  unit_entry_pre_authorized: boolean;
  alarm_system: boolean | null;
  laundry_room: boolean | null;
  country: string;
  site_manager_prefix: string;
  site_manager_first_name: string;
  dogs_allowed: boolean;
  other_pets_allowed: boolean;
  site_manager_last_name: string;
  year_built: string;
  cats_allowed: boolean;
  other_pets: string[];
  description: string | null;
  portfolio: string;
  property_class: string;
  management_start: string;
  folio_guard_policy: boolean;
  rental_license: boolean;
  vacancy_posting_area_code: string | null;
  vacancy_posting_phone_number: string | null;
  minimum_management_fee: number | null;
  maintenance_type: string | null;
  maintenance_notes: string | null;
  rental_license_files: string | null;
  maintenance_request: string | null;
  insurance_expiration: string;
  renewal_date: string;
  marketing_plan: boolean | null;
  snow_plan: boolean | null;
  floor: string | null;
  entrance: string | null;
  code: string | null;
  address: string | null;
  location_notes: string | null;
  marketing_information_files: string | null;
  property_attachments: string | null;
  units: Unit[];
}
export interface PropertyLabel {
  id: string;
  label: string;
}

export interface Property {
  property_class: string;
  management_start: string;
  folio_guard_policy: boolean;
  rental_license: boolean;
  property_type: string;
  property_name: string;
  country: string;
  cats_allowed: boolean;
  renewal_date: string;
  dogs_allowed: boolean;
  other_pets_allowed: boolean;
  other_pets: string[];
  description: string;
  portfolio: string;
  site_manager_prefix: string;
  site_manager_first_name: string;
  site_manager_last_name: string;
  year_built: string;
  property_marketing_name: string;
  floor: string;
  entrance: string;
  code: string;
  unit_entry_pre_authorized: boolean;
  alarm_system: boolean;
  laundry_room: boolean;
  marketing_plan: boolean;
  location_notes: string;
  rental_license_files: string | null;
  marketing_information_files: string | null;
  minimum_management_fee: number;
  insurance_expiration: string;
  warranty_coverage: boolean;
  snow_plan: boolean;
  marketing_description: string;
  lising_type: string;
  vacancy_posting_email_address: string;
  vacancy_posting_area_code: string;
  vacancy_posting_phone_number: string;
  maintenance_type: string | null;
  maintenance_notes: string;
  maintenance_request: string;
  property_attachments: string | null;
  tenant: TenantData[];
}

export interface TenantData {
  id: number;
  name: string;
  date_of_birth: string;
  address: string;
  email_address: string;
  area_code: string;
  phone_number: string;
  renewal_date: string;
  class_of_vehicle: string;
  model: string;
  vehicle_registration_plate: string;
  landlord_account_id: number;
  property_id: number;
  created_at: string;
  updated_at: string;
}

export interface PropertyData {
  id: string;
  type: string;
  attributes: PropertyAttributes;
}

interface PropertyRes {
  id: string;
  type: string;
  attributes: PropertyAttributes;
}

interface PropertyAttributes {
  property_type: string;
  property_name: string;
  description: string | null;
  portfolio: string;
  site_manager_last_name: string;
  country: string;
  cats_allowed: boolean;
  dogs_allowed: boolean;
  site_manager_prefix: string;
  site_manager_first_name: string;
  other_pets_allowed: boolean;
  other_pets: string[];
  year_built: string;
  folio_guard_policy: boolean;
  property_class: string;
  management_start: string;
  rental_license: boolean;
  vacancy_posting_area_code: string | null;

  insurance_expiration: string;
  warranty_coverage: boolean;
  unit_entry_pre_authorized: boolean;
  alarm_system: boolean | null;
  vacancy_posting_phone_number: string | null;
  minimum_management_fee: number | null;
  laundry_room: boolean | null;
  renewal_date: string;
  property_marketing_name: string | null;
  marketing_description: string | null;
  lising_type: string;
  vacancy_posting_email_address: string | null;
  marketing_plan: boolean | null;
  snow_plan: boolean | null;
  maintenance_type: string | null;
  maintenance_notes: string | null;
  maintenance_request: string | null;
  rental_license_files: string | null;
  floor: string | null;
  address: string | null;
  entrance: string | null;
  code: string | null;
  location_notes: string | null;
  marketing_information_files: string | null;
  property_attachments: string | null;
  units: Unit[];
}

interface ApiResponse {
  data: PropertyRes[];
  errors?: string;
}

export interface TenantInfo {
  tenant: Tenant;
  comment: string[];
}

interface Tenant{
  id: number;
    name: string;
    date_of_birth: string;
    address: string;
    email_address: string;
    area_code: string;
    phone_number: number;
    renewal_date: string;
    class_of_vehicle: string;
    model: string;
    vehicle_registration_plate: string;
    landlord_account_id: number;
    property_id: number;
    created_at: string;
    updated_at: string;
    brand_name: string;
    type_of_pet: string;
    pet_name: string;
    pet_age: string | null;
    unit_id: number;
    bet_0_to_30: number;
    bet_30_plus: number;
    payment_0_to_30_days_late: number;
    payment_over_0_to_30_days_late: number;
    status: string;
    last_payment_date: string;
    lease_start_date: string | null;
    lease_end_date: string | null;
    date_of_first_payment_due: string | null;
    pay_period: string | null;
    rent_amount_per_month: number | null;
    security_deposit: number | null;
    payment_method: string | null;
    collected_by: string | null;
    number_of_occupants: number | null;
}
export interface Unit {
  id: number;
  unit_name: string | null;
  status: string;
  tenants: Data[];
  landlord_work_orders: string[];
}

export interface Data {
  comment: string[];
  tenant: Tenant;
}

interface Tenant {
  vehicle_registration_plate: string;
  landlord_account_id: number;
  property_id: number;
  created_at: string;
  updated_at: string;
  id: number;
  name: string;
  date_of_birth: string;
  address: string;
  model: string;
  brand_name: string;
  type_of_pet: string;
  email_address: string;
  area_code: string;
  security_deposit: number | null;
  payment_method: string | null;
  collected_by: string | null;
  number_of_occupants: number | null;
  phone_number: number;
  renewal_date: string;
  class_of_vehicle: string;
  pet_name: string;
  pet_age: string | null;
  status: string;
  last_payment_date: string;
  lease_start_date: string | null;
  payment_0_to_30_days_late: number;
  payment_over_0_to_30_days_late: number;
  pay_period: string | null;
  rent_amount_per_month: number | null;
  lease_end_date: string | null;
  date_of_first_payment_due: string | null;
  unit_id: number;
  bet_0_to_30: number;
  bet_30_plus: number;
}
export interface PropertyDataOut {
  property_type: string;
  other_pets_allowed: boolean;
  rental_license_files: string | null;
  marketing_information_files: string | null;
  property_attachments: string | null;
  other_pets: string[];
  description: string;
  portfolio: string;
  site_manager_prefix: string;
  property_name: string;
  country: string;
  cats_allowed: boolean;
  dogs_allowed: boolean;
  site_manager_first_name: string;
  site_manager_last_name: string;
  year_built: string;
  renewal_date: string;
  property_marketing_name: string;
  marketing_description: string;
  lising_type: string;
  vacancy_posting_email_address: string;
  vacancy_posting_area_code: string;
  vacancy_posting_phone_number: string;
  minimum_management_fee: number;
  property_class: string;
  management_start: string;
  folio_guard_policy: boolean;
  rental_license: boolean;
  insurance_expiration: string;
  warranty_coverage: boolean;
  unit_entry_pre_authorized: boolean;
  alarm_system: boolean;
  maintenance_request: string;
  floor: string;
  entrance: string;
  code: string;
  address: string | null;
  location_notes: string;
  laundry_room: boolean;
  marketing_plan: boolean;
  snow_plan: boolean;
  maintenance_type: string | null;
  maintenance_notes: string;
  units: Unit[];
}

export interface TenantProp {
  id: number;
  name: string;
  date_of_birth: string;
  address: string;
  email_address: string;
  area_code: string;
  phone_number: number;
  renewal_date: string;
  class_of_vehicle: string;
  model: string;
  vehicle_registration_plate: string;
  landlord_account_id: number;
  property_id: number;
  created_at: string;
  updated_at: string;
  brand_name: string;
  type_of_pet: string;
  pet_name: string;
  pet_age: number | null;
  unit_id: number;
  bet_0_to_30: number;
  bet_30_plus: number;
  payment_0_to_30_days_late: number;
  payment_over_0_to_30_days_late: number;
  status: string;
  last_payment_date: string;
  lease_start_date: string | null;
  lease_end_date: string | null;
  date_of_first_payment_due: string | null;
  pay_period: string | null;
  rent_amount_per_month: number | null;
  security_deposit: number | null;
  payment_method: string | null;
  collected_by: string | null;
  number_of_occupants: number | null;
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  indexArr: IListItem[];
  videoArr: IListItem[];
  audioArr: IListItem[];
  documentsArr: IListItem[];
  imageAll: boolean;
  audioAll: boolean;
  videoAll: boolean;
  documentsAll: boolean;
  activeMyproperty: string;
  openLandlordDrawermypro: boolean;
  isPopupVisible:boolean;
  popupPosition: boolean;
  isPopupVisibletwo:boolean;
  isSecondaryPopupVisible: boolean,
  isOpenDialog: boolean,
  isOpen: boolean;
  currentPage: number;
  totalPages: number;
  propertiedData: PropertyData[],
  token: string;
  searchQuery: string;
  searchData: PropertyLabel[],
  filterData: PropertyData[],
  lastChar: string,
  keyData: PropertyData[],
  propertiInfoData: PropertyData[],
  isUnitOpen: boolean,
  unitName: string,
  propertyId: string,
  queryData: string | undefined
}

interface INavigateTo {
  props: unknown;
  screenName: string
}

interface SS {
  id: any;
}

export default class PortfolioManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  indexAPICallID: string="";
  videosAPICallID: string="";
  audiosAPICallID: string="";
  documentsAPICallID: string="";
  getProperties: string="";
  getSearchProperties: string="";
  getPropertiesInfo: string="";
  postUnit: string="";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials)
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      indexArr: [],
      videoArr: [],
      audioArr: [],
      documentsArr: [],
      imageAll: false,
      audioAll: false,
      videoAll: false,
      documentsAll: false,
      activeMyproperty: "landlordproperties",
      openLandlordDrawermypro: false,
      isPopupVisible:false,
      popupPosition: false,
      isPopupVisibletwo:false,
      isSecondaryPopupVisible: false,
      isOpenDialog:false,
      propertiedData: [],
      token: "",
      isOpen: false,
      currentPage: 1,
      totalPages: Math.ceil(props.totalItems / props.itemsPerPage),
      searchQuery: "",
      searchData: [],
      filterData: [],
      lastChar: "",
      keyData: [],
      propertiInfoData: [],
      isUnitOpen: false,
      unitName: "",
      propertyId: "",
      queryData: ""
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  getPropertiesApiData = (response: ApiResponse) => {
    if(!response.errors){
      const resData = response?.data.map((property:PropertyRes) => property);
      const filterData = response?.data;
      this.setState({ propertiedData: resData, filterData: filterData})
      return response
    }
  }

  filterApiData = (response: ApiResponse) => {
    if(!response.errors){
      const resSearchData = response?.data?.map((property:PropertyRes) => property);
      this.setState({ propertiedData: resSearchData});
      return response
    }
  }

  getPropertiesInfoApiData = (response: ApiResponse) => {
    if(!response.errors){
     const resData = response?.data.map((property:PropertyRes) => property);
      this.setState({ propertiInfoData: resData});
     return response
    }
  }

  postUnitApiData= (response: ApiResponse) => {
    if(!response.errors){
    this.getPropertiesData();
     return response
    }
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    const apiRequestCallId1 = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson1 = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId1 && responseJson1) {
      if (apiRequestCallId1 === this.indexAPICallID && !responseJson1.errors) {
        this.setState({
          indexArr: responseJson1.data,
        });
      } else if (
        apiRequestCallId1 === this.videosAPICallID &&
        !responseJson1.errors
      ) {
        this.setState({
          videoArr: responseJson1.data,
        });
      } else if (
        apiRequestCallId1 === this.audiosAPICallID &&
        !responseJson1.errors
      ) {
        this.setState({
          audioArr: responseJson1.data,
        });
      }
      if (
        apiRequestCallId1 === this.documentsAPICallID &&
        !responseJson1.errors
      ) {
        this.setState({
          documentsArr: responseJson1.data,
        });
      }

      switch (apiRequestCallId1) {
        case this.getProperties:
          this.getPropertiesApiData(responseJson1);
          break;
  
        case this.getSearchProperties:
          this.filterApiData(responseJson1);
          break;

        case this.getPropertiesInfo:
          this.getPropertiesInfoApiData(responseJson1);
          break; 
        case this.postUnit:
          this.postUnitApiData(responseJson1)
          break 
      }
    }
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  touchableViewContainer = {
    onPress: () => this.hideKeyboard(),
  };

  videoSeeAllContainer = {
    onPress: () => {
      this.setState({videoAll: true});
      this.props.navigation.navigate("SelectedFile", {
        type: "video",
      });
    },
  };

  imageSeeAllContainer = {
    onPress: () => {
      this.setState({imageAll: true});
      this.props.navigation.navigate("SelectedFile", {
        type: "image",
      });
    },
  };

  audioSeeAllContainer = {
    onPress: () => {
      this.setState({audioAll: true})
      this.props.navigation.navigate("SelectedFile", {
        type: "audio",
      });
    },
  };

  documentSeeAllContainer = {
    onPress: () => {
      this.setState({documentsAll: true});
      this.props.navigation.navigate("SelectedFile", {
        type: "application",
      });
    },
  };

  imageAPI = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.indexAPICallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_portfolio_management/items/?file_format=image`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  audioAPI = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.audiosAPICallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_portfolio_management/items/?file_format=audio`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  documentsAPI = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.documentsAPICallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_portfolio_management/items/?file_format=application`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  videosAPI = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.videosAPICallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_portfolio_management/items/?file_format=video`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };


  async componentDidMount() {
    super.componentDidMount();
    this.imageAPI();
    this.videosAPI();
    this.audioAPI();
    this.documentsAPI();
    const token = await getStorageData('authToken');
    const propertyId = await getStorageData('propertyId');
    this.setState({ token: token, propertyId: propertyId}, () => {
      this.getPropertiesData();
      this.getFilterData()
    })
  }
  handleMainItemMyproClicks = (labelKey: string) => {
    this.setState({ activeMyproperty: labelKey },() => {
      this.handleDashboardMenu()
    });
  
   
  };

  handleDashboardMenu = () => {
    const { activeMyproperty } = this.state;
    const toMsg = new Message(getName(MessageEnum.NavigationMessage));
    toMsg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      activeMyproperty
    );
    toMsg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(toMsg);
  };

  handleLandlordMyproDrawers = () => {
    this.setState({
      openLandlordDrawermypro: !this.state.openLandlordDrawermypro
    });
  };
  handleClickpro = () => {
    this.setState((prevState) => ({
      isPopupVisible: !prevState.isPopupVisible
    }));
  }
  handleClickblv = (id: string, label: string) => {
     const newSearchItem = {
        id: id, 
        label: label
      };
     this.setState((prevState) => ({
       popupPosition: !prevState.popupPosition, isPopupVisible:false,
       isPopupVisibletwo:false,
       searchData: [...prevState.searchData, newSearchItem]
      }), () => {
        this.getFilterData()
      });
  }

  handleClickOpendialog = (idData: string) => {
    const allData = this.state.propertiedData.map((data: PropertyData) => {
      return data
    })

    const keyData = allData.filter((data: PropertyData) => {
      return data.id === idData
    })
    this.setState(
      {isOpenDialog:true, keyData: keyData

      }, () => {
        this.getPropertiesData()
      })
  }
  handleCloseDialog = () =>{
    this.setState({isOpenDialog:false})
  }
  handleCloseopopup = () =>{
    this.setState({isOpenDialog:false})
  }
  handleClickunitfalse = () =>{
    this.setState({  isPopupVisibletwo:false,isPopupVisible:false})
  }

  handleClickOpen =(idData: string)=> {
    this.setState({ isOpen: true }, () => {
      this.getPropertiesInfoData(idData)
    });
  }

  handleClose =()=> {
    this.setState({ isOpen: false });
  }


  navigateTo = ({
    props,
    screenName,
    }: INavigateTo) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationPropsMessage), props);
    message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
    runEngine.sendMessage(message.id, message);
    }  
    
    getPropertiesData = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getProperties = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProperty
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getSearchPropertiesData = () => {
    
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSearchProperties = requestMessage.messageId;

    const endpoint = this.state.searchQuery.length > 0
      ? `${configJSON.getPropertySearch}?property[]=${this.state.searchQuery}`
      : configJSON.getPropertySearch;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
   
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

  }

  formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const monthName = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
  
    return `${day} ${monthName} ${year}`;
  };

  formatYear = (yearString: string) => {
    const date = new Date(yearString);
    const year = date.getFullYear();
  
    return `${year}`;
  };

  handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    const propData = this.state.filterData.map((data:PropertyData)=> data.attributes.property_name)
    const search = propData.find((data: string) => data === query)
    this.setState({ searchQuery: query, queryData: search }, () => {
      this.getSearchPropertiesData();
    });
  };

  getFilterData = () => {
    const params = new URLSearchParams();

    this.state.searchData.forEach((query: PropertyLabel) => {
      params.append('property[]', query.label);
    });

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSearchProperties = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPropertySearch}?${params.toString()}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header),
    );
   
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleDelete = (keyId: string) => {
    const chipId = this.state.searchData.filter((dataId: PropertyLabel) => {
      return dataId.id !== keyId
    })
    this.setState({ searchData: chipId}, () => {
      this.getFilterData()
    })
  }

  handleAllDelete = () => {
    this.setState({ searchData: []}, () => {
      this.getFilterData()
    })
  }

  getPropertiesInfoData = (idData: string) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPropertiesInfo = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPropertyInfo}?property_id=${idData}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleCloseUnit =()=> {
    this.setState({ isUnitOpen: false, unitName:"" });
  }

  handleClickUnit = () => {
    this.setState({ isUnitOpen: true})
  }

  onUnitChange = (value: string) => {
    this.setState({ unitName: value });
  };

  handleAdd = () => {
  
  this.setState({ isUnitOpen: false, unitName: "" });

   const bodyData = {
      "unit": 
        {
        "property_id": this.state.propertyId,
        "status": "vacant",
        "name": this.state.unitName
        },
    }

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token:this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.postUnit = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postUnit
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyData)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
}

handleClick = (number: string) => {
  this.handleDelete(number)
}
}

// Customizable Area End
