import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { slider1, slider2, slider3 } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  index: number;
  imgData: Array<{ id:number; img: string; heading: string; text: string }>;
  backgroundImage: string;
  touchStartX: number;
  touchEndX: number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CarouselDisplayController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  swiper: any;
  intervalId: number | NodeJS.Timeout;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
      this.intervalId = setInterval(() => {
        // Your interval logic here
      }, 2000);
    // Customizable Area End
    this.subScribedMessages = [
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      index: 0,
      imgData: [
        {
          id: 1,
          img: slider1,
          heading: "I’m a landlord",
          text: "Empowering Landlords to efficiently manage properties, request maintenance, and maintain detailed records with ease."
        },
        {
          id: 2,
          img: slider2,
          heading: "I’m a vendor",
          text: "Empowering Vendors to efficiently provide maintenance and other services, and maintain detailed records with ease."
        },
        {
          id: 3,
          img: slider3,
          heading: "I’m a tenant",
          text: "Empowering Tenants to efficiently manage their detailed records, lease and agreements, raise work orders for vendors and make payments with ease."
        }
      ],
      backgroundImage: slider1,
      touchStartX: 0,
      touchEndX: 0,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidMount() {
    return Promise.resolve().then(() => {
      this.startAutoSlide();
    });
  }

  componentWillUnmount() {
    return Promise.resolve().then(() => {
      clearInterval(this.intervalId  as number);
    });
  }

  startAutoSlide = () => {
    this.intervalId = setInterval(this.handleNext, 2000);
  }

  resetAutoSlide = () => {
    clearInterval(this.intervalId as number);
    this.startAutoSlide();
  }

  handlePrev = () => {
    this.setState((prevState) => ({
      index: (prevState.index - 1 + this.state.imgData.length) % this.state.imgData.length,
      backgroundImage: this.state.imgData[(prevState.index - 1 + this.state.imgData.length) % this.state.imgData.length].img,
    }),
    this.resetAutoSlide
    );
  };
  
  handleNext = () => {
    this.setState((prevState) => ({
      index: (prevState.index + 1) % this.state.imgData.length,
      backgroundImage: this.state.imgData[(prevState.index + 1) % this.state.imgData.length].img,
    }),
    this.resetAutoSlide
    );
  };

  handleTouchStart = (event: React.TouchEvent) => {
    this.setState({ touchStartX: event.changedTouches[0].screenX });
  };

  handleTouchMove = (event: React.TouchEvent) => {
    this.setState({ touchEndX: event.changedTouches[0].screenX });
  };

  handleTouchEnd = () => {
    const { touchStartX, touchEndX } = this.state;
    const touchThreshold = 50; 

    if (touchStartX - touchEndX > touchThreshold && touchStartX!==0 && touchEndX!==0) {
      this.handleNext();
    } else if (touchEndX - touchStartX > touchThreshold && touchStartX!==0 && touchEndX!==0) {
      this.handlePrev();
    }

    this.setState({ touchStartX: 0, touchEndX: 0 });
  };

  thumbnailControl = (index: number) => {
    let currentIndex = this.state.index;
    if (currentIndex !== index) {
      let resultSlide = 0;
      let countSlides = this.state.imgData.length;

      if (index > currentIndex && index !== countSlides) {
        resultSlide = index - currentIndex;
      } else if (index > currentIndex && index === countSlides) {
        resultSlide = currentIndex + 1;
      } else if (index < currentIndex && index !== 0) {
        resultSlide = (currentIndex - index) * -1;
      } else if (index < currentIndex && index === 0) {
        resultSlide = currentIndex * -1;
      }
      this.swiper.scrollBy(resultSlide, true);
    }
  };
  // Customizable Area End
}
