import React from "react";

import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  TouchableWithoutFeedback,
  Platform
  // Customizable Area Start
  // Customizable Area End
} from "react-native";

// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  IconButton,
  Grid,
  Alert,
} from "@mui/material";
import { styled } from "@mui/system";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import ImageCollection from "@mui/icons-material/Collections";
import CameraAltIcon from "@mui/icons-material/CameraAlt";

const FileDetails = styled(Box)(({ theme }) => ({
  alignItems: "center",
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`,
  marginTop: theme.spacing(2),
}));
// Customizable Area End

import CameraAccessController, {
  Props,
  configJSON
} from "./CameraAccessController";

export default class CameraAccess extends CameraAccessController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  fileInput: HTMLInputElement | null = null;
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box sx={{ marginBottom: "16px" }}>
        <Box>
          <Button data-test-id="cameraButton" onClick={() => this.fileInput?.click()}>
            <CameraAltIcon fontSize="large" color="primary" />
          </Button>
          <input
            type="file"
            data-test-id="fileInput"
            accept=".png,.jpeg,.jpg,.mp4,.webm,.ogg"
            style={{ display: "none" }}
            ref={(input) => (this.fileInput = input)}
            onChange={(event) => this.onChange(event)}
          />
        </Box>
        {this.state.error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {this.state.error}
          </Alert>
        )}
        {this.state.files.map((file) => (
          <FileDetails key={file.name}>
            <Grid display={"flex"} justifyContent={"space-between"}>
              <Box display={"flex"} alignItems={"center"}>
                <IconButton
                  data-test-id={file.name}
                  onClick={() => this.handleRemoveFile(file)}
                  sx={{ paddingLeft: "0px" }}
                >
                  <DeleteIcon sx={webStyles.delete_icon} />
                </IconButton>
                <ImageCollection sx={webStyles.file_icon} />
                <Typography sx={webStyles.file_name}>{file.name}</Typography>
                <Typography sx={webStyles.file_size}>
                  {(file.size / 1024 / 1024).toFixed(2)} MB
                </Typography>
              </Box>
            </Grid>
          </FileDetails>
        ))}
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyles = {
  delete_icon: {
    color: "#CC9200",
    maxHeight: "18px",
  },
  file_icon: {
    color: "#CC9200",
  },
  file_name: {
    fontFamily: "Outfit, sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "18px",
    color: "#000000",
    marginLeft: "11px",
  },
  file_size: {
    fontFamily: "Outfit, sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "18px",
    color: "#A3978F",
    marginLeft: "19px",
  },
};
// Customizable Area End
