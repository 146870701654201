import React from "react";
// Customizable Area Start
import { Box, Grid } from "@mui/material";
import NavigationSidebar from "../../../components/src/NavigationSidebar.web";
import { activeMainDashboard, activeMainReminder, activePropertyMain, activeMainFinancial, keyMainChain, activeKeyMain, keasyIcon, dashboardLandLogo, financialMain, propertyMain, remindersMain } from "./assets";
// Customizable Area End
import TenantDashboardController, {
    Props,
    configJSON
} from "./TenantDashboardController.web";

export default class TenantDashboard extends TenantDashboardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <Box>
                <Grid container>
                    <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                        <NavigationSidebar
                            data-test-id={"tenantId"}
                            activeItem={this.state.activeTenantItem}
                            sidebarItems={[
                                { label: configJSON.dashboardLandloard, labelKey: configJSON.tenantDashboard, icon: dashboardLandLogo, activeIcon: activeMainDashboard, pathName: configJSON.tenantDashboard },
                                { label: configJSON.serviceRequests, labelKey: configJSON.servicelabel, icon: remindersMain, activeIcon: activeMainReminder, pathName: configJSON.servicelabel },
                                { label: configJSON.lease, labelKey: configJSON.leaselabel, icon: propertyMain, activeIcon: activePropertyMain, pathName: configJSON.leaselabel },
                                { label: configJSON.paymentHistory, labelKey: configJSON.paymentHistorylabel, icon: financialMain, activeIcon: activeMainFinancial, pathName: configJSON.paymentHistorylabel },
                                { label: configJSON.chatLandloard, labelKey: configJSON.chatlandlabel, icon: keyMainChain, activeIcon: activeKeyMain, pathName: configJSON.chatTenantabel }
                            ]}
                            onClickSidebar={this.handleTenantitemClick}
                            keasylogo={keasyIcon}
                            openDrawer={this.state.openTenantDrawer}
                            onClickDrawer={this.handleTenantDrawer}
                        />
                    </Grid>
                    <Grid item xl={10} lg={10} md={10} sm={12} xs={12}>
                        {configJSON.tenant}
                    </Grid>
                </Grid>
            </Box>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
// Customizable Area End
