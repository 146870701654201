import React from "react";

// Customizable Area Start
import {Box,styled} from "@mui/material";
import NewPasswordController,{Props} from "./NewPasswordController";

import {
 
    Button,
    Typography,
    IconButton,
    InputAdornment,
    Stack,
  } from "@mui/material";
  import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
  import VisibilityIcon from "@mui/icons-material/Visibility";
  import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
  import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
  import { Formik, Form, Field } from "formik";
  import * as Yup from "yup";
  import CustomInputField from "../../../components/src/CustomInputField.web";

type Values = {
    password: string;
    confirmPassword:string
  };
// Customizable Area End

export default class NewPassword extends NewPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
       // Customizable Area Start
       // Customizable Area End
    return (
       // Customizable Area Start
      <NewPassowrdStyle>
        <Box className="imageBackground" >
        </Box>
        <Box className="logo-container">
            <img
              className="image_image_keasylogo_1426934368"
              src={require('../../termsconditions/assets/1426934368KmTObgfmr1gx8VdhoxvtGF.svg')}
              alt="Keasy Logo"
            />
              <img
              className="image_image_keasyfont_1426934369"
              src={require('../../termsconditions/assets/1426934369KmTObgfmr1gx8VdhoxvtGF.svg')}
              alt="Keasy Font"
            />
        </Box>
        <Box className= "description"  data-test-id="form_data">
        <Formik
            data-test-id="formik"
            enableReinitialize={true}
            validateOnBlur={true}
            validate={(values) => this.validatePasswordField(values.password)}
            initialValues={{
            password: "",
            confirmPassword:"",
            }}
            validationSchema={Yup.object({
            password: Yup.string()
                .min(8)
                .matches(/[a-z]/)
                .matches(/\d/)
                .matches(/[A-Z]/)
                .required(),
                confirmPassword: Yup.string()
                .oneOf([Yup.ref('password'), null], 'Passwords must match')
                .required('Confirm password is required')
            })}
            onSubmit={(values: Values, { setSubmitting }) => {
                this.handleSubmit(values, setSubmitting);
              }}
            >
              {(props) => (
                <Form className="signup_form_formik">
                  <Typography className="title">New password</Typography>
                  <Typography className="signup_subheading">
                    Enter a new password for 
                  </Typography>
                  <Field
                    className="input-box"
                    data-test-id="txtInputPassword"
                    name="password"
                    label="New Password"
                    type={this.state.enablePassField ? "text" : "password"}
                    placeholder="Your new password"
                    value={props.values.password}
                    onChange={(event: { target: { value: string } }) => {                      
                      let password = event.target.value;
                      this.setPassword(event.target.value);
                      props.setFieldValue("password", password);
                    }}
                    as={CustomInputField}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            data-test-id="testTogglePassword"
                            aria-label="toggle password visibility"
                            onClick={() =>
                              this.handleClickShowPassword("password")
                            }
                            edge="end"
                          >
                            {this.state.enablePassField ? (
                              <VisibilityIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                
                <CustomInputField
                  className="input-box"
                  data-test-id="txtInputConfirmPassword"
                  name="confirmPassword"
                  label="Confirm New Password"
                  type={this.state.enableConfirmPasswordField ? "text" : "password"}
                  placeholder="Confirm your new password"
                  value={props.values.confirmPassword}
                  onChange={(event: { target: { value: string } }) => {                      
                    let confirmPassword = event.target.value;
                    this.setConfirmPassword(event.target.value);
                    props.setFieldValue("confirmPassword", confirmPassword);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          data-test-id="testToggleConfirmPassword"
                          aria-label="toggle password visibility"
                          onClick={() =>
                            this.handleClickShowPassword("confirmPassword")
                          }
                          edge="end"
                        >
                          {this.state.enableConfirmPasswordField ? (
                            <VisibilityIcon />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  />

                  {!this.state.isPasswordValid ? (
                    <Box
                      data-test-id="validationBlock"
                      className="password_error_wrapper"
                    >
                      <Typography className="error-title">
                        Your password must contain
                      </Typography>
                      <Stack direction="row" alignItems="center" gap={1}>
                        {!this.state.hasOneUppercaseLetter ? (
                          <RemoveCircleRoundedIcon
                            data-test-id="hasOneUppercaseLetterRemove"
                            className="password_error_icon"
                          />
                        ) : (
                          <CheckCircleRoundedIcon
                            data-test-id="hasOneUppercaseLetterCheck"
                            className="password_sucess_icon"
                          />
                        )}
                        <Typography className="password_error_message">
                           At least one capital letter
                        </Typography>
                      </Stack>
                      <Stack direction="row" alignItems="center" gap={1}>
                        {!this.state.hasOneLowercaseLetter ? (
                          <RemoveCircleRoundedIcon
                            data-test-id="hasOneLowercaseLetterRemove"
                            className="password_error_icon"
                          />
                        ) : (
                          <CheckCircleRoundedIcon
                            data-test-id="hasOneLowercaseLetterCheck"
                            className="password_sucess_icon"
                          />
                        )}
                        <Typography className="password_error_message">
                          At least one lowercase letter
                        </Typography>
                      </Stack>
                      <Stack direction="row" alignItems="center" gap={1}>
                        {!this.state.hasOneNumber ? (
                          <RemoveCircleRoundedIcon
                            data-test-id="hasOneNumberRemove"
                            className="password_error_icon"
                          />
                        ) : (
                          <CheckCircleRoundedIcon
                            data-test-id="hasOneNumberCheck"
                            className="password_sucess_icon"
                          />
                        )}
                        <Typography className="password_error_message">
                         At least one number
                        </Typography>
                      </Stack>
                      <Stack direction="row" alignItems="center" gap={1}>
                        {!this.state.hasEightCharacters ? (
                          <RemoveCircleRoundedIcon
                            data-test-id="hasEightCharactersRemove"
                            className="password_error_icon"
                          />
                        ) : (
                          <CheckCircleRoundedIcon
                            data-test-id="hasEightCharactersCheck"
                            className="password_sucess_icon"
                          />
                        )}
                        <Typography className="password_error_message">
                         Minimum character length is 8 characters
                        </Typography>
                      </Stack>
                    </Box>
                  ) : null}
                 
                 <Box className="bottom_wrapper">
                 <Button
                    type="submit"
                    className="save_button"
                    data-test-id="btnSignUp"
                  >
                    Set New Password
                  </Button>
                 </Box>
                    <Box className="button_text_wrapper">
                    <Typography className="bottom_text">
                      Back to
                  <span className="bottom_link_text" onClick={this.handleClickSignUp} data-test-id="testSignupbtn"> Sign Up</span>
                </Typography>
                    </Box>
                 <Box className="mobile-social-media">
                        <img
                            className="social-media-icon"
                            src={require("../../email-account-login/assets/button_facebook.svg")}
                          />
                          <img
                            className="social-media-icon"
                            src={require("../../email-account-login/assets/button_instagram.svg")}
                          />
                            <img
                            className="social-media-icon"
                            src={require("../../email-account-login/assets/button_twitter.svg")}
                          />
                  </Box>
                </Form>
              )}
        </Formik>
        </Box>
      </NewPassowrdStyle>
       // Customizable Area Start
    );
    
  }
}
// Customizable Area Start    
const NewPassowrdStyle = styled(Box)({
    backgroundColor: '#ffffffff',
    width: '100%',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  
    "@media(max-width: 768px)": {
      height: 'auto',
      padding: '1rem',
    },
    "& .imageBackground": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundImage: `url(${require('../../termsconditions/assets/1426934416KmTObgfmr1gx8VdhoxvtGF.svg')})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      "@media(max-width: 1440px)": {
        width: "103%",
        height: "143%",
      },
      "@media(max-width: 768px)": {
        width: "142%",
        height: '49%',
        marginTop:"31rem"
      },
    },
    "& .logo-container": {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      gap: '1rem',
      alignItems: 'center',
      flex: 0.5,
      "@media(max-width: 768px)": {
        flexDirection: 'column',
        gap: '0.5rem',
      },
    },
    "& .image_image_keasylogo_1426934368": {
      width: '2.6%',
      "@media(max-width: 768px)": {
        width: '10%',
      },
    },
    "& .image_image_keasyfont_1426934369": {
      width: '10.4%',
      "@media(max-width: 768px)": {
        width: '40%',
      },
    },
    "& .title": {
      fontFamily: 'Outfit',
      fontWeight: 700,
      fontSize: 24,
      color: '#000000',
      textAlign: "start",
      marginBottom:"2rem",
      "@media(max-width: 768px)": {
        fontSize: 20,
        marginTop: "2rem",
      },
    },
    "& .signup_subheading": {
      fontFamily: 'Outfit',
      fontWeight: 400,
      fontSize: 16,
      lineHeight: "18px",
      color: '#000000',
      textAlign: "start",
      marginBottom:"2rem",
      "@media(max-width: 768px)": {
        fontSize: 20,
      },
    },
    
    "& .input-box": {
      fontFamily: 'Outfit',
      width:"100%",
      backgroundColor: 'red',
      fontWeight: 700,
      fontSize: 18,
      lineHeight: "17px",
      color: '#70645C',
      textAlign: "start",
      "@media(max-width: 768px)": {
        fontSize: 20,
      },
    },
    "& .error-title": {
      fontFamily: 'Outfit',
      fontWeight: 400,
      fontSize: "18px",
      lineHeight: "26px",
      color: '#000000',
      textAlign: "start",
      marginBottom:"0.5rem",
      "@media(max-width: 768px)": {
     
      },
    },
    "& .signup_form_formik":{
      display: 'flex',

      flexDirection: 'column',

    },
    "& .description": {
      display: 'flex',
      justifyContent: 'center',
      alignItems: "center",
      flexDirection: 'column',
      marginTop: '2rem',
      zIndex:'10',
      "@media(max-width: 768px)": {
        marginTop: '1rem',
        width:"95%",
      },
    },
    "& .password_error_message": {
      fontFamily: 'Outfit',
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "12px",
      color: "#000000",
    },
    "& .password_error_icon": {
      fontSize: "16px",
      color: "#FF0000",
    },
    "& .password_sucess_icon": {
      fontSize: "16px",
      color: "#32CD32",
    },
    "& .password_error_wrapper": {
      marginTop: "32px",
    },
    "& .text_textlabel_description_1426934372": {
      width: 690,
      fontFamily: 'Outfit',
      fontWeight: 400,
      fontSize: 20,
      color: '#544b45',
      flex: 2,
      "@media(max-width: 768px)": {
        width: '100%',
        fontSize: 16,
      },
    },
    "& .bottom_wrapper":{
      height:"50%",
      width:"100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop:"1.5rem",
      },
      "& .save_button":{
        height:"100%",
        width:"100%",
        backgroundColor: '#FFC123',
        borderRadious:"8px",
        fontFamily: 'Outfit',
        fontWeight: 700,
        fontSize: 18,
        color:"#000000",
        '&:hover': {
          backgroundColor: '#FFC123', 
        },
        "@media(max-width: 768px)": {
          width:"55%",
          height:"90%",
          
        },
        },
        "& .mobile-social-media": {
          display: 'flex',
          justifyContent: 'center',
          alignItems: "center",
          "@media (max-width:600px)": {
            position: 'absolute',
            bottom: "1rem",
          },
          "@media (max-height:650px)": {
            bottom: "0px",
          }
        },
        "& .social-media-icon": {
          width: "28px",
          padding: "4px",
          backgroundColor: "#A3978F", 
          margin: "12px",
          borderRadius: '8px',
          cursor: "pointer",
        },
        "& .button_text_wrapper": {
          display: "flex",
          justifyContent: "start",
          marginTop:"2rem",
          marginBottom:"2rem"
          
        },
        "& .bottom_text":{
          fontFamily: 'Outfit',
          fontWeight: 400,
          fontSize: 18,
        },
        "& .bottom_link_text": {
          fontFamily: 'Outfit',
          fontWeight: 700,
          fontSize: "18px",
          color: "#CC9200",
          cursor: "pointer",
        },
});
// Customizable Area End