import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  name: string;
  onFilesChange: (name: string, files: File[]) => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  files: File[];
  error: string | null;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CameraAccessController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess)
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      files: [],
      error: null,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    // Customizable Area End
  }

  btnGetCameraAccessProps = {
    onPress: () => this.showCameraPicker()
  };

  showCameraPicker() {
    try {
      const ImagePicker = require("react-native-image-crop-picker");
      ImagePicker.openCamera({
        mediaType: "photo",
        compressImageQuality: 0.3,
        includeBase64: true,
        cropping: true
      }).then(async (image: any) => {
        console.log("@@@ Selected Image Item =============", image);
      });
    } catch (e) {
      console.log("@@@ Error opening camera ==========", e);
    }
  }
  // Customizable Area Start
  componentDidUpdate(prevProps: Props, prevState: S) {
    if (prevState.files !== this.state.files) {
      this.props.onFilesChange(this.props.name, this.state.files);
    }
  }

  onChange = (event: any) => {
    const selectedFiles: File[] = Array.from(event.target.files || []);
    const acceptedFileTypes = [
      "image/png",
      "image/jpeg",
      "image/jpg",
      "video/mp4",
      "video/webm",
      "video/ogg",
    ];
    const acceptedFiles: File[] = selectedFiles.filter((file: File) =>
      acceptedFileTypes.includes(file.type)
    );
    const fileRejections = selectedFiles.filter(
      (file) => !acceptedFileTypes.includes(file.type)
    );

    if (fileRejections.length > 0) {
      this.setState({
        error: "Invalid file type.",
      });
    } else {
      this.setState({ error: null });
      this.setState((prevState) => ({
        files: [...prevState.files, ...acceptedFiles],
      }));
    }
  }

  handleRemoveFile = (file: File) => {
    this.setState((prevState) => ({
      files: prevState.files.filter((f) => f !== file),
    }));
  };
  // Customizable Area End
}
