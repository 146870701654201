import * as React from "react";
import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
import { Alert, AlertColor } from "@mui/material";

interface Props {
  open: boolean;
  type: AlertColor; // values for this can be error, success, info and warning in double quotes
  message: string;
  handleClose: () => void;
}

interface State extends SnackbarOrigin {
  vertical: "top" | "bottom";
  horizontal: "center" | "left" | "right";
}

export default class PopupMessage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      vertical: "top",
      horizontal: "center",
    };
  }

  render() {
    const { vertical, horizontal } = this.state;

    return (
      <div>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={this.props.open}
          onClose={this.props.handleClose}
          autoHideDuration={5000}
        >
          <Alert
            onClose={this.props.handleClose}
            severity={this.props.type}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {this.props.message}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}
