import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import CarouselDisplay from "../../../../packages/blocks/carouseldisplay/src/CarouselDisplay.web";
import SplashscreenController, { Props } from "./SplashscreenController";

const SplashStyling = styled(Box)({
  overflow: "hidden",
  backgroundColor: "#FFFFFF",
  width: "100%",
  height: "100vh",
  display: "flex",
  position: "relative",
  flexDirection: "row",
  "@media(max-width:600px)": {
    width: "100%",
    height: "100%",
    display: "flex",
    position: "relative",
    flexDirection: "column",
  },
  "@media(max-height:700px)": {
    minHeight: "700px",
   },
  "& .left": {
    display: "flex",
    flexDirection: "column",
    justifyContent:"space-between",
    gap: "8rem",
    paddingLeft: "1.5rem",
    flex: 1,
    height: "100%",
    "@media(max-width:600px)": {
      display: "flex",
      flexDirection: "column",
      gap: "1rem",
      paddingLeft: "1rem",
      paddingRight: "1rem",
      alignItems: "start",
      justifyContent: "start",
    },
    "@media(max-height:700px)": {
      gap: "0rem",
    },
  },
  "& .right": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: 1.3,
    zIndex: 20,
    "@media(max-width:600px)": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
    },
  },
  "& .logoContainer": {
    display: "flex",
    alignItems: "center",
    paddingTop: "1.5rem",
    "@media(max-width:600px)": {
      display: "flex",
      justifyContent: "center",
      marginBottom: "2rem",
    },
    "@media(max-height:700px)": {
      marginBottom: "0rem",
      paddingTop: "0rem",
    },
  },
  "& .logoImage": {
    width: 45,
    height: 72,
  },
  "& .logoText": {
    marginLeft: 12,
    color: "#000000",
    fontSize: 35.6,
    fontFamily: "Fascinate, sans-serif",
    fontWeight: "900",
    "@media(max-width:600px)": {
      marginLeft: 8,
      color: "#000000",
      fontSize: 24,
      fontFamily: "Outfit, sans-serif",
      fontWeight: "700",
    },
  },
  "& .descriptionContainer": {
    width: "100%",
    flex:2,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "@media(max-width:600px)": {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
      marginTop:"-2rem"
    },
    "@media(max-height:700px)": {
      marginTop:"-3rem"
    },
  },
  "& .welcomeText": {
    fontFamily: "Outfit, sans-serif",
    fontWeight: "700",
    fontSize: "28px",
    color: "#cc9200",
    "@media(width:375px)": {
      fontFamily: "Outfit, sans-serif",
      fontWeight: "700",
      fontSize: "26px",
      color: "#cc9200",
      textAlign: "center",
      position: "relative",
      top: "2rem"
    },
    "@media(max-width:600px)": {
      fontFamily: "Outfit, sans-serif",
      fontWeight: "700",
      fontSize: 28,
      color: "#cc9200",
      textAlign: "center",
    },
  },
  "& .descriptionText": {
    marginTop: 24,
    fontFamily: "Outfit, sans-serif",
    fontWeight: 400,
    fontSize: 30,
    color: "#1c1917",
    "@media(max-width:600px)": {
      fontSize: 18,
    },
    "@media(max-height:700px)": {
      marginTop: 23,
    },
  },

  "& .landlordContainer": {
    backgroundColor: "#FFFFFF",
    borderRadius: 24,
    padding: "24px",
    zIndex: 20,
    width: "100%",
    "@media(max-width:600px)": {
      backgroundColor: "#FFFFFF",
      borderRadius: 24,
      padding: "16px",
      zIndex: 10,
      width: "90%",
      marginTop: "2rem",
    },
  },
  "& .landlordTitle": {
    fontFamily: "Outfit, sans-serif",
    fontWeight: 700,
    fontSize: 36,
    color: "#000000",
    "@media(max-width:600px)": {
      fontFamily: "Outfit, sans-serif",
      fontWeight: "700",
      fontSize: 24,
      color: "#000000",
      textAlign: "center",
    },
  },
  "& .landlordDescription": {
    fontFamily: "Outfit, sans-serif",
    fontWeight: 400,
    fontSize: 18,
    color: "#000000",
    "@media(max-width:600px)": {
      fontSize: 16,
    },
  },
  "& .imageBackground": {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundImage: `url(${require("../assets/1317616248KmTObgfmr1gx8VdhoxvtGF.svg")})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    zIndex: 10,
    
  },
  "& .social-media":{
      display: 'flex',
      gap: "1.2rem",
      justifyContent:'center',
      flex:1,
      "@media(max-width:600px)": {
        display:'none',
      },
  },
  "& .facebook":{
    width:"6%",
    "@media(width:375px)": {
      width: "32px",
      height: "32px",
      marginTop:"6rem",
      marginBottom:"2rem"
      
    },
    "@media(max-width:600px)": {
      width: "32px",
      height: "32px",
      
    },
  },
  "& .instagram":{
     width:"6%",
     "@media(width:375px)": {
      width: "32px",
      height: "32px",
      marginTop:"6rem",
      marginBottom:"2rem"
      
    },
    "@media(max-width:600px)": {
      width: "32px",
      height: "32px",
   },
  },
  "& .twitter":{
    width: "6%",
   "@media(max-width:600px)": {
      width: "32px",
      height: "32px",
  },
  "@media(width:375px)": {
    width: "32px",
    height: "32px",
    marginTop:"6rem",
    marginBottom:"2rem"
    
  },
},
  "& .mobile-social-media":{
     display: 'none', 
    "@media(max-width:600px)": {
      display: 'flex',
      marginTop: "3rem",
      marginBottom: "1rem",
      justifyContent: "center",
      gap: "1rem",
    },
 
    "@media(max-height:700px)": {
      marginTop: "4rem",
      marginBottom: "0rem",
    },
  }
});

class Splashscreen extends SplashscreenController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    return (
      <SplashStyling>
        <Box className="imageBackground" />
        <Box className="left">
          <Box className="logoContainer">
            <img
              className="logoImage"
              src={require("../assets/1317616257KmTObgfmr1gx8VdhoxvtGF.svg")}
            />
            <Typography className="logoText">Keasy.ai</Typography>
          </Box>
          <Box className="descriptionContainer">
            <Typography className="welcomeText">
              Welcome to Keasy.ai!
            </Typography>
            <Typography className="descriptionText">
              Lorem ipsum dolor sit amet consectetur. Sit sagittis at mi
              fermentum maecenas.
            </Typography>
          </Box>
          <Box className="social-media">
          <img
              className="facebook"
              src={require("../assets/button_facebbok.svg")}
            />
            <img
              className="instagram"
              src={require("../assets/button_instagram.svg")}
            />
              <img
              className="twitter"
              src={require("../assets/button_twitter.svg")}
            />
          </Box>
        </Box>

        <Box className="right">
          <CarouselDisplay navigation={this.props.navigation} id="carousel-1" />
        </Box>
        <Box className="mobile-social-media">
          <img
              className="facebook"
              src={require("../assets/button_facebbok.svg")}
            />
            <img
              className="instagram"
              src={require("../assets/button_instagram.svg")}
            />
              <img
              className="twitter"
              src={require("../assets/button_twitter.svg")}
            />
          </Box>
      </SplashStyling>
    );
  }
}

export default Splashscreen;
