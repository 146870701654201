Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Email";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";
exports.formSubheader = "Please fill your information below"

exports.requiredFieldLabel = "This field is required";
exports.invalidEmailLabel = "Invalid email";
exports.termsAndConditionLabel = "You have to agree with Privacy Policy and Terms and Conditions to Sign up";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and Conditions";
exports.labelLegalPrivacyPolicy = "Privacy Policy";
exports.andText = "and";
exports.agreeLabel = "I agree with";
exports.labelHaveAccount = 'Already have an account? ';
exports.labelLogin = "Log in"
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";
exports.capitalLetterError = "At least one capital letter";
exports.lowerCaseError = "At least one lowercase letter";
exports.numberError = "At least one number";
exports.specialCharError = "At least one special character";
exports.minimumCharacters = "Minimum character length is 8 characters";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";
exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPointWeb = "account_block/accounts";

exports.accountsAPiEndPoint =
  "account/accounts";

exports.apiMethodTypeAddDetail = "POST";

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";

exports.validationApiMethodType = "GET";

exports.keasyLogoName = "Keasy.ai";
exports.selectUser = "Select user";
exports.selectTextOne = "We find that you are registered in more";
exports.selectTextTwo = "then one user type.";
exports.wantToUse = "Which one you want to use?";
exports.landlordUser = "Landlord";
exports.tenantUser = "Tenant";
exports.serviceProvider = "Vendor";
exports.dontAccount = "Don't have an account?";
exports.signUpAccount = "Sign up";
exports.logInAccount = "Continue";
exports.userMessage = "Please Select User Type";
exports.successMessage = "Account successfully created.";

// Customizable Area End
