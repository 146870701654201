import React from "react";
// Customizable Area Start
import { Box, Button, Grid, IconButton, Menu, MenuItem, Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, styled } from "@mui/material";
import NavigationSidebar from "../../../components/src/NavigationSidebar.web";
import { dashboardOrderLogo, activeOrderDashboard, accountsOrder, activeOrderAccount, ordersIcon, activeOrderIcon, vendorOrder, activeOrderVendor, remindersOrder, activeOrderReminder, propertyOrder, activePropertyOrder, financialOrder, activeOrderFinancial, reportOrder, activeOrderReport, keyOrderChain, activeKeyOrder, settingsOrder, activeOrderSetting, keasIcon, hexagon, halfHexagon, findKeys, waitingBill, keys } from './assets';
import { AuditLogs, CategoryUpdateDetails, Documents, FileWithPreview} from "./UpdateWorkOrderController.web";
import { ArrowDropDown, ArrowDropUp, Close, Collections, KeyboardArrowDown, DeleteOutline } from '@mui/icons-material';
import FileUpload from "../../accountgroups/src/FileUpload";
// Customizable Area End
import UpdateWorkOrderController, {
  Props,
  configJSON,
} from "./UpdateWorkOrderController.web";

export default class UpdateWorkOrder extends UpdateWorkOrderController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderUpdateCell = (property: string, label: string) => {
    return (
      <CustomCell key={property}>
        <Box display="flex" justifyContent={"space-evenly"} alignItems="center">
          {label}
          <Box display="flex" marginLeft={"10px"} flexDirection="column">
            <CustomButtonUp
              data-test-id="sortUpdateId"
              onClick={() => this.handleUpdateSort(property, "asc")}
            >
              <ArrowDropUp
              />
            </CustomButtonUp>
            <CustomButtonDown
              data-test-id="downUpdateId"
              onClick={() => this.handleUpdateSort(property, "desc")}
            >
              <ArrowDropDown
              />
            </CustomButtonDown>
          </Box>
        </Box>
      </CustomCell>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
      return (
        <WorkOrderStyles>
          <NavigationSidebar
            data-test-id={"navBarUpdateId"}
            activeItem={this.state.activeUpdateOrder}
            sidebarItems={[
              { icon: dashboardOrderLogo, labelKey: configJSON.dashboardOrderlabel, label: configJSON.dashboardOrder, pathName: configJSON.dashboardOrderlabel, activeIcon: activeOrderDashboard, },
              { icon: accountsOrder, pathName: configJSON.myAccountOrderabel, label: configJSON.myAccountOrder, labelKey: configJSON.settingOrderlabel, activeIcon: activeOrderAccount },
              { icon: ordersIcon, labelKey: configJSON.worksOrderlabel, activeIcon: activeOrderIcon, pathName: configJSON.worksOrderlabel, label: configJSON.workOrder },
              { icon: vendorOrder, labelKey: configJSON.prevendorOrderlabel, label: configJSON.preferredOrder, activeIcon: activeOrderVendor, pathName: configJSON.prevendorOrderlabel },
              { icon: remindersOrder, labelKey: configJSON.reminOrderlabel, label: configJSON.remindersOrder, activeIcon: activeOrderReminder, pathName: configJSON.reminOrderlabel },
              { icon: propertyOrder, labelKey: configJSON.mypropOrderlabel, label: configJSON.mypropOrder, activeIcon: activePropertyOrder, pathName: configJSON.mypropOrderlabel },
              { icon: financialOrder, labelKey: configJSON.financOrderlabel, label: configJSON.financiaOrder, activeIcon: activeOrderFinancial, pathName: configJSON.financOrderlabel },
              { icon: reportOrder, labelKey: configJSON.reportOrderlabel, label: configJSON.reportOrder, activeIcon: activeOrderReport, pathName: configJSON.reportOrderlabel },
              { icon: keyOrderChain, labelKey: configJSON.chatOrderlabel, label: configJSON.chatOrder, activeIcon: activeKeyOrder, pathName: configJSON.chatOrderlabel },
              { activeIcon: activeOrderSetting, labelKey: configJSON.workOrder, icon: settingsOrder, label: configJSON.settingOrder, pathName: configJSON.settingOrderlabel }
            ]}
            onClickDrawer={this.handleOpenDrawer}
            onClickSidebar={this.handleSideBarNav}
            keasylogo={keasIcon}
            openDrawer={this.state.openShowOrder}
          />
          <Box width={"100%"} display={"flex"} justifyContent={"space-between"} flexDirection={"column"}
            sx={{
              padding: {
                borderLeft: "1px solid rgb(208,203,199)",
                flexGrow: 1
              },
            }}>
            <MainWorkContainer>
              <WorkStyles>
                <WorkOrders data-test-id="seeOpentId">
                  {configJSON.updateWork}
                </WorkOrders>
                <BorderContainer>
                  <Grid container spacing={2} mt={2}>
                    <GridItem item xl={6} lg={6} md={6} sm={12}>
                      <HeadItems>
                        <HeadName>{configJSON.orderId}{this.state.openWorksOrder.data.id}</HeadName>
                      </HeadItems>
                    </GridItem>
                    <GridItem item xl={6} lg={6} md={6} sm={12}>
                      <CompletedDetails>
                        <ScheduledNewWaiting data-test-id="payBillId" onClick={()=>this.handlePayBillDetails("pay_bill")}>
                          <img
                            className="image-icon"
                            src={waitingBill} alt="payBills" />
                          <ScheduledDate data-test-id="payId">{configJSON.payBill}</ScheduledDate>
                        </ScheduledNewWaiting>
                        <Box display={"flex"} gap={1} onClick={this.handleModal}>
                          <img src={findKeys} alt="finds" className="image-icon" />
                          <FindKeyName>{configJSON.findKeys}</FindKeyName>
                        </Box>
                      </CompletedDetails>
                    </GridItem>
                    <CustomNewModal
                      data-test-id="upateModalId"
                      open={this.state.openFindKey}
                    >
                      <ParagraphKeyContainer>
                        <Box display={"flex"} flexDirection={"column"} gap={"10px"} height={"100%"}>
                          <CloseKeyModal onClick={this.handleModal}>
                            <Close />
                          </CloseKeyModal>
                          <KeyContainers>
                                <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
                                  <FindKeyHeading>
                                    {configJSON.keyLocatedMessage}{this.state.openWorksOrder.data.attributes.property.code}{configJSON.doorClose}
                                  </FindKeyHeading>
                                  <FindKeyLocation>{configJSON.keyLocation}</FindKeyLocation>
                                  <FindKeyPara data-test-id="findKeyDetailsId">{configJSON.floorName} {this.state.openWorksOrder.data.attributes.property.floor}</FindKeyPara>
                                  <FindKeyPara>{configJSON.entrance} {this.state.openWorksOrder.data.attributes.property.entrance}</FindKeyPara>
                                  <FindKeyPara>{configJSON.codeName}{this.state.openWorksOrder.data.attributes.property.code}</FindKeyPara>
                                </Box>
                            <Box>
                              <img src={keys} alt="findKey" />
                            </Box>
                          </KeyContainers>
                        </Box>
                      </ParagraphKeyContainer>
                    </CustomNewModal>
                  </Grid>
                  <HeadItems>
                    <HeadName>{this.state.openWorksOrder.data.attributes.property.property_name}|{this.state.openWorksOrder.data.attributes.unit.name}</HeadName>
                    <MainHeadings>{this.state.openWorksOrder.data.attributes.property.address}</MainHeadings>
                  </HeadItems>
                  <form  data-test-id="updateSubmitId" onSubmit={this.handleUpdateSubmit}>
                    <Box>
                      <Box className="InputWrapper">
                        <Box className="InputWrapperLeft">
                          {configJSON.categoryName}
                        </Box>
                        <Box className="InputWrapperRight">
                          <select
                            className='selectLong'
                            onChange={(catEvent) => {
                              this.setUpdateCatagory(catEvent.target.value);
                            }}
                            data-test-id="catagoryTest"
                          >
                            <option data-test-id="catagoryNewId" value={this.state.openWorksOrder.data.attributes.sub_category.sub_category_id}>{this.state.openWorksOrder.data.attributes.sub_category.sub_category ? this.state.openWorksOrder.data.attributes.sub_category.sub_category : configJSON.naTextValue}</option>
                            {this.state.catagoryDetails.map((catagory: CategoryUpdateDetails) => (
                              <option key={catagory.id} value={catagory.attributes.id} data-test-id="catagoryId">
                                {catagory.attributes.name}
                              </option>
                            ))}
                          </select>
                        </Box>
                      </Box>
                      <Box className="InputWrapper">
                        <Box className="InputWrapperLeft" data-test-id = "textId">
                          {configJSON.workNeedName}
                        </Box>
                        <Box className="InputWrapperRight">
                          <input
                            value={this.capitalizeLetter(this.state.openWorksOrder.data.attributes.work_needed)}
                            className="input"
                            onChange={(updateEvent) => this.setUpdateWorkNeed(updateEvent.target.value)}
                            data-test-id="workId"
                          />
                        </Box>
                      </Box>
                      <Box className="InputWrapper">
                        <Box className="InputWrapperLeft" data-test-id = "vendorId">
                          {configJSON.SelectedVendor}
                        </Box>
                        <Box className="InputWrapperRight" display={"flex"} flexDirection={"column"} gap={1}>
                          <CustomText>{this.state.openWorksOrder.data.attributes.vendor_account.name}</CustomText>
                          <CustomText>{this.state.openWorksOrder.data.attributes.vendor_account.phone_number}</CustomText>
                          <CustomText>{this.state.openWorksOrder.data.attributes.vendor_account.email_address}</CustomText>
                        </Box>
                      </Box>
                      <Box className="InputWrapper">
                        <Box className="InputWrapperLeft" data-test-id = "textStatusId">
                          {configJSON.status}
                        </Box>
                        <Box className="InputWrappersRight">
                          <OrderOpenStatus data-test-id="statusUpdateId" sx={this.priorityUpdateStyleHandler(this.setUpdatedStatus(this.state.selectedUpdateValue) || this.setUpdatedStatus(this.state.openWorksOrder.data.attributes.status))}>
                            {this.setUpdatedStatus(this.state.selectedUpdateValue) || this.state.openWorksOrder.data.attributes.status}
                          </OrderOpenStatus>
                          <ApproveUpdateDetails data-test-id="approveUpdateId" onClick={this.handleUpdateApproveClick}>
                            <ApprovedName>{this.state.selectedUpdateValue || configJSON.assignVendorNew}</ApprovedName>
                            <KeyboardArrowDown />
                          </ApproveUpdateDetails>
                          <Menu
                            data-test-id="closeUpdateId"
                            anchorEl={this.state.anchorElement}
                            keepMounted
                            open={this.state.openUpdateApprove}
                            onClose={this.handleUpdateApproveMenu}
                            elevation={0}
                            slotProps={{
                              paper: {
                                style: { marginLeft: "-8px" },
                              },
                            }}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "center",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "center",
                            }}
                          >
                            {this.state.approveUpdateDetails.map((items: string, appIndex: number) => (
                              <CustomUpadteMenuItem
                                data-test-id="approveMenuId"
                                key={`menu-item-${appIndex}`}
                                onClick={() => this.handleMenuClick(items)}
                              >
                                {items}
                              </CustomUpadteMenuItem>
                            ))}
                          </Menu>
                        </Box>
                      </Box>
                      <Box className="InputWrapper">
                        <Box className="InputWrapperLeft">
                          {configJSON.descriptionName}
                        </Box>
                        <Box className="InputWrapperRight">
                          <textarea
                            value={this.state.openWorksOrder.data.attributes.description}
                            className="textArea"
                            rows={6}
                            onChange={(updateEvent) => this.setUpdateDescription(updateEvent.target.value)}
                            data-test-id="descUpdateId"
                          />
                        </Box>
                      </Box>
                      <Box className="InputWrapper">
                        <Box className="InputWrapperLeft">
                          {configJSON.goingOnName}
                        </Box>
                        <Box className="InputWrapperRight">
                          <CustomTableContainer className={this.handleShowScrollContainer(this.state.openWorksOrder.data.attributes.work_order_audits)}>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  {this.renderUpdateCell("date", configJSON.dateName)}
                                  {this.renderUpdateCell("updated_by", configJSON.updatedName)}
                                  <DetailsCell>{configJSON.detailsName}</DetailsCell>
                                  {this.renderUpdateCell("lowCost", configJSON.costName)}
                                  {this.renderUpdateCell("status", configJSON.statusChangeName)}
                                  <TableCell />
                                </TableRow>
                              </TableHead>
                              <CustomContainer>
                                {this.sortShowAuditData(this.state.openWorksOrder.data.attributes.work_order_audits).map((audits: AuditLogs, auditsIndex: number) => {
                                  return (
                                    <React.Fragment key={auditsIndex}>
                                      <TableRow className={this.handleAuditLog(this.state.openWorksOrder.data.attributes.work_order_audits)}>
                                        <StatusUpdateCell data-test-id="sortUpdateId">{audits.date}</StatusUpdateCell>
                                        <StatusUpdateCell>{audits.updated_by}</StatusUpdateCell>
                                        <StatusUpdateCells>{audits.description}</StatusUpdateCells>
                                        <StatusUpdateCell>{configJSON.lowCost}</StatusUpdateCell>
                                        <StatusUpdateCell>{audits.status}</StatusUpdateCell>
                                      </TableRow>
                                    </React.Fragment>
                                  );
                                })}
                              </CustomContainer>
                              <TableRow className={this.handleEmptyShowAudit(this.state.openWorksOrder.data.attributes.work_order_audits)}>
                                <TableCell colSpan={5} align="center"  data-test-id = "deleteFileId">
                                  {configJSON.auditMissing}
                                </TableCell>
                              </TableRow>
                            </Table>
                          </CustomTableContainer>
                        </Box>
                      </Box>
                      <Box className="InputWrapper">
                        <Box className="InputWrapperLeft">
                          {configJSON.fileAdded}
                        </Box>
                        <Box className="InputWrapperRight fileUpload">
                          <FileUpload
                            label=""
                            name="documents"
                            files={this.state.fileDocuments}
                            onFilesChange={(names: string, files: FileWithPreview[]) => {
                              this.handleFileDocChange(names, files);
                            }}
                            data-test-id="fileUploadTest"
                          />
                          <Box>
                          {this.state.openWorksOrder.data.attributes.documents.map((updateDocuments: Documents, docIndex: number) => {
                            return (
                              <Box key={docIndex} display={"flex"} justifyContent={"space-between"} width={"100%"}>
                                <Box display={"flex"} alignItems={"center"}>
                                  <IconButton
                                    id="delete"
                                    sx={{ paddingLeft: "0px" }}
                                    data-test-id = "deleteId"
                                    onClick={() => this.handleFileRemove(updateDocuments)}
                                  >
                                    <DeleteOutline className="delete_icon" />
                                  </IconButton>
                                  <Collections className="file_icon" />
                                  <Typography className="file_name">{updateDocuments.file_name}</Typography>
                                  <Typography className="file_size">
                                  </Typography>
                                </Box>
                                <Button
                                  data-test-id="previewUpdateId"
                                  variant="text"
                                  className="preview_button"
                                  onClick={() => this.handleShowPreview(updateDocuments)}
                                >
                                  {configJSON.preview}
                                </Button>
                              </Box>
                            )
                          })}
                          </Box>
                        </Box>
                      </Box>
                      <Box className="InputWrapper">
                        <Box className="InputWrapperLeft">
                        </Box>
                        <Box className="submitBtnWrapper">
                          <Button className="submitBtn" type="submit">{configJSON.submitUpdate}</Button>
                        </Box>
                      </Box>
                    </Box>
                  </form>
                </BorderContainer>
              </WorkStyles>
            </MainWorkContainer>
            <Box className="bgImgWrapper" data-test-id="bgUpdateId">
              <Box className="backgroundImages">
                <img src={hexagon} alt="uodateHexagons" className="hexImg" />
              </Box>
              <Box className="backgroundImages">
                <img src={hexagon} alt="uodateHexagons" className="hexImg" />
              </Box>
              <Box className="backgroundImages">
                <img src={hexagon} alt="uodateHexagons" className="hexImg" />
              </Box>
              <Box className="backgroundImages">
                <img src={halfHexagon} alt="updateHalf" className="hexImg" />
              </Box>
            </Box>
          </Box>
        </WorkOrderStyles>
      );
    // Customizable Area End
  }
}

// Customizable Area Start
const MainWorkContainer = styled(Box)({
  width: "100%",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  "@media(max-width: 992px)": {
    padding: "0px 0px 0px 0px"

  }
});

const WorkOrderStyles = styled(Box)({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  "@media(max-width: 992px)": {
    flexDirection: "column"
  },
  "&.webStyle": {
    backgroundColor: "red"
  },
  "& .bgImgWrapper": {
    display: "flex",
    justifyContent: "flex-end",
    width: "97%",
    marginTop: "2rem",
    "@media (max-width:1220px)": {
      width: "88%"
    },
    "@media (max-width:992px)": {
      display: "none",
    }
  },
  "& .backgroundImages": {
    width: "250px",
    "@media (max-width:1220px)": {
      width: "180px"
    },
  },
  "& .hexImg": {
    objectFit: "cover"
  }
});

const WorkStyles = styled(Box)({
  marginTop: "42px",
  width: "90%",
  "& .search-main-content": {
    gap: "1rem",
    display: "flex",
    "@media(max-width:600px)": {
      width: "100%",
      height: "100%",
      flexDirection: "row",
      display: "flex",
      position: "relative",
      "@media(max-width: 420px)": {
        gap: "5px"
      }
    },
  },
  "& .search-contents": {
    borderRadius: "50px",
    display: "flex",
    height: "44px",
    paddingLeft: "15px",
    alignItems: "center",
    border: "1px solid #CBD5E1",
    gap: "13px",
  },
  "& .search-icons": {
    width: "24px",
    height: "24px",
  },
  "& .search-text": {
    fontSize: "16px",
    fontWeight: 400,
    color: "#A3978F",
    "@media(max-width: 420px)": {
      fontSize: "12px"
    }
  },
  "& .search-inputs": {
    width: "100%"
  },
  "& .properties-dropdown": {
    width: "142px",
    height: "44px",
    backgroundColor: "rgb(253,242,208)",
    display: "flex",
    borderRadius: "8px",
    alignItems: "center",
    textTransform: "none",
    padding: "2px 10px 2px 10px",
    justifyContent: "space-around",
    cursor: "pointer",
    color: "#000000",
    "&:hover": {
      color: "#000000",
      backgroundColor: "rgb(253,242,208)",
    },
    "@media(max-width: 420px)": {
      fontSize: "12px",
      width: "105px",
      height: "30px"
    }
  },
  "& .view-completed": {
    width: "180px",
    height: "44px",
    display: "flex",
    borderRadius: "8px",
    alignItems: "center",
    padding: "2px 10px 2px 10px",
    justifyContent: "space-around",
    backgroundColor: "rgb(253,242,208)",
    cursor: "pointer",
    "@media(max-width: 460px)": {
      fontSize: "12px",
      height: "35px",
      width: "100%"
    },
    "@media(max-width: 347px)": {
      padding: "2px 5px 2px 5px"
    }
  },
  "& .icon": {
    mixBlendMode: "multiply"
  },
  "& .image-icon": {
    height: "16px",
    width: "16px",
  },
  "& .text-data": {
    fontWeight: 700,
    fontSize: "16px",
    "@media(max-width: 420px)": {
      fontSize: "12px"
    }
  },
  "& .new-data": {
    color: "#CC9200",
    fontWeight: 700,
    fontSize: "18px",
    "@media(max-width: 420px)": {
      fontSize: "12px"
    }
  },
  "& .new-property": {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    justifyContent: "space-around",
    borderRadius: "8px",
    paddingLeft: "5px",
    paddingRight: "5px",
    cursor: "pointer"
  },
  "& .filter-content": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: "20px"
  },
  "& .filter": {
    display: "flex",
    gap: "15px",
    alignItems: "center",
  },
  "& .filter-data": {
    fontWeight: 700,
    fontSize: "12px",
    color: "#000000"
  },
  "& .clear-data": {
    fontSize: "12px",
    color: "#CC9200",
    fontWeight: 700,
    cursor: "pointer"
  }
});

const WorkOrders = styled(Typography)({
  fontSize: "24px",
  textTransform: "capitalize",
  fontWeight: 700,
  color: "#000000",
  marginBottom: "20px"
});

const GridItem = styled(Grid)({
  width: "100%"
});

const BorderContainer = styled(Box)({
  border: "1px solid #FFD466",
  marginTop: "1rem",
  display: "flex",
  flexDirection: "column",
  borderRadius: "8px 8px 32px 8px",
  padding: "1rem",
  "& .delete_icon": {
    color: "#CC9200",
    maxHeight: "18px",
  },
  "& .scrollable": {
    height: "300px",
    overflowY: "auto"
  },
  "& .isUnitsDisabled": {
    display: "none"
  },
  "& .openOrderForm": {
    width: "90%",
    margin: "2rem",
    borderRadius: "8px",
    border: "1px solid #FFE299",
    padding: "2rem",
    "@media (max-width:1200px)": {
      padding: "0rem",
      width: "95%",
    },
    "@media (max-width:1000px)": {
      margin: "0.5rem",
    },
    "@media (max-width:600px)": {
      border: "none",
      width: "90%",
    }
  },
  "& .InputWrapper": {
    padding: "2rem 0rem 2rem 2rem",
    display: "flex",
    "@media (max-width:600px)": {
      display: "block",
      padding: "1rem 0rem 1rem 0rem",
    }
  },
  "& .InputWrapperLeft": {
    width: "20%",
    lineHeight: "17px",
    fontWeight: 700,
    fontSize: "18px",
    color: "#BAB1AB",
    "@media (max-width:600px)": {
      width: "100%",
    }
  },
  "& .InputWrapperRight": {
    display: "flex",
    flexDirection: "column",
    marginRight: "25px",
    width: "80%",
    "@media (max-width:600px)": {
      width: "100%",
      display: "block",
      marginTop: "1rem",
    }
  },
  "& .fileUpload": {
    display: "block",
    marginRight: "25px",
    width: "670px",
    "@media (max-width:600px)": {
      width: "100%",
    }
  },
  "& .InputWrappersRight": {
    display: "flex",
    gap: "20px",
    marginRight: "25px",
    width: "80%",
    "@media (max-width:600px)": {
      width: "100%",
      display: "block",
      marginTop: "1rem",
    }
  },
  "& .selectLong": {
    width: "670px",
    border: "1px solid #BAB1AB",
    height: "44px",
    borderRadius: "8px",
    paddingLeft: "10px",
    backgroundColor: "#ffffff",
    "@media (max-width:1200px)": {
      width: "100%",
    }
  },
  "& .submitBtn": {
    width: "175px",
    height: "44px",
    cursor: "pointer",
    borderRadius: "8px",
    color: "#000000",
    marginRight: "25px",
    fontWeight: 700,
    backgroundColor: "#FFC123",
    fontSize: '16px',
    lineHeight: '24px',
    fontFamily: "Outfit, sans-serif",
    "&:hover": {
      backgroundColor: "#FFD700",
    },
    "@media (max-width:600px)": {
      marginRight: "0px",
    }
  },
  "& .submitBtnWrapper": {
    width: "670px",
    justifyContent: "flex-end",
    display: "flex",
    cursor: "pointer",
    "@media (max-width:600px)": {
      width: "100%",
      justifyContent: "flex-start",
    }
  },
  "& .textArea": {
    width: "670px",
    borderRadius: "8px",
    padding: "10px",
    border: "1px solid #BAB1AB",
    marginRight: "25px",
    "@media (max-width:1000px)": {
      width: "100%",
    }
  },
  "& .input": {
    width: "670px",
    height: "44px",
    border: "1px solid #BAB1AB",
    borderRadius: "8px",
    paddingLeft: "10px",
    "@media (max-width:1200px)": {
      width: "100%",
    }
  },
  "& .file_icon": {
    color: "#CC9200",
  },
  "& .file_name": {
    fontFamily: "Outfit, sans-serif",
    fontSize: "16px",
    marginLeft: "11px",
    overflow: "hidden",
    fontWeight: 400,
    lineHeight: "18px",
    color: "#000000",
    maxWidth: "10rem",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  "& .file_size": {
    fontFamily: "Outfit, sans-serif",
    fontSize: "16px",
    lineHeight: "18px",
    color: "#A3978F",
    marginLeft: "19px",
    fontWeight: 400
  },
  "& .preview_button": {
    lineHeight: "22px",
    fontFamily: "Outfit, sans-serif",
    fontSize: "14px",
    fontWeight: 700,
    color: "#CC9200",
    textTransform: "capitalize",
  },
});

const HeadName = styled(Typography)({
  fontSize: "16px",
  color: "#000000",
  fontWeight: 700,
   '&::first-letter': {
      textTransform: 'capitalize',
    },
  "@media(max-width: 405px)": {
    fontSize: "12px"
  }
});

const MainHeadings = styled(Typography)({
  color: "#000000",
  fontSize: "16px",
  fontWeight: 400,
  '&::first-letter': {
    textTransform: 'capitalize',
  },
  "@media(max-width: 405px)": {
    fontSize: "12px"
  }
});

const HeadItems = styled(Box)({
  gap: "10px",
  display: "flex",
  padding: "0rem 0rem 1rem 2rem"
});

const CompletedDetails = styled(Box)({
  gap: "30px",
  display: "flex",
  justifyContent: "flex-end",
  "@media(max-width: 460px)": {
    gap: "5px",
    width: "100%"
  }
});

const ScheduledNewWaiting = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "5px",
  borderRadius: "8px",
  cursor: "pointer",
  "@media(max-width: 900px)": {
    display: "none"
  }
});

const FindKeyName = styled(Typography)({
  color: "#CC9200",
  fontWeight: 700,
  fontSize: "14px",
  cursor: "pointer"
});

const ScheduledDate = styled(Typography)({
  color: "#CC9200",
  fontWeight: 700,
  fontSize: "14px"
});

const OrderOpenStatus = styled(Box)({
  borderRadius: "50px",
  backgroundColor: "#D1FAE5",
  color: "#059669",
  fontSize: "9px",
  alignItems: "center",
  padding: "10px 20px",
  width: "fit-content",
  borderColor: "#D1FAE5",
  fontWeight: 700
});

const CustomContainer = styled(TableBody)({
  backgroundColor: "#FFFFFF"
});

const DetailsCell = styled(TableCell)({
  color: "#A3978F",
  whiteSpace: "nowrap",
  fontSize: "12px",
  textAlign: "center",
  fontWeight: 700
});

const CustomCell = styled(TableCell)({
  color: "#A3978F",
  textAlign: "center",
  fontSize: "12px",
  fontWeight: 700,
  whiteSpace: "nowrap",
  padding: "0px 10px 0px 10px !important"
});

const CustomButtonUp = styled(Typography)({
  marginBottom: "-14px",
  cursor: "pointer"
});

const CustomButtonDown = styled(Typography)({
  marginTop: "0px",
  cursor: "pointer"
});

const StatusUpdateCell = styled(TableCell)({
  fontSize: "12px",
  fontWeight: 400,
  whiteSpace: "nowrap",
  color: "#000000",
  textAlign: "center"
});

const CustomTableContainer = styled(TableContainer)({
  width: "80%"
});

const StatusUpdateCells = styled(TableCell)({
  fontSize: "12px",
  fontWeight: 400,
  color: "#000000",
  textAlign: "center"
});

const ApproveUpdateDetails = styled(Button)({
  textTransform: "none",
  height: "30px",
  display: "flex",
  color: "#000000",
  alignItems: "center",
  borderRadius: "8px",
  padding: "5px 10px 5px 15px",
  backgroundColor: "rgb(253,242,208)",
  gap: "4px",
  "&:hover": {
    color: "#000000",
    backgroundColor: "rgb(253,242,208)"
  }
});

const ApprovedName = styled(Box)({
  fontSize: "12px",
  fontWeight: 400,
  color: "#000000"
});

const CustomUpadteMenuItem = styled(MenuItem)({
  color: "#000000",
  fontSize: "12px",
  fontWeight: 400,
  "&.changeColor": {
    borderRadius: "10px",
    backgroundColor: "rgb(250,227,162)"
  },
  "&:hover": {
    backgroundColor: "rgb(250,227,162)",
    color: "#000000"
  }
});

const CustomText = styled(Typography)({
  color: "#332500",
  fontSize: "16px",
  fontWeight: 400
});

const CustomNewModal = styled(Modal)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "20px"
});

const ParagraphKeyContainer = styled(Box)({
  width: "30%",
  padding: "25px 20px 80px 20px",
  height: "250px",
  borderRadius: "8px 8px 30px 8px",
  lineHeight: "2rem",
  gap: "25px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  backgroundColor: "white",
  alignItems: "center",
   "@media(max-width: 1260px)": {
    width: "50%"
  },
  "@media(max-width: 767px)": {
    width: "80%"
  },
  "@media(max-width: 600px)": {
    height: "290px",
    gap: "15px",
  },
  "@media(max-width: 475px)": {
    height: "345px",
    gap: "12px",
  },
  "@media(max-width: 466px)": {
    height: "390px",
    gap: "10px",
  },
  "@media(max-width: 400px)": {
    height: "400px",
    gap: "10px"
  }
});

const FindKeyHeading = styled(Typography)({
  color: "#CC9200",
  fontWeight: 700,
  fontSize: "20px",
  "@media(max-width: 467px)": {
    fontSize: "16px"
  },
  "@media(max-width: 360px)": {
    fontSize: "15px"
  }
});

const FindKeyLocation = styled(Typography)({
  color: "#0F172A",
  fontSize: "18px",
  fontWeight: 700
});

const FindKeyPara = styled(Typography)({
  color: "#0F172A",
  fontSize: "18px",
  fontWeight: 400
});

const KeyContainers = styled(Box)({
  display: "flex",
  "@media(max-width: 467px)": {
    flexDirection: "column",
    gap: "10px"
  }
});

const CloseKeyModal = styled(Box)({
  justifyContent: "flex-end",
  display: "flex",
  cursor: "pointer"
});
// Customizable Area End
