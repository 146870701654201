// Customizable Area Start
export const upArrow = require("../assets/up.png");
export const downArrow = require("../assets/down.png");
export const caretRight = require("../assets/caret_right.png");
export const keasyIcon = require("../assets/keasylogo.svg");
export const profileMain = require("../assets/profile.png");
export const activeProfile = require("../assets/activeProfile.png");
export const dashboardLandLogo = require("../assets/dashboard.png");
export const activeMainDashboard = require("../assets/activeDashboard.png");
export const accountsMain = require("../assets/profile.png");
export const activeMainAccount = require("../assets/activeProfile.png");
export const ordersMain = require("../assets/order.png");
export const activeMainOrder = require("../assets/activeorder.png");
export const vendorMain = require("../assets/vendor.png");
export const activeMainVendor = require("../assets/activeSave.png");
export const remindersMain = require("../assets/reminder.png");
export const activeMainReminder = require("../assets/activeReminder.png");
export const propertyMain = require("../assets/property.png");
export const activePropertyMain = require("../assets/activeProperty.png");
export const financialMain = require("../assets/financial.png");
export const activeMainFinancial = require("../assets/activeFinancial.png");
export const reportMain = require("../assets/report.png");
export const activeMainReport = require("../assets/activeReport.png");
export const keyMainChain = require("../assets/keyChain.png");
export const activeKeyMain = require("../assets/activeKey.png");
export const settingsMain = require("../assets/settings.png");
export const activeMainSetting = require("../assets/activeSetting.png");
export const projects = require("../assets/projects.png");
export const activeProjects = require("../assets/activeprojects.png");
export const prefferedVendor = require("../assets/PrefferedVendors.png");
export const SearchBarIcon = require("../assets/SearchIcon.png");
export const AddPlusIcon = require("../assets/PlusCircle.svg");
export const closeIcon = require("../assets/closeImage.svg");
export const pencilIcon = require("../assets/pencilImage.svg");
export const downImageIcon = require("../assets/downImage.svg");
export const upImageIcon = require("../assets/upImage.svg");
export const filterImageIcon = require("../assets/filterImage.svg");
export const crossImageIcon = require("../assets/crossImage.svg");
export const bottomImageIcon = require("../assets/bottomImage.svg");
export const viewHexagonIcon = require("../assets/viewHexagon.svg");
export const vendorInfoImageIcon = require("../assets/vendorInfoImage.svg");
export const DefaultImgPlcIcon = require("../assets/DefaultImgPlc.svg");
// Customizable Area End