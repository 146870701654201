import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {getName,} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { imgPasswordVisible, imgPasswordInVisible } from "./assets";
import { getStorageData } from "framework/src/Utilities";


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start  
  navigation: any;
  id: string;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start  
  accountStatus: any;
  passwordSchema:any,
  enablePasswordField: Boolean;
  passwordRules: any;
  btnConfirmPasswordShowHide: Boolean;

  enablePassField:boolean;
  enableConfirmPasswordField:boolean;
  password:string;
  confirmPassword:string,
  hasEightCharacters: boolean;
  hasOneLowercaseLetter: boolean;
  hasOneNumber: boolean;
  hasOneUppercaseLetter: boolean;
  isPasswordValid: boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class NewPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start  
  isChangePassword: boolean = false;
  imgPasswordVisible: any = imgPasswordVisible;
  imgPasswordInVisible: any = imgPasswordInVisible;
  placeholderIsReTypePassword: string = configJSON.placeholderIsReTypePassword;
  buttonTextIsNext: string = configJSON.buttonTextIsNext;
  buttonColorForNextButton: any = configJSON.buttonColorForNextButton;
  buttonTitleIsOk: string = configJSON.buttonTitleIsOk;
  buttonColorForOkButton: any = configJSON.buttonColorForOkButton;
  requestGoToConfirmationCallId: any;
  updatedPasswordApiId: string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);  
     // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
       // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),  
       // Customizable Area End    
    ]; 
    this.receive = this.receive.bind(this);    
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    
    this.state = {
      // Customizable Area Start    
      accountStatus: "ChooseAccountType",
      passwordSchema: "",
      enablePasswordField: true,
      passwordRules: "",
      btnConfirmPasswordShowHide: true,
 
      enablePassField:false,
      enableConfirmPasswordField:false,
      password:"",
      confirmPassword:"",
      hasEightCharacters: true,
      hasOneLowercaseLetter: true,
      hasOneNumber: true,
      hasOneUppercaseLetter: true,
      isPasswordValid: true,  
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start      
   
    const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      ); 
      if (apiRequestCallId === this.updatedPasswordApiId) {
        if (responseJson && !responseJson.errors) {
          this.props.navigation.navigate("TenantDashboard");
        }
       
      } 
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();    
  }
  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  goToConfirmationAfterPasswordChange(values: {
    password: any;
    confirmPassword: any;
  }) {
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.passwordRecoveryChangePasswordAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  setPassword = (text: string) => {
    this.setState({
      password: text,
    });
  };
  setConfirmPassword = (text: string) => {
    this.setState({
      confirmPassword: text,
    });
  };
  setIsPasswordValid = (state: boolean) => {
    this.setState({
      isPasswordValid: state,
    });
  };

  setHasEightCharacters = (state: boolean) => {
    this.setState({
      hasEightCharacters: state,
    });
  };

  setHasOneLowercaseLetter = (state: boolean) => {
    this.setState({
      hasOneLowercaseLetter: state,
    });
  };
  setHasOneNumber = (state: boolean) => {
    this.setState({
      hasOneNumber: state,
    });
  };

  setHasOneUppercaseLetter = (state: boolean) => {
    this.setState({
      hasOneUppercaseLetter: state,
    });
  };

  async handleSubmit(
    values: {
      password: string;
      confirmPassword: string;
    },
    setSubmitting: (isSubmitting: boolean) => void
  ) {
      const token = await getStorageData("authToken")
      const header = {
        token: token ,
        'Content-Type': 'application/json',
      };
      const body = {
        data: {
          password: this.state.password,
          password_confirmation: this.state.confirmPassword
        }
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
   
      this.updatedPasswordApiId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.updateNewPasswordAPiEndPoint
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPutMethod
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  validatePasswordField = (passwordValue: string) => {
    let hasEightCharacters: boolean = passwordValue.length > 7;
    let hasOneLowercaseLetter: boolean = /[a-z]/.test(passwordValue);
    let hasOneNumber: boolean = /\d/.test(passwordValue);
    let hasOneUppercaseLetter: boolean = /[A-Z]/.test(passwordValue);
    this.setHasEightCharacters(hasEightCharacters);
    this.setHasOneLowercaseLetter(hasOneLowercaseLetter);
    this.setHasOneNumber(hasOneNumber);
    this.setHasOneUppercaseLetter(hasOneUppercaseLetter);
    this.setIsPasswordValid(
      hasOneUppercaseLetter &&
        hasOneNumber &&
        hasOneLowercaseLetter &&
        hasEightCharacters
    );
  };

  handleClickShowPassword = (state:string) => {
    if(state=="password")
    {
      this.setState({
        enablePassField: !this.state.enablePassField,
      });
    }else{
      this.setState({
        enableConfirmPasswordField: !this.state.enableConfirmPasswordField,
      });
    }
   
  };
  handleClickSignUp = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  // Customizable Area End
  }