Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "DownloadOptions";
exports.labelBodyText = "DownloadOptions Body";

exports.btnExampleTitle = "CLICK ME";
exports.downloadOptionUrl = "download_options";

exports.dashboardLandloard = "Dashboard";
exports.tenantDashboard = "tenantdashboard";
exports.serviceRequests = "Service Requests";
exports.servicelabel = "tenantservice";
exports.lease = "Lease"
exports.leaselabel = "tenantlease";
exports.paymentHistory = "Payment History";
exports.paymentHistorylabel = "tenanthistory";
exports.chatLandloard = "Chat with Keasy";
exports.chatlandlabel = "Chat";
exports.chatTenantabel = "Chat";

exports.getLeaseDataApiEndPoint = 'account_block/tenants/tenant_lease';
exports.validationApiMethodType = "GET";

exports.getDownloadPdfApiEndPoint = '/account_block/tenants/tenant_lease';



// Customizable Area End
