import React from "react";

// Customizable Area Start
import { Box, Button, Typography } from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";
import {
  TextField,
  Grid,
  Select,
  styled,
  Checkbox,
  Table,
  TableBody,
  ClickAwayListener,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  MenuItem,
  IconButton,
} from '@mui/material';
import NavigationSidebar from "../../../components/src/NavigationSidebar.web";
import { dashboardOrderLogo, activeOrderDashboard, accountsOrder, activeOrderAccount, ordersIcon, activeOrderIcon, vendorOrder, activeOrderVendor, remindersOrder, activeOrderReminder, propertyOrder, activePropertyOrder, financialOrder, activeOrderFinancial, reportOrder, activeOrderReport, keyOrderChain, activeKeyOrder, settingsOrder, activeOrderSetting, keasIcon, searchIcon, filterIcon, plusIcon, findKeys, waitingBill, scheduledDate, editIcon, workIcon, declineIcon, transferIcon, doneIcon, hexagon, halfHexagon, keys} from './assets';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
export const reverse = require("../../analytics/assets/reverse.svg");
export const eye = require("../../analytics/assets/visibility.svg");
import { Transaction } from "./AnalyticsController.web";
import { KeyboardArrowUp, KeyboardArrowDown, MoreVert, ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
// Customizable Area End

import AnalyticsController, {
  Props,
  configJSON,
} from "./AnalyticsController.web";

export default class Analytics extends AnalyticsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderSortableTableCell = (property: string, label: string, unitIndex: number) => {
    return (
      <CustomTableCell key={property}>
        <Box display="flex" alignItems="center" justifyContent={"center"}>
          {label}
          <Box display="flex" flexDirection="column" marginLeft={"10px"}>
            <CustomIconButtonUp
              data-test-id={`sortUpId,${unitIndex}`}
              onClick={() => this.handleSortRequest(property, "asc", unitIndex)}
            >
              <ArrowDropUp
              />
            </CustomIconButtonUp>
            <CustomIconButtonDown
              data-test-id={`sortDownId,${unitIndex}`}
              onClick={() => this.handleSortRequest(property, "desc", unitIndex)}
            >
              <ArrowDropDown
              />
            </CustomIconButtonDown>
          </Box>
        </Box>
      </CustomTableCell>
    );
  };
  
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <WorkOrderStyle>
        <NavigationSidebar
          data-test-id={"navId"}
          activeItem={this.state.activeOrderItem}
          sidebarItems={[
            { label: configJSON.dashboardOrder, labelKey: configJSON.dashboardOrderlabel, icon: dashboardOrderLogo, activeIcon: activeOrderDashboard, pathName: configJSON.dashboardOrderlabel },
            { label: configJSON.myAccountOrder, labelKey: configJSON.settingOrderlabel, icon: accountsOrder, activeIcon: activeOrderAccount, pathName: configJSON.myAccountOrderabel },
            { label: configJSON.workOrder, labelKey: configJSON.worksOrderlabel, icon: ordersIcon, activeIcon: activeOrderIcon, pathName: configJSON.worksOrderlabel },
            { label: configJSON.preferredOrder, labelKey: configJSON.prevendorOrderlabel, icon: vendorOrder, activeIcon: activeOrderVendor, pathName: configJSON.prevendorOrderlabel },
            { label: configJSON.remindersOrder, labelKey: configJSON.reminOrderlabel, icon: remindersOrder, activeIcon: activeOrderReminder, pathName: configJSON.reminOrderlabel },
            { label: configJSON.mypropOrder, labelKey: configJSON.mypropOrderlabel, icon: propertyOrder, activeIcon: activePropertyOrder, pathName: configJSON.mypropOrderlabel },
            { label: configJSON.financiaOrder, labelKey: configJSON.financOrderlabel, icon: financialOrder, activeIcon: activeOrderFinancial, pathName: configJSON.financOrderlabel },
            { label: configJSON.reportOrder, labelKey: configJSON.reportOrderlabel, icon: reportOrder, activeIcon: activeOrderReport, pathName: configJSON.reportOrderlabel },
            { label: configJSON.chatOrder, labelKey: configJSON.chatOrderlabel, icon: keyOrderChain, activeIcon: activeKeyOrder, pathName: configJSON.chatOrderlabel },
            { label: configJSON.settingOrder, labelKey: configJSON.workOrder, icon: settingsOrder, activeIcon: activeOrderSetting, pathName: configJSON.settingOrderlabel }
          ]}
          onClickSidebar={this.handleSideNav}
          keasylogo={keasIcon}
          openDrawer={this.state.openWorkOrder}
          onClickDrawer={this.handleWordDrawer}

        />
        <Box display={"flex"} flexDirection={"column"} justifyContent={"space-between"} width={"100%"}
          sx={{
            padding: {
              flexGrow: 1,
              borderLeft: "1px solid rgb(208,203,199)",
            },
          }}>
          <MainWorkContainer>
            <WorkStyles>
              <WorkOrderHead data-test-id="seeTextId">
                Financial Overview 
              </WorkOrderHead>
              <Grid item spacing={2}>
                <Grid item xs={12} md={6} justifyContent="flex-start">
                <ClickAwayListener  data-test-id="click-away"  onClickAway={() => this.setState({ startDateOpen: false,endDateOpen:false })}  mouseEvent="onMouseDown">
                  <Box className="search-main-content">
                      <Box className="left">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Box sx={{ marginBottom: 2, width:"23%" }}>
                              <DesktopDatePicker
                                  data-test-id="desktopDatePicker"
                                  value={this.state.startDate}
                                  onChange={(date) => this.handleDateChange(date, 'startDate')}
                                  onClose={() => this.setState({ startDateOpen: false })}
                                  open={this.state.startDateOpen} 
                                  renderInput={(params) =>
                                    <TextField
                                    data-test-id="startDateDatePicker"
                                    {...params}
                                    label="Start date"
                                    onClick={()=>{this.handleOpen("startDate")}}
                                    InputProps={{
                                      ...params.InputProps,
                                      style: {
                                        width: '100%', 
                                        borderRadius: '8px',
                                        border: '1px solid #BAB1AB', 
                                        padding: '0rem 1rem', 
                                        fontFamily: 'Outfit, sans-serif', 
                                        fontSize: '16px', 
                                        fontWeight: 400, 
                                        lineHeight: '18px', 
                                      },
                                    }}
                                    InputLabelProps={{
                                      style: {
                                        fontFamily: 'Outfit', 
                                        fontSize: '16px',
                                        fontWeight: 400, 
                                        lineHeight: '19px', 
                                        color: '#A3978F',
                                      },
                                    }}
                                    sx={{
                                      '& .MuiInputBase-root': {
                                        paddingRight: '8px',
                                      },
                                      '& .MuiSvgIcon-root': {
                                        color: '#BAB1AB',
                                      },
                                    }}
                                  />
                                    }
                                  label={"start date"}
                                />
                            </Box>
                          </LocalizationProvider>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Box sx={{ marginBottom: 2,width:"23%" }}>
                              <DesktopDatePicker
                                  data-test-id="endDesktopDatePicker"
                                  value={this.state.endDate}
                                  onChange={(date) => this.handleDateChange(date, 'endDate')}
                                  onClose={() => this.setState({ endDateOpen: false })}
                                  open={this.state.endDateOpen}
                                  renderInput={(params) =>
                                    <TextField
                                    data-test-id="endDesktopDatePickerField"
                                    {...params}
                                    label="End date"
                                    onClick={()=>{this.handleOpen("endDate")}}
                                    InputProps={{
                                      ...params.InputProps,
                                      style: {
                                        width: '100%', 
                                        borderRadius: '8px',
                                        border: '1px solid #BAB1AB', 
                                        padding: '0rem 1rem', 
                                        fontFamily: 'Outfit, sans-serif', 
                                        fontSize: '16px', 
                                        fontWeight: 400, 
                                        lineHeight: '18px', 
                                      },
                                    }}
                                    InputLabelProps={{
                                      style: {
                                        fontFamily: 'Outfit', 
                                        fontSize: '16px',
                                        fontWeight: 400, 
                                        lineHeight: '19px', 
                                        color: '#A3978F',
                                      },
                                    }}
                                    sx={{
                                      '& .MuiInputBase-root': {
                                        paddingRight: '8px',
                                      },
                                      '& .MuiSvgIcon-root': {
                                        color: '#BAB1AB',
                                      },
                                    }}
                                  />
                                    }
                                />
                            </Box>
                          </LocalizationProvider>
                          <Select
                              data-test-id="properties-block"
                              multiple
                              value={this.state.property}  
                              onChange={this.handleDropdown}  
                              displayEmpty
                              renderValue={(selected) => {
                                return <label>Properties</label>;
                              }}
                              sx={{
                                height:"3.7rem",
                                backgroundColor: '#FFF1CC',
                                borderRadius: '8px',
                                border: 'none',
                                boxShadow: 'none',
                                '& .MuiSelect-select': {
                                  display: 'flex',
                                  alignItems: 'center',
                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                  border: 'none',
                                },
                                '& label': {
                                  fontSize: '16px',
                                  fontWeight: 'bold',
                                  color: '#000',
                                },
                                '& .MuiSvgIcon-root': {
                                  color: '#000',
                                },
                              }}
                              MenuProps={{
                                PaperProps: {
                                  sx: {
                                    maxHeight: 200,
                                    '& .MuiMenuItem-root': {
                                      minHeight: 'auto',
                                    },
                                  },
                                },
                              }}
                          >
                              {this.state.allProperties?.map((option) => (
                                <MenuItem key={option.unit_id} value={option.name}>
                                  <Box component="li">
                                    {option.name}
                                  </Box>
                                </MenuItem>
                              ))}
                          </Select>
                          <Select
                            data-test-id="description-block"
                            multiple
                            value={this.state.selectedDescriptions}
                            onChange={(event) => this.setState({ selectedDescriptions: event.target.value as string[]})}
                            displayEmpty
                            renderValue={(selected) => {
                          return <label>Description</label>;
                            }}
                            sx={{
                              height:"3.7rem",
                              backgroundColor: '#FFC123',
                              borderRadius: '8px',
                              border: 'none',
                              boxShadow: 'none',
                              '& .MuiSelect-select': {
                                display: 'flex',
                                alignItems: 'center',
                              },
                              '& .MuiOutlinedInput-notchedOutline': {
                                border: 'none',
                              },
                              '& label': {
                                fontSize: '16px',
                                fontWeight: '700',
                                color: '#0F172A',
                                fontFamily: "Outfit",
                              },
                              '& .MuiSvgIcon-root': {
                                color: '#000',
                              },
                            }}
                            MenuProps={{
                              PaperProps: {
                                sx: {
                                  maxHeight: 200,
                                  '& .MuiMenuItem-root': {
                                    minHeight: 'auto',
                                  },
                                },
                              },
                            }}
                          >
                            {configJSON.descriptionOptions.map((option:any) => (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                        </Select>
                            <Button
                              data-test-id="apply-btn"
                              variant="contained"
                              style={{
                                  width:"5rem",
                                  height:"3.7rem",
                                  fontFamily: "outfit",
                                  fontSize: "16px",
                                  fontWeight: 700,
                                  color: "#000000",
                                  textAlign: "center",
                                  backgroundColor: "#FFC123",
                                  borderRadius: "8px",
                                  textTransform: "none",
                                  cursor: "pointer"}}
                                  onClick={this.handleFilter}
                            >
                            Apply  
                          </Button>
                      </Box>
                      <Box className="right" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <Button
                            variant="contained"
                            startIcon={<AddCircleOutlineIcon />}
                            sx={{
                              width:"14rem",
                              height:"2.2rem",
                              backgroundColor: '#FFF1CC',
                              color: '#000', 
                              borderRadius: '8px', 
                              fontSize: '16px', 
                              fontFamily: "Outfit",
                              fontWeight: 'bold', 
                              padding: '10px 16px',
                              textTransform: 'none', 
                              boxShadow: 'none',
                              '&:hover': {
                                backgroundColor: '#FFF1CC', 
                                boxShadow: 'none', 
                              },
                            }}
                            onClick={this.handleNewTransaction}
                          >
                              Add new transaction
                         </Button>
                      </Box>
                  </Box>
                </ClickAwayListener>
               
                </Grid>
                <Grid item md={6} xs={12}>
                  <Box className="filter-content">
                    <Box className="filter">
                      <img
                        className="icon"
                        src={filterIcon} alt="filter" />
                      <Typography className="filter-data" data-test-id="headClickId">Filters</Typography>
                    </Box>
                  </Box>
                </Grid>

                <BorderContainer key={1}>
                <Grid container mt={2} spacing={2}>
                  <CustomApproveGrid >
                      <SelectedCounts>
                        <SelectedOrder>0 Selected</SelectedOrder>
                        <SelectedOrder>&nbsp;</SelectedOrder>
                      </SelectedCounts>
                  
                      <ScheduledNewWaiting > 
                            <ScheduledDate>Mark as complete</ScheduledDate>
                      </ScheduledNewWaiting>
                      <ScheduledNewWaiting >
                              <img
                                className="image-icon"
                                src={reverse} alt="bill" />
                              <ScheduledDate>Reverse payment</ScheduledDate>
                      </ScheduledNewWaiting>
                      <ScheduledNewWaiting data-test-id = "eye" onClick={() => this.handleDeliquency()}>
                        <img
                          className="image-icon"
                          src={eye} alt="bill" />
                        <ScheduledDate data-test-id="waitingId">See deliquency report</ScheduledDate>
                      </ScheduledNewWaiting>
                  </CustomApproveGrid>
                </Grid>
                <TableContainer >
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell padding="checkbox">
                          <CustomCheckbox
                            data-test-id="selectAllId"
                            onChange={(eventDetails) => this.handleSelectAllClick(eventDetails, 1)}
                          />
                        </TableCell>
                        {this.renderSortableTableCell("Transaction Date", "Transaction Date", 1)}
                        {this.renderSortableTableCell("Property", "Property", 3)}
                        {this.renderSortableTableCell("Ledger type", "Ledger", 5)}
                        {this.renderSortableTableCell("Sender", "Sender", 6)}
                        {this.renderSortableTableCell("Cost", "Cost", 4)}
                        <TableCell />
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                    {
                    this.state.transactions.map((rowTrans: Transaction, workIndex: number) => {
                      const isExpanded = this.state.expanded === rowTrans.id;
                          return (
                            <React.Fragment key={`workOrder-${rowTrans.id}`}>
                              <TableRow 
                             >
                                <CustomDetailTableCell isExpanded={isExpanded} padding="checkbox">
                                  <CustomCheckbox
                                    data-test-id="checkedClickTestId"
                                  />
                                </CustomDetailTableCell>
                                <CustomDetailTableCell isExpanded={isExpanded}>{rowTrans.transaction_date}</CustomDetailTableCell>
                                <CustomDetailTableCell isExpanded={isExpanded}>{rowTrans.property_name}-{rowTrans.unit_name}</CustomDetailTableCell>
                                <CustomDetailTableCell isExpanded={isExpanded}>{rowTrans.ledger_type}</CustomDetailTableCell>
                                <CustomDetailTableCell isExpanded={isExpanded}>{rowTrans.sender}</CustomDetailTableCell>
                                <CostTableCell isExpanded={isExpanded} status={rowTrans.transaction_status} >{rowTrans.amount}</CostTableCell>

                                <CustomDetailTableCell isExpanded={isExpanded}> 
                                    <IconButton data-test-id="expandClickTestId" onClick={() => this.handleExpandClick(rowTrans.id)}>
                                      {this.state.expanded === rowTrans.id ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                    </IconButton>
                                </CustomDetailTableCell>
                               
                              </TableRow>
                                {this.state.expanded === rowTrans.id && ( 
                                         <TableRow>
                                         <TableCell colSpan={8}>
                                         <InnerTableBg>
                                                 <TableContainer>
                                                   <Table>
                                                     <TableHead>
                                                       <TableRow>
                                                       <DetailsTableCell>Reference</DetailsTableCell>
                                                       <DetailsTableCell>Transaction status</DetailsTableCell>
                                                       <DetailsTableCell>Details</DetailsTableCell>
                                                       <DetailsTableCell/>
                                                       <DetailsTableCell/>
                                                       <DetailsTableCell/>
                                                       </TableRow>
                                                     </TableHead>
                                                    
                                                             <TableRow >
                                                               <TransectionTableCell>{rowTrans.reference_number}</TransectionTableCell>
                                                               <TableCell sx={{ borderBottom: 'none' }}>
                                                                <StatusTableCell status={rowTrans.transaction_status}> {rowTrans.transaction_status.toUpperCase()}</StatusTableCell>
                                                               </TableCell>
                                                               <TableCell
                                                                    sx={{
                                                                      maxWidth: '200px',  
                                                                      whiteSpace: 'normal', 
                                                                      overflow: 'hidden',  
                                                                      wordWrap: 'break-word',
                                                                      borderBottom: 'none',
                                                                      fontSize: "12px",
                                                                      fontFamily:"Outfit",
                                                                      fontWeight: 400,
                                                                    }}
                                                                  >
                                                                    {rowTrans.details}
                                                                  </TableCell>
                                                               <TableCell sx={{ borderBottom: 'none' }}>
                                                                  <ScheduledNewWaiting data-test-id="acceptPayment" onClick={() => this.handleMarkAsComplete(rowTrans.id)}> 
                                                                      <ScheduledDate>Mark as complete</ScheduledDate>
                                                                    </ScheduledNewWaiting>
                                                               </TableCell>
                                                               <TableCell sx={{ borderBottom: 'none' }}>
                                                                  <ScheduledNewWaiting data-test-id="ReversePayment" onClick={() => this.handleReversePayment(rowTrans.id)}>
                                                                      <img className="image-icon" src={reverse} alt="bill" />
                                                                      <ScheduledDate>Reverse payment</ScheduledDate>
                                                                    </ScheduledNewWaiting>
                                                                
                                                               </TableCell>
                                                               <TableCell sx={{ borderBottom: 'none' }}>
                                                                  <ScheduledNewWaiting data-test-id="delinquency" onClick={() => this.handleDeliquency()}>
                                                                      <img className="image-icon" src={eye} alt="bill" />
                                                                      <ScheduledDate data-test-id="waitingId">See delinquency report</ScheduledDate>
                                                                    </ScheduledNewWaiting>
                                                               </TableCell>
                                                             </TableRow>
                                                  
                                                   </Table>
                                                 </TableContainer>
                                          </InnerTableBg>
                                           
                                          
                                         </TableCell>
                                       </TableRow>
                                 )}
                            </React.Fragment>
                          );
                        })}
                    </TableBody>
              
                  </Table>
                </TableContainer>
                </BorderContainer>
              </Grid>
          
            </WorkStyles>
          </MainWorkContainer>
          <Box className="bgImgWrapper" data-test-id="bgImgId">
            <Box className="backgroundImage">
              <img src={hexagon} alt="hexagon-one" className="hexImg" />
            </Box>
            <Box className="backgroundImage">
              <img src={hexagon} alt="hexagon-two" className="hexImg" />
            </Box>
            <Box className="backgroundImage">
              <img src={hexagon} alt="hexagon-three" className="hexImg" />
            </Box>
            <Box className="backgroundImage">
              <img src={halfHexagon} alt="half-hexagon" className="hexImg" />
            </Box>
          </Box>
        </Box>
      </WorkOrderStyle>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const WorkOrderStyle = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  "@media(max-width: 992px)": {
    flexDirection: "column"
  },
  "&.webStyle": {
    backgroundColor: "red"
  },
  "& .bgImgWrapper": {
    display: "flex",
    justifyContent: "flex-end",
    width: "97%",
    marginTop: "2rem",
    "@media (max-width:1220px)": {
      width: "88%"
    },
    "@media (max-width:992px)": {
      display: "none",
    }
  },
  "& .backgroundImage": {
    width: "250px",
    "@media (max-width:1220px)": {
      width: "180px"
    },
  },
  "& .hexImg": {
    objectFit: "cover"
  }
});
const BorderContainer = styled(Box)({
  border: "1px solid #FFD466",
  borderRadius: "8px 8px 32px 8px",
  padding: "1rem",
  marginTop: "1rem",
  display: "flex",
  flexDirection: "column",
  "& .scrollable-container": {
    height: "300px",
    overflowY: "auto"
  },
  "& .isUnitsDisabled": {
    display: "none"
  }
});
const CustomIconButtonUp = styled(Typography)({
  marginBottom: "-14px",
  cursor: "pointer"
});

const DetailsTableCell = styled(TableCell)({
  color: "#A3978F",
  fontSize: "14px",
  textAlign: "center",
  fontWeight: 700,
  whiteSpace: "nowrap",
  fontFamily:"Outfit",
});
const TransectionTableCell = styled(TableCell)({
  fontSize: "12px",
  fontFamily:"Outfit",
  fontWeight: 400,
  color: "#000000",
  textAlign: "center",
  whiteSpace: "nowrap",
  borderBottom:"none" 
});
const StatusTableCell = styled(TableCell)<{ status: string }>(({ status }) => ({
  fontFamily: "Outfit",
  fontWeight: 700,
  padding:"0.5rem",
  color: status === configJSON.completed ? "#059669" : status === configJSON.failed ? "FF0000" : "#FFA500",
  backgroundColor: status === configJSON.completed  ? "#D1FAE5" : status === configJSON.failed ? "#FFE5E5" : "#FFF3E0",
  borderRadius: "40px",
  textAlign: "center",
  textTransform: "capitalize",
  borderBottom:"none" 
}));
const OrderUnitContainer = styled(Box)({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: "20px"
});
const SelectedCounts = styled(Box)({
  display: "flex",
  marginLeft:"1rem",
  "@media(max-width: 900px)": {
    display: "none"
  }
});
const CustomCheckbox = styled(Checkbox)({
  color: "rgba(0, 0, 0, 0.54)",
  "&.Mui-checked": {
    color: "rgb(195,149,49)"
  },
  "&.MuiCheckbox-indeterminate": {
    color: "rgb(195,149,49)"
  }
});
const ScheduledNewWaiting = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap:"0.5rem",
  width:"11rem",
  cursor: "pointer",
  "@media(max-width: 900px)": {
    display: "none"
  }
});
const OrderUnitName = styled(Typography)({
  fontSize: "16px",
  fontWeight: 700,
  color: "#000000"
});
const CustomApproveGrid = styled(Grid)({
  display: "flex",
  marginLeft:"1.5rem",
  marginBottom:"1rem",

  "@media(max-width: 900px)": {
    gap: "10px",
    justifyContent: "flex-start"
  }
});
const CustomIconButtonDown = styled(Typography)({
  marginTop: "0px",
  cursor: "pointer"
});
const CustomTableCell = styled(TableCell)({
  color: "#A3978F",
  textAlign: "center",
  fontSize: "12px",
  fontWeight: 700,
  padding: "0px 10px 0px 10px !important",
  whiteSpace: "nowrap"
});
const CustomTableContainer = styled(TableBody)({
  backgroundColor: "#FFFFFF"
});
const ScheduledDate = styled(Typography)({
  color: "#CC9200",
  fontFamily: 'Outfit', 
  fontSize: "14px",
  fontWeight: 700
});
const InnerTableBg = styled(Box)({
  borderRadius: "10px",
  backgroundColor: "#F8FAFC",
  paddingLeft:"5rem",
  marginTop:"1rem",
  marginBottom:"1rem",
});
const CustomDetailTableCell = styled(TableCell)<{ isExpanded: boolean }>(({ isExpanded }) => ({
  fontSize: "12px",
  color: "#A3978F",
  fontWeight: 400,
  textAlign:"center",
  borderBottom: isExpanded ? "none" : "1px solid #e0e0e0" 
}));
const CostTableCell = styled(TableCell)<{ isExpanded: boolean; status: string }>(
  ({ isExpanded, status }) => ({
    fontSize: "12px",
    color: status === configJSON.completed ? "#059669" : status === configJSON.failed? "#FF0000" : "#FFA500",
    fontWeight: 400,
    borderBottom: isExpanded ? "none" : "1px solid #e0e0e0" 
  })
);

const SelectedOrder = styled(Typography)({
  color: "#A3978F",
  fontSize: "14px",
  fontWeight: 700,
  marginRight:"1rem",
  fontFamily:"Outfit"
});

const WorkStyles = styled(Box)({
  width: "90%",
  marginTop: "42px",
  "& .search-main-content": {
    display: "flex",
    "@media(max-width:600px)": {
      width: "100%",
      position: "relative",
      height: "100%",
      flexDirection: "row",
      display: "flex",
      "@media(max-width: 420px)": {
        gap: "5px"
      }
    },
  },
  "& .left": {
    display: "flex",
    gap:"1rem",
  },
  "& .right": {
    display: "flex",
  },
  "& .search-contents": {
    border: "1px solid #CBD5E1",
    borderRadius: "50px",
    height: "44px",
    display: "flex",
    paddingLeft: "15px",
    alignItems: "center",
    gap: "13px",
  },
  "& .search-icons": {
    height: "24px",
    width: "24px"
  },
  "& .search-text": {
    fontWeight: 400,
    fontSize: "16px",
    color: "#A3978F",
    "@media(max-width: 420px)": {
      fontSize: "12px"
    }
  },
  "& .search-inputs": {
    width: "100%"
  },
  "& .properties-dropdown": {
    width: "142px",
    height: "44px",
    backgroundColor: "rgb(253,242,208)",
    display: "flex",
    alignItems: "center",
    textTransform: "none",
    borderRadius: "8px",
    padding: "2px 10px 2px 10px",
    justifyContent: "space-around",
    cursor: "pointer",
    color: "#000000",
    "&:hover": {
      backgroundColor: "rgb(253,242,208)",
      color: "#000000"
    },
    "@media(max-width: 420px)": {
      fontSize: "12px",
      width: "105px",
      height: "30px"
    }
  },
  "& .view-completed": {
    width: "180px",
    backgroundColor: "rgb(253,242,208)",
    height: "44px",
    display: "flex",
    alignItems: "center",
    padding: "2px 10px 2px 10px",
    borderRadius: "8px",
    justifyContent: "space-around",
    cursor: "pointer",
    "@media(max-width: 460px)": {
      fontSize: "12px",
      height: "35px",
      width: "100%"
    },
    "@media(max-width: 347px)": {
      padding: "2px 5px 2px 5px"
    }
  },
  "& .icon": {
    mixBlendMode: "multiply"
  },
  "& .image-icon": {
    width: "16px",
    height: "16px"
  },
  "& .text-data": {
    fontSize: "16px",
    fontWeight: 700,
    "@media(max-width: 420px)": {
      fontSize: "12px"
    }
  },
  "& .new-data": {
    fontSize: "18px",
    color: "#CC9200",
    fontWeight: 700,
    "@media(max-width: 420px)": {
      fontSize: "12px"
    }
  },
  "& .new-property": {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    borderRadius: "8px",
    justifyContent: "space-around",
    paddingLeft: "5px",
    paddingRight: "5px",
    cursor: "pointer"
  },
  "& .filter-content": {
    display: "flex",
    alignItems: "center",
    paddingRight: "20px"
  },
  "& .filter": {
    display: "flex",
    alignItems: "center",
    gap: "15px",
  },
  "& .filter-data": {
    fontSize: "12px",
    fontWeight: 700,
    color: "#000000"
  },
  "& .clear-data": {
    fontSize: "12px",
    fontWeight: 700,
    color: "#CC9200",
    cursor: "pointer"
  },
  "& .isUnitsDisabled": {
    display: "none"
  }
});
const MainWorkContainer = styled(Box)({
  width: "100%",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  "@media(max-width: 992px)": {
    padding: "0px 0px 0px 0px"

  }
});
const WorkOrderHead = styled(Typography)({
  fontSize: "24px",
  fontWeight: 700,
  color: "#000000",
  marginBottom: "20px"
});
// Customizable Area End
