import React from "react";

import {
    
    Box,
    Grid,
    Button,
    Paper,
    Typography,
    styled,

    // Customizable Area Start



    // Customizable Area End
    
} from "@mui/material";

// Customizable Area Start

import CustomTimePicker from "../../../components/src/CustomTimePicker.web"
import {
    Home_h, bell_b, dashboard_g, financial_f, Key_k,
    Key_logo
} from "./assets";
import {
    createTheme,
    ThemeProvider,

} from "@mui/material/styles";
import NavigationSidebar from "../../../components/src/NavigationSidebar.web";

import CustomDatePicker from "../../../components/src/CustomDatePicker.web";

import { Formik } from 'formik';
import * as Yup from 'yup';



const validationSchemas = Yup.object().shape({
    propertys: Yup.number().required('Property is required'),
    categorys: Yup.number().required('Category is required'),
    workNeededs: Yup.string().required('Work needed is required'),
    dueDates: Yup.date().required('Due date is required').nullable(),
    dueHours: Yup.string().required('Due time is required'),
    dueFrequencys: Yup.string().required('Select frequency'),
    remindMeOnDates: Yup.date().required('Reminder date is required').nullable(),
    remindMeOnHours: Yup.string().required('Reminder time is required'),
    remindMeFrequencys: Yup.string().required('Select frequency'),
    permissionToEnters: Yup.boolean().nullable().required('Permission to enter is required'),
    permissionDescriptions: Yup.string().required('Permission description is required'),
    descriptions: Yup.string().required('Description is required'),
    documentss: Yup.array().min(1, 'At least one document is required'),
    units: Yup.number().required('Unit is required'),
    vendorIds: Yup.number().required('Please choose a vendor'),
});
const theme = createTheme({
    palette: {
        primary: {
            main: "#6200ee",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});

// Customizable Area End

import AutomatedworkflowController, {
    Props,
    configJSON,

} from "./AutomatedworkflowController";

export default class Automatedworkflow extends AutomatedworkflowController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
   
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start


            <>
                <ThemeProvider theme={theme}>
                     <AddWorkOrderStyle>
                        <NavigationSidebar
                            data-test-id={"sidebarMenuIds"}
                            activeItem={this.state.activeMainNewrequest}
                            sidebarItems={[
                                { label: configJSON.dashboardLandloard1, icon: dashboard_g, labelKey: configJSON.dashboardLandlabel, pathName: configJSON.dashboardLandlabel },

                                { label: configJSON.serviderequest, icon: bell_b, labelKey: configJSON.reminlandlabel, pathName: configJSON.reminlandlabel },
                                {
                                    label: configJSON.tenentNR, icon: Home_h, labelKey: configJSON.myproplandlabel1, activeIcon: Home_h, pathName: configJSON.myproplandlabel1,
                                },
                                { label: configJSON.financialandloardnewR, icon: financial_f, labelKey: configJSON.financlandlabel, pathName: configJSON.financlandlabel },
                                { label: configJSON.chatLandloard1, icon: Key_k, labelKey: configJSON.chatlandlabel, pathName: configJSON.chatlandlabel },

                            ]}
                            onClickSidebar={this.handleMainItemClicknewrequest}
                            keasylogo={Key_logo}
                            openDrawer={this.state.openTenentDrawers}
                            onClickDrawer={this.handleTenentrequestDrawers} />
                        <Box className="rightBoxs">
                            <Box className="headingreq" style={{ display: "flex", justifyContent: "space-between" }}>
                                <Box>New Request</Box>
                                 </Box>
                            <Grid container spacing={2}>
                                <Grid item md={9} sm={12} xs={12}>
                                    <Box className="workOrderFormreq">
                                        <Formik
                                            enableReinitialize={true}
                                            validateOnBlur={true}
                                            initialValues={{
                                                propertys: '',
                                                categorys: '',
                                                workNeededs: '',
                                                dueDates: null,
                                                dueHours: '',
                                                dueFrequencys: '',
                                                remindMeOnDates: null,
                                                remindMeOnHours: '',
                                                remindMeFrequencys: '',
                                                permissionToEnters: null,
                                                permissionDescriptions: '',
                                                costs: false,
                                                descriptions: '',
                                                documentss: [],
                                                units: '',
                                                vendorIds: '',
                                            }}
                                            validationSchema={validationSchemas}
                                            onSubmit={async ( formikHelpers) => {
                                                try {
                                                  this.addAppointment();
                                                } catch (error) {
                                                }
                                              }}
                                            data-test-id="Formik"
                                        >
                                            {({ errors, values, handleSubmit, setFieldValue }) => {
                                                return (
                                                    <form onSubmit={handleSubmit}>
                                                        <Box className="headingOne">
                                                            <Box><Typography className="headingtwo">1234|BLV UNIT1</Typography></Box>
                                                            <Box><Typography className="headingtwo">144 39th Belleve WA 98005</Typography></Box>
                                                        </Box>
                                                        <Box className="InputWrapperss">
                                                            <Box className="InputWrapperLefts">
                                                                Category
                                                            </Box>
                                                            <Box style={{ display: 'block' }} className="InputWrapperRights">
                                                                <select
                                                                    className='selectLongsnew'
                                                                    onChange={(e) => {
                                                                        setFieldValue("category", e.target.value); }}
                                                                    data-test-id="catagoryTest"
                                                                >
                                                                    <option value=""></option>

                                                                </select>
                                                                {errors.categorys && (
                                                                    <div className="errorNew">{errors.categorys}</div>
                                                                )}
                                                            </Box>
                                                        </Box>
                                                        <Box className="InputWrapperss">
                                                            <Box className="InputWrapperLefts">
                                                                Work needed
                                                            </Box>
                                                            <Box style={{ display: 'block' }} className="InputWrapperRights">
                                                                <input
                                                                    className='inputNew'
                                                                    onChange={(e) => {
                                                                        setFieldValue("workNeeded", e.target.value);
                                                                        
                                                                    }}
                                                                    data-test-id="workNeededTest"
                                                                />
                                                                {errors.workNeededs && (
                                                                    <div className="errorNew">{errors.workNeededs}</div>
                                                                )}
                                                            </Box>
                                                        </Box>
                                                        <Box className="InputWrapperss">
                                                            <Box className="InputWrapperLefts">
                                                                Due
                                                            </Box>
                                                            <Box className="InputWrapperRights">
                                                                <Box style={{ marginRight: "25px" }} className="dateSelects">
                                                                    <Typography
                                                                        sx={{ marginBottom: "8px" }}
                                                                    >
                                                                        Date
                                                                    </Typography>
                                                                    <CustomDatePicker
                                                                        value={this.state.dueDate}
                                                                        onChange={(newValue) => {
                                                                            setFieldValue("dueDate", newValue);
                                                                            this.handleDateChange(
                                                                                "dueDate", newValue
                                                                            )
                                                                        }}
                                                                        data-test-id="dueDateTest"
                                                                    />
                                                                    {errors.dueDates && (
                                                                        <div className="errorNew">{errors.dueHours}</div>
                                                                    )}
                                                                </Box>
                                                                <Box className="dateSelects marginTopnew">
                                                                    <Typography
                                                                        sx={{ marginBottom: "8px" }}
                                                                    >
                                                                        Hour
                                                                    </Typography>
                                                                    <CustomTimePicker
                                                                        value={this.state.dueHour}
                                                                        onChange={(newValue) => {
                                                                            setFieldValue("dueHour", newValue);
                                                                            this.handleDateChange(
                                                                                "dueHour", newValue
                                                                            )
                                                                        }}
                                                                        data-test-id="dueHourTest"
                                                                    />
                                                                    {errors.dueHours && (
                                                                        <div className="errorNew">{errors.dueHours}</div>
                                                                    )}
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                        <Box className="InputWrapperss" style={{ paddingTop: "0px" }}>
                                                            <Box className="InputWrapperLefts">
                                                            </Box>
                                                            <Box className="frequencyWrappernew">
                                                                <Box>
                                                                    <select className='frequency' data-test-id="dueFrequencyTest" onChange={(e) => {
                                                                        setFieldValue("dueFrequency", e.target.value);
                                                                    
                                                                    }}>
                                                                        <option>Frequency</option>
                                                                        <option value="daily">Daily</option>
                                                                        <option value="weekly">Weekly</option>
                                                                        <option value="biweekly">Biweekly</option>
                                                                        <option value="monthly">Monthly</option>
                                                                        <option value="yearly">yearly</option>
                                                                    </select>
                                                                    {errors.dueFrequencys && (
                                                                        <div className="errorNew">{errors.dueFrequencys}</div>
                                                                    )}
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                        <Box className="InputWrapperss">
                                                            <Box className="InputWrapperLefts">
                                                                Remind me on
                                                            </Box>
                                                            <Box className="InputWrapperRights">
                                                                <Box style={{ marginRight: "25px" }} className="dateSelects">
                                                                    <Typography
                                                                        sx={{ marginBottom: "8px" }}
                                                                    >
                                                                        Date
                                                                    </Typography>
                                                                    <CustomDatePicker
                                                                        value={this.state.remindMeOnDate}
                                                                        onChange={(newValue) => {
                                                                            setFieldValue("remindMeOnDate", newValue);
                                                                            this.handleDateChange(
                                                                                "remindMeOnDate", newValue
                                                                            )
                                                                        }}
                                                                        data-test-id="remindMeOnDateTest"
                                                                    />
                                                                    {errors.remindMeOnDates && (
                                                                        <div className="errorNew">{errors.remindMeOnDates}</div>
                                                                    )}
                                                                </Box>
                                                                <Box className="dateSelects marginTopnew">
                                                                    <Typography
                                                                        sx={{ marginBottom: "8px" }}
                                                                    >
                                                                        Hour
                                                                    </Typography>
                                                                    <CustomTimePicker
                                                                        value={this.state.remindMeOnHour}
                                                                        onChange={(newValue) => {
                                                                            setFieldValue("remindMeOnHour", newValue);
                                                                            this.handleDateChange(
                                                                                "remindMeOnHour", newValue
                                                                            )
                                                                        }}
                                                                        data-test-id="remindMeOnHourTest"
                                                                    />
                                                                    {errors.remindMeOnHours && (
                                                                        <div className="errorNew">{errors.remindMeOnHours}</div>
                                                                    )}
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                        <Box className="InputWrapperss" style={{ paddingTop: "0px" }}>
                                                            <Box className="InputWrapperLefts">
                                                            </Box>
                                                            <Box className="frequencyWrappernew">
                                                                <Box>
                                                                    <select className='frequencynew' data-test-id="remindMeOnFrequencyTest" onChange={(e) => {
                                                                        setFieldValue("remindMeFrequency", e.target.value);
                                                                
                                                                    }}>
                                                                        <option>Frequency</option>
                                                                        <option value="daily_remind">Daily</option>
                                                                        <option value="weekly_remind">Weekly</option>
                                                                        <option value="biweekly_remind">Biweekly</option>
                                                                        <option value="monthly_remind">Monthly</option>
                                                                        <option value="yearly_remind">yearly</option>
                                                                    </select>
                                                                    {errors.remindMeFrequencys && (
                                                                        <div className="errorNew">{errors.remindMeFrequencys}</div>
                                                                    )}
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                        <Box className="InputWrapperss">
                                                            <Box className="InputWrapperLefts">
                                                                Availability
                                                            </Box>
                                                            <Box className="radioOuterWrappernew">

                                                                <input className='inputNew' data-test-id="permissionInputTest" onChange={(e) => {
                                                                    setFieldValue("permissionDescription", e.target.value);
                                                                    this.setPermissionDescription(e.target.value);
                                                                }} />
                                                                {errors.permissionDescriptions && (
                                                                    <div className="errorNew">{errors.permissionDescriptions}</div>
                                                                )}
                                                            </Box>
                                                        </Box>
                                                        <Box className="InputWrapperss">
                                                            <Box className="InputWrapperLefts">
                                                                Description
                                                            </Box>
                                                            <Box style={{ display: 'block' }} className="InputWrapperRights">
                                                                <textarea
                                                                    className='textAreanew'
                                                                    rows={6}
                                                                    onChange={(e) => {
                                                                        setFieldValue("description", e.target.value);
                                                                        this.setDescription(e.target.value);
                                                                    }}
                                                                    data-test-id="descriptionTest"
                                                                />
                                                                {errors.descriptions && (
                                                                    <div className="errorNew">{errors.descriptions}</div>
                                                                )}
                                                            </Box>
                                                        </Box>
                                                        <Box className="InputWrapperss">
                                                            <Box className="InputWrapperLefts">
                                                            </Box>
                                                            <Box className="submitBtnWrappernew">
                                                                <Button className="submitBtnnew" style={{ textTransform: 'none' }} data-test-id="btnSubmit" type="submit">Submit Work Order</Button>
                                                            </Box>
                                                        </Box>
                                                    </form>
                                                );
                                            }}
                                        </Formik>
                                    </Box>
                                </Grid>
                                <Grid item md={3} sm={12} xs={12}>
                                    <Paper style={{ border: "1px solid #FFE299", height: "auto", borderRadius: '8px 8px 32px 8px', marginTop: "2rem" }}>
                                        <Box sx={{ padding: "1rem" }}>
                                            <Box>
                                                <img src={Key_logo} alt="wait_BC_FOR__GUY" />
                                            </Box>
                                            <Box>
                                                <Typography>
                                                    If you want to prompt the user to input text in a React component, you typically use an i
                                                    nput field or textarea where the user can type in their
                                                    response. Heres how you can implement an input field that allows the user to enter text:
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <Typography className="headingtwos">Imergencies,,Dial 9111</Typography>
                                            </Box>

                                        </Box>
                                    </Paper>
                                </Grid>
                            </Grid>

                        </Box>
                    </AddWorkOrderStyle>




                </ThemeProvider>




            </>
            // Customizable Area End
        );
    }
    addAppointment() {
        throw new Error("Method not implemented.");
    }
}

// Customizable Area Start

const AddWorkOrderStyle = styled(Box)({
   
    "& .headingOne": {
        display: "flex",
        justifyContent: "flex-start",
        gap: "1rem",
        padding: "2rem",

        alignItems: "center",
    },
    "& .headingtwo": {
        fontSize: "16px",
        fontWeight: "700",
        lineHeight: "24px",

    },
   
  
    "& .InputWrapperss": {
        display: "flex",
        padding: "2rem 0rem 2rem 2rem",
        "@media (max-width:600px)": {
            display: "block",
            padding: "1rem 0rem 1rem 0rem",
        }
    },
   
  
    "& .selectLongsnew": {
        width: "670px",
        height: "44px",
        border: "1px solid #BAB1AB",
        borderRadius: "8px",
        backgroundColor: "#ffffff",
        paddingLeft: "10px",
        "@media (max-width:1200px)": {
            width: "100%",
        }
    },
   
  
    "& .InputWrapperLefts": {
        width: "20%",
        fontWeight: 700,
        fontSize: "18px",
        lineHeight: "17px",
        color: "#BAB1AB",
        "@media (max-width:600px)": {
            width: "100%",
        }
    },
    "& .workOrderFormreq": {
        width: "90%",
        border: "1px solid #FFE299",
        borderRadius: "8px",
        margin: "2rem",
        padding: "2rem",
        "@media (max-width:586px)": {
            width: "100",
            padding: "0rem",
        },
        "@media (max-width:1200px)": {
            width: "95%",
            padding: "0rem"

        },
        "@media (max-width:1000px)": {
            margin: "0.5rem"
        },

        "@media (max-width:600px)": {
            width: "100%",
            border: "none"
        }
    },
    "& .InputWrapperRights": {
        display: "flex",
        width: "80%",
        marginRight: "25px",
        "@media (max-width:600px)": {
            width: "100%",
            display: "block",
            marginTop: "1rem",
        }
    },
    "& .headingreq": {
        fontWeight: 700,
        fontSize: '24px',
        lineHeight: '32px',
        marginLeft: "2rem",
        marginTop: "2rem",

        "@media (max-width:1000px)": {
            marginLeft: "0.5rem",
        },
    },
   
    "& .inputNew": {
        width: "670px",
        height: "44px",
        border: "1px solid #BAB1AB",
        borderRadius: "8px",
        paddingLeft: "10px",
        "@media (max-width:1200px)": {
            width: "100%",
        }
    },
   
    "& .radioOuterWrappernew": {
        width: "80%",
        marginRight: "25px",
        "@media (max-width:600px)": {
            width: "100%",
        }
    },
    display: "flex",
    fontFamily: "Outfit, sans-serif",
    width: "100%",
    "& .rightBoxs": {
        width: "100%",
        overflow: "hidden",
        paddingRight: "2rem",
        "@media (max-width:486px)": {
            paddingRight: "1.3rem",
        },
    },
    "& .textAreanew": {
        width: "670px",
        border: "1px solid #BAB1AB",
        borderRadius: "8px",
        padding: "10px",
        marginRight: "25px",
        "@media (max-width:1000px)": {
            width: "100%",
        }
    },
 
  
    "& .submitBtnWrappernew": {
        width: "670px",
        display: "flex",
        justifyContent: "flex-end",
        cursor: "pointer",
        "@media (max-width:600px)": {
            width: "100%",
            justifyContent: "flex-start",
        }
    },
    "& .frequencyWrappernew": {
        display: "flex",
        justifyContent: "flex-end",
        width: "670px",
        "@media (max-width:600px)": {
            width: "100%",
        }
    },
    "& .frequencynew": {
        width: "100px",
        height: "30px",
        border: "none",
        borderRadius: "8px",
        paddingLeft: "10px",
        backgroundColor: "#FFF1CC",
        "@media (max-width:1000px)": {
            marginRight: "25px",
        }
    },
    "& .dateSelects": {

        width: "322px",
        "@media (max-width:1200px)": {
            width: "48%",
        },
        "@media (max-width:600px)": {
            width: "100%",
        }
    },
    "& .marginTopnew": {
        "@media (max-width:600px)": {
            marginTop: "1rem",
        }
    },

    "& .errorNew": {
        color: "#ff0000",
        fontSize: "12px",
        marginTop: '3px',
    },
    "& .submitBtnnew": {
        width: "175px",
        height: "44px",
        backgroundColor: "#FFC123",
        borderRadius: "8px",
        color: "#000000",
        marginRight: "25px",
        cursor: "pointer",
        fontWeight: 700,
        fontSize: '16px',
        lineHeight: '24px',
        fontFamily: "Outfit, sans-serif",
        "&:hover": {
            backgroundColor: "#FFD700",
        },
        "@media (max-width:600px)": {
            marginRight: "0px",
        }
    },
    "& .headingtwos": {
        fontSize: "16px",
        fontWeight: "700",
        lineHeight: "24px",
        marginTop: "1rem"

    },
});
// Customizable Area End