export const userProfile = require("../assets/user-profile.png");
export const keasylogo = require("../assets/keasylogo.svg");
export const dashboardLogo = require("../assets/dashboard.png");
export const activeDashboard = require("../assets/activeDashboard.png");
export const accounts = require("../assets/profile.png");
export const activeAccount = require("../assets/activeProfile.png");
export const orders = require("../assets/order.png");
export const activeOrder = require("../assets/activeorder.png");
export const vendor = require("../assets/vendor.png");
export const activeVendor = require("../assets/activeSave.png");
export const reminders = require("../assets/reminder.png");
export const activeReminder = require("../assets/activeReminder.png");
export const property = require("../assets/property.png");
export const activeProperty = require("../assets/activeProperty.png");
export const financial = require("../assets/financial.png");
export const activeFinancial = require("../assets/activeFinancial.png");
export const report = require("../assets/report.png");
export const activeReport = require("../assets/activeReport.png");
export const keyChain = require("../assets/keyChain.png");
export const activeKey = require("../assets/activeKey.png");
export const settings = require("../assets/settings.png");
export const activeSetting = require("../assets/activeSetting.png");
