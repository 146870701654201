export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const keasyLogo = require("../assets/keasylogo.svg");
export const facebook = require("../assets/button_facebook.svg");
export const instagram = require("../assets/button_instagram.svg");
export const twitter = require("../assets/button_twitter.svg");
export const keasyFont = require("../assets/keasyFont.svg");
export const profile = require("../assets/profile.svg");
export const home = require("../assets/home.svg");
export const service = require("../assets/vendorUser.svg");
export const bgUser = require("../assets/bgUser.svg");
export const backgroundImg = require("../assets/backimage_keasyforms.svg");
