import React from "react";

import {

  Box,
  Grid,
  Button,

  Typography,
  styled,

  // Customizable Area Start
  Menu,
  InputBase,
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ListItemText,
  MenuItem,
  ListItemIcon,
  Divider

  // Customizable Area End

} from "@mui/material";

// Customizable Area Start
import NavigationSidebar from "../../../components/src/NavigationSidebar.web";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { KeyboardArrowUp, KeyboardArrowDown, ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import {
  Home_h, bell_b, dashboard_g, financial_f, Key_k,
  Key_logo,
  plusIcon,
  transferIcon,
   workIcon,
  declineIcon,
  filterIcon,
  editIcon,
  searchIcon
} from "./assets";




// Customizable Area End

import ServicerequestController, {
  Props,
  configJSON

} from "./ServicerequestController";

export default class Automatedworkflow extends ServicerequestController {

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderSortableTableCell = (property: string, label: string, unitIndex: number) => {
    return (
      <CustomTableCellsd key={property}>
        <Box display="flex" alignItems="center">
          {label}
          <Box display="flex" flexDirection="column" marginLeft={"10px"}>
            <CustomIconButtonUp
              data-test-id={`sortUpId,${unitIndex}`}
           
            >
              <ArrowDropUp
              />
            </CustomIconButtonUp>
            <CustomIconButtonDown
              data-test-id={`sortDownId,${unitIndex}`}
           
            >
              <ArrowDropDown
              />
            </CustomIconButtonDown>
          </Box>
        </Box>
      </CustomTableCellsd>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start


      <>
        <WorkOrderStylesd>
          <NavigationSidebar
            data-test-id={"navId"}
            activeItem={this.state.activeMainNewrequestdash}
            sidebarItems={[
              { label: configJSON.dashboardLandloard1, icon: dashboard_g, labelKey: configJSON.dashboardLandlabel, pathName: configJSON.dashboardLandlabel },

              { label: configJSON.serviderequest, icon: bell_b, labelKey: configJSON.reminlandlabel, pathName: configJSON.reminlandlabel },
              {
                label: configJSON.tenentNR, icon: Home_h, labelKey: configJSON.myproplandlabel1, activeIcon: Home_h, pathName: configJSON.myproplandlabel1,
              },
              { label: configJSON.financialandloardnewR, icon: financial_f, labelKey: configJSON.financlandlabel, pathName: configJSON.financlandlabel },
              { label: configJSON.chatLandloard1, icon: Key_k, labelKey: configJSON.chatlandlabel, pathName: configJSON.chatlandlabel },

            ]}
            onClickSidebar={this.handleMainItemClicknewrequest}
            keasylogo={Key_logo}
            openDrawer={this.state.openTenentDrawersdash}
            onClickDrawer={this.handleTenentrequestDrawers} />
          <Box display={"flex"} flexDirection={"column"} justifyContent={"space-between"} width={"100%"}
            sx={{
              padding: {
                flexGrow: 1,
                borderLeft: "1px solid rgb(208,203,199)",
              },
            }}>
            <MainWorkContainersd>
              <WorkStylessd>
                <WorkOrderHeadsd data-test-id="seeTextId">
                  {configJSON.workOrder}
                </WorkOrderHeadsd>
                <Grid container spacing={2}>
                  <Grid item md={6} xs={12}>
                    <Box className="search-contents">
                      <img
                        className="search-icons"
                        src={searchIcon} alt="location" />
                      <InputBase
                        className="search-inputs"
                        placeholder="Search"
                        inputProps={{ "aria-label": "search" }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6} justifyContent="flex-start">
                    <Box className="search-main-content">
                      <Button data-test-id="propertyClickId" className="properties-dropdown" >
                        <Typography data-test-id="propertyNameId" className="text-data">{configJSON.propertiesHeadsd}</Typography>
                        <KeyboardArrowDownIcon />
                      </Button>
                      <Box data-test-id="navigationId" className="new-property" >
                        <img
                          className="icon"
                          src={plusIcon} alt="plus" />
                        <Typography className="new-data">{configJSON.newWorkOrderssd}</Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Box className="filter-content">
                      <Box className="filter">
                        <img
                          className="icon"
                          src={filterIcon} alt="filter" />
                        <Typography className="filter-data" data-test-id="headClickId">{configJSON.filtersHead}</Typography>
                      </Box>
                      <Typography className="clear-data" data-test-id="clearOrderId">{configJSON.clearFilters}</Typography>
                    </Box>
                  </Grid>
                </Grid>
                <>
                  <React.Fragment>
                    <Grid container spacing={2} mt={2}>
                      <GridItemsd item xl={6} lg={6} md={6} sm={12}>
                        <HeadItemssd>
                          <HeadNamesd>property_name</HeadNamesd>
                          <MainHeadingsd>attributes</MainHeadingsd>
                        </HeadItemssd>
                      </GridItemsd>
                      <GridItemsd item xl={6} lg={6} md={6} sm={12}>
                        <CompletedDetailssd>
                          <Box className="view-completed">
                            <VisibilityOutlinedIcon />
                            <Typography className="text-data">{configJSON.viewCompleted}</Typography>
                          </Box>
                          <Box className="view-completed">
                            <CalendarTodayIcon />
                            <Typography className="text-data">{configJSON.viewScheduled}</Typography>
                          </Box>
                        </CompletedDetailssd>
                      </GridItemsd>
                    </Grid>

                    <BorderContainersd>
                       <TableContainer >
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell padding="checkbox" sx={{color:"white"}}>
                                <Checkbox sx={{color:"white"}} />
                              </TableCell>
                              {this.renderSortableTableCell("id", configJSON.idNamesd, 1)}
                              <CustomTableCellsd>{configJSON.createdNamesd}</CustomTableCellsd>
                              {this.renderSortableTableCell("category", configJSON.categoryNamesd, 1)}
                              <CustomTableCellsd>{configJSON.workNeedNamesd}</CustomTableCellsd>
                              {this.renderSortableTableCell("assigned_vendor", configJSON.assignVendorNamesd, 1)}
                              {this.renderSortableTableCell("cost", configJSON.estimatedCostNamesd, 1)}
                              {this.renderSortableTableCell("status", configJSON.statusNamesd, 1)}
                              <CustomTableCellsd>{configJSON.descriptionNamesd}</CustomTableCellsd>
                              {this.renderSortableTableCell("due_date_date", configJSON.openedNamesd, 1)}
                              <TableCell />
                            </TableRow>
                          </TableHead>
                          <TableBody>


                            <TableRow >
                              <TableCell padding="checkbox">
                                <CustomCheckbox
                                  style={{ color: 'white' }}
                                  data-test-id="checkedClickTestId"
                             
                                />
                              </TableCell>
                              <CustomDetailTableCellsd data-test-id="rowId">{configJSON.hashTagName}23333</CustomDetailTableCellsd>
                              <CustomDetailTableCellsd>56/78/00</CustomDetailTableCellsd>
                              <CustomDetailTableCellsd data-test-id="createTestId">genral</CustomDetailTableCellsd>
                              <CustomDetailTableCellsd data-test-id="createTestId">genral</CustomDetailTableCellsd>
                              <CustomTableCellsd>{configJSON.estimatedCost}xxxxxxxxx</CustomTableCellsd>
                              <TableCell>
                                <OrderStatus variant="outlined" data-test-id="statusId">
                                  status
                                </OrderStatus>
                              </TableCell>
                              <CustomDetailTableCellsd>
                                <DescriptionDetails data-test-id="descTestId">description</DescriptionDetails>
                              </CustomDetailTableCellsd>
                              <CustomDetailTableCellsd data-test-id="dueTestId">due_date_date</CustomDetailTableCellsd>
                              <TableCell>
                                <MoreArrow>
                                  <IconButton data-test-id="expandClickTestId" onClick={() => this.handleExpandClick(7)}>
                                    {this.state.expanded === 7 ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                  </IconButton>
                                   </MoreArrow>
                              </TableCell>
                            </TableRow>
                            {
                              this.state.expanded ?
                                <TableRow>
                                  <TableCell colSpan={11}>
                                    <InnerTableBg>
                                      <OngoingName>
                                        {configJSON.goingOnName}
                                      </OngoingName>
                                      <Grid container spacing={2}>
                                        <Grid item xl={8} lg={8} sm={12} xs={12}>
                                          <TableContainer>
                                            <Table>
                                              <TableHead>
                                                <TableRow>
                                                  {this.renderSortableTableCell("date", configJSON.dateName, 3)}
                                                  {this.renderSortableTableCell("updated_by", configJSON.updatedName, 3)}
                                                  <DetailsTableCellsd>{configJSON.detailsName}</DetailsTableCellsd>
                                                  {this.renderSortableTableCell("lowCost", configJSON.costName, 3)}
                                                  {this.renderSortableTableCell("status", configJSON.statusChangeName, 3)}
                                                  <TableCell />
                                                </TableRow>
                                              </TableHead>
                                              <CustomTableContainer>

                                                <React.Fragment key={2}>
                                                  <TableRow >
                                                    <StatusTableCell>date</StatusTableCell>
                                                    <StatusTableCell>updated_by</StatusTableCell>
                                                    <StatusTableCell>description</StatusTableCell>
                                                    <StatusTableCell>lowCos</StatusTableCell>
                                                    <StatusTableCell>status</StatusTableCell>
                                                  </TableRow>
                                                </React.Fragment>

                                              </CustomTableContainer>
                                              <TableRow>
                                                <TableCell colSpan={11} align="center">
                                                  {configJSON.auditMissing}
                                                </TableCell>
                                              </TableRow>
                                            </Table>
                                          </TableContainer>
                                        </Grid>
                                        <Grid item xl={4} lg={4} sm={12} xs={12}>
                                          <Images>{configJSON.imagesUpload}</Images>
                                          <Grid container alignItems={"center"} justifyContent={"center"} spacing={1}>

                                            <Grid item xl={6} lg={6} sm={6} xs={6} key={2}>
                                              <BulkUploadingFiles>
                                                <ImagesVideosFiles>
                                                  <ImageFiles src="" alt={`Image ${2}`} />
                                                </ImagesVideosFiles>
                                              </BulkUploadingFiles>
                                            </Grid>

                                            <Typography textAlign={"center"} mt={8}>
                                              {configJSON.noImages}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </InnerTableBg>
                                  </TableCell>
                                </TableRow> : null
                            }
                             </TableBody>
                             </Table>
                      </TableContainer>
                    </BorderContainersd>

                  </React.Fragment>

                </>
              </WorkStylessd>
            </MainWorkContainersd>
          
          </Box>
          
       
        
        </WorkOrderStylesd>




      </>
      // Customizable Area End
    );
  }
  addAppointment() {
    throw new Error("Method not implemented.");
  }
}

// Customizable Area Start
const WorkOrderStylesd = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  "@media(max-width: 992px)": {
    flexDirection: "column"
  },
  "&.webStyle": {
    backgroundColor: "red"
  },
  "& .bgImgWrapper": {
    display: "flex",
    justifyContent: "flex-end",
    width: "97%",
    marginTop: "2rem",
    "@media (max-width:1220px)": {
      width: "88%"
    },
    "@media (max-width:992px)": {
      display: "none",
    }
  },
  "& .backgroundImage": {
    width: "250px",
    "@media (max-width:1220px)": {
      width: "180px"
    },
  },
  "& .hexImg": {
    objectFit: "cover"
  }
});

const MainWorkContainersd = styled(Box)({
  width: "100%",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  "@media(max-width: 992px)": {
    padding: "0px 0px 0px 0px"

  }
});




const WorkOrderHeadsd = styled(Typography)({
  fontSize: "24px",
  fontWeight: 700,
  color: "#000000",
  marginBottom: "20px"
});

const WorkStylessd = styled(Box)({
  width: "90%",
  marginTop: "42px",
  "& .search-main-content": {
    display: "flex",
    gap: "1rem",
    "@media(max-width:600px)": {
      width: "100%",
      position: "relative",
      height: "100%",
      flexDirection: "row",
      display: "flex",
      "@media(max-width: 420px)": {
        gap: "5px"
      }
    },
  },
  "& .search-contents": {
    border: "1px solid #CBD5E1",
    borderRadius: "50px",
    height: "44px",
    display: "flex",
    paddingLeft: "15px",
    alignItems: "center",
    gap: "13px",
  },
  "& .search-icons": {
    height: "24px",
    width: "24px"
  },
  "& .search-text": {
    fontWeight: 400,
    fontSize: "16px",
    color: "#A3978F",
    "@media(max-width: 420px)": {
      fontSize: "12px"
    }
  },
  "& .search-inputs": {
    width: "100%"
  },
  "& .properties-dropdown": {
    width: "142px",
    height: "44px",
    backgroundColor: "rgb(253,242,208)",
    display: "flex",
    alignItems: "center",
    textTransform: "none",
    borderRadius: "8px",
    padding: "2px 10px 2px 10px",
    justifyContent: "space-around",
    cursor: "pointer",
    color: "#000000",
    "&:hover": {
      backgroundColor: "rgb(253,242,208)",
      color: "#000000"
    },
    "@media(max-width: 420px)": {
      fontSize: "12px",
      width: "105px",
      height: "30px"
    }
  },
  "& .view-completed": {
    width: "180px",
    backgroundColor: "rgb(253,242,208)",
    height: "44px",
    display: "flex",
    alignItems: "center",
    padding: "2px 10px 2px 10px",
    borderRadius: "8px",
    justifyContent: "space-around",
    cursor: "pointer",
    "@media(max-width: 460px)": {
      fontSize: "12px",
      height: "35px",
      width: "100%"
    },
    "@media(max-width: 347px)": {
      padding: "2px 5px 2px 5px"
    }
  },
  "& .icon": {
    mixBlendMode: "multiply"
  },
  "& .image-icon": {
    width: "16px",
    height: "16px"
  },
  "& .text-data": {
    fontSize: "16px",
    fontWeight: 700,
    "@media(max-width: 420px)": {
      fontSize: "12px"
    }
  },
  "& .new-data": {
    fontSize: "18px",
    color: "#CC9200",
    fontWeight: 700,
    "@media(max-width: 420px)": {
      fontSize: "12px"
    }
  },
  "& .new-property": {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    borderRadius: "8px",
    justifyContent: "space-around",
    paddingLeft: "5px",
    paddingRight: "5px",
    cursor: "pointer"
  },
  "& .filter-content": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: "20px"
  },
  "& .filter": {
    display: "flex",
    alignItems: "center",
    gap: "15px",
  },
  "& .filter-data": {
    fontSize: "12px",
    fontWeight: 700,
    color: "#000000"
  },
  "& .clear-data": {
    fontSize: "12px",
    fontWeight: 700,
    color: "#CC9200",
    cursor: "pointer"
  }
});

const HeadNamesd = styled(Typography)({
  fontSize: "16px",
  fontWeight: 700,
  color: "#000000",
  "@media(max-width: 405px)": {
    fontSize: "12px"
  }
});

const MainHeadingsd = styled(Typography)({
  fontSize: "16px",
  color: "#000000",
  fontWeight: 400,
  "@media(max-width: 405px)": {
    fontSize: "12px"
  }
});

const HeadItemssd = styled(Box)({
  display: "flex",
  gap: "10px"
});

const CompletedDetailssd = styled(Box)({
  display: "flex",
  gap: "30px",
  justifyContent: "flex-end",
  "@media(max-width: 460px)": {
    gap: "5px",
    width: "100%"
  }
});

const GridItemsd = styled(Grid)({
  width: "100%"
});

const BorderContainersd = styled(Box)({
  border: "1px solid #FFD466",
  borderRadius: "8px 8px 32px 8px",
  padding: "1rem",
  marginTop: "1rem",
  display: "flex",
  flexDirection: "column",
  "& .scrollable-container": {
    height: "300px",
    overflowY: "auto"
  },
  "& .isUnitsDisabled": {
    display: "none"
  }
});



const CustomTableCellsd = styled(TableCell)({
  color: "#A3978F",
  textAlign: "center",
  fontSize: "12px",
  fontWeight: 700,
  padding: "0px 10px 0px 10px !important"
});

const DetailsTableCellsd = styled(TableCell)({
  color: "#A3978F",
  fontSize: "12px",
  textAlign: "center",
  fontWeight: 700
});

const CustomDetailTableCellsd = styled(TableCell)({
  fontSize: "12px",
  color: "#A3978F",
  fontWeight: 400
});

const MoreArrow = styled(Box)({
  display: "flex"
});

const OrderStatus = styled(Button)({
  borderRadius: "50px",
  backgroundColor: "#D1FAE5",
  color: "#059669",
  fontSize: "9px",
  width: "100%",
  borderColor: "#D1FAE5",
  fontWeight: 700,
  "&:hover": {
    backgroundColor: "#D1FAE5",
    color: "#059669",
    borderColor: "#D1FAE5"
  }
});

const DescriptionDetails = styled(Typography)({
  fontSize: "12px",
  fontWeight: 400,
  color: "#A3978F",
  wordBreak: "break-word"
});

const InnerTableBg = styled(Box)({
  border: "1px solid #F9FAFC",
  borderRadius: "10px",
  backgroundColor: "#F9FAFC",
  padding: "20px 0px 20px 20px"
});

const StatusTableCell = styled(TableCell)({
  fontSize: "12px",
  fontWeight: 400,
  color: "#000000",
  textAlign: "center"
});

const OngoingName = styled(Typography)({
  fontSize: "16px",
  fontWeight: 700,
  color: "#1C1917"
});

const CustomTableContainer = styled(TableBody)({
  backgroundColor: "#FFFFFF"
});

const CustomCheckbox = styled(Checkbox)({
  color: "rgba(0, 0, 0, 0.54)",
  "&.Mui-checked": {
    color: "rgb(195,149,49)"
  },
  "&.MuiCheckbox-indeterminate": {
    color: "rgb(195,149,49)"
  }
});

const Images = styled(Typography)({
  textAlign: "left",
  fontSize: "16px",
  fontWeight: 700,
  color: "#1C1917"
});



const CustomIconButtonUp = styled(Typography)({
  marginBottom: "-14px",
  cursor: "pointer"
});

const CustomIconButtonDown = styled(Typography)({
  marginTop: "0px",
  cursor: "pointer"
});

const BulkUploadingFiles = styled(Grid)({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  paddingTop: "20px",
  alignItems: "center",
  gap: "10px"
});

const ImagesVideosFiles = styled(Box)({
  height: "100px",
  width: "100px"
});

const ImageFiles = styled('img')({
  height: "100%",
  width: "100%",
  objectFit: "contain"
});
// Customizable Area End