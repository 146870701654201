import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config.js");
// Customizable Area Start
const navigation = require("react-navigation");
import { SelectChangeEvent } from '@mui/material/Select';
import { Message } from "../../../framework/src/Message";
import storage from "../../../framework/src/StorageProvider";

interface PaginationState {
    currentPage: number;
    totalPages: number;
  }

  export interface FileWithPreview extends File {
  preview: string;
}

export interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  token?: string;
  body?: object;
  type?: string;
}

export interface Countries {
  data: Array<AreasCode>;
}

export interface AreasCode {
  id: string;
  type: string;
  attributes: {
    name: string;
    emoji_flag: string;
    country_code: string;
  }
}

  
  
// Customizable Area End

export interface Props {
    navigation: typeof navigation;
    id: string;
    // Customizable Area Start
    itemsPerPage: number;
    totalItems: number;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    token: string;
    filesWeb: File[];
    files: any[];
    uploadedFiles: ""
    filesStatus: (undefined | "uploading" | "success" | "failed")[];
    activeStepedit:number;
    
    
    activeMainItemsedit: string;
    openLandlordDrawersedit: boolean
    activeStep: number;
    vendorType: string;
    ownerName: string;
    vendorTrade: string[];
    taxpayerName: string;
    taxpayerID: string;
    taxFormAccountNumber: string;
    send1099: string;
    workersCompExpiration: Date | null;
    generalLiabilityExpiration:  Date | null;
    epaCertificationExpiration:  Date | null;
    autoInsuranceExpiration: Date | null;
    stateLicenseExpiration: Date | null;
    contractExpiration: Date | null;
    phoneNumber: string;
    email: string;
    address: string;
    portalActivated: string;
    areaCode: string;
    taxpayerId: string;
    useOnlinePayables: string;
    paymentType: string;
    vendorBankRoutingNumber: string;
    vendorBankAccountNumber: string;
    accountType: string;
    expirationDate1: Date | null;
    expirationDate2: Date | null;
    notes: string;
    filesDocuments: FileWithPreview[];
    filesName: string;
    filesAccountType: FileWithPreview[];
    filesInsurance: FileWithPreview[];
    licenceInsurance: FileWithPreview[];
    openSnack: boolean;
    errorMessage: string;
    vendorsItem: string[];
    payments: string[];
    errors: {
      vendorTrade: boolean;
      ownerName: boolean;
      monFriTime: boolean;
      satTime: boolean;
      sunTime: boolean;
      phone: boolean;
      email: boolean;
      formW9Files: boolean;
      bankRoutingNumber: boolean;
      bankAccountNumber: boolean;
      businessLicense: boolean;
      licenseExpDate: boolean;
      businessInsurance: boolean;
      insuranceExpDate: boolean;
      taxpayerName: boolean;
      taxpayerId: boolean;
      websiteLink: boolean;
    };
    trades: string;
    isCountryDropdown: boolean;
    countriesCode: Array<{ name: string; emoji_flag: string; country_code: string }>;

    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}
export default class TenantDashboardController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getAreaCodeApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
            // Customizable Area End
        ];
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            token: "",
            filesWeb: [],
            files: [],
            uploadedFiles:"",
            filesStatus: [],
            activeStepedit: 0,
            activeMainItemsedit: configJSON.prevendorlandlabel,
            openLandlordDrawersedit: false,
            activeStep: 0,
            vendorType: "",
            ownerName: "",
            vendorTrade: [],
            taxpayerName: "",
            taxpayerID: "",
            taxFormAccountNumber: "",
            send1099: 'Yes',
            workersCompExpiration: null,
            generalLiabilityExpiration: null,
            epaCertificationExpiration: null,
            autoInsuranceExpiration: null,
            stateLicenseExpiration: null,
            contractExpiration: null,
            phoneNumber: "",
            email: "",
            address: "",
            portalActivated: "",
            areaCode: "",
            taxpayerId: "",
            useOnlinePayables: "",
            paymentType: "",
            vendorBankRoutingNumber: "",
            vendorBankAccountNumber: "",
            accountType: "",
            expirationDate1: null,
            expirationDate2: null,
            notes: "",
            filesDocuments: [],
            filesName: "",
            filesAccountType: [],
            filesInsurance: [],
            licenceInsurance: [],
            openSnack: false,
            errorMessage: "",
          vendorsItem: [
            "Plumbing",
            "Electrical",
            "HVAC",
            "Landscaping",
            "Appliance Repair",
            "Pest Control",
            "General",
            "Property Management"
          ],
          payments: [
            "eCheck",
            "Pay via check in mail",
            "Pay online via vendor's website",
            "Pay with Venmo",
            "Pay with Zelle"
          ],
          errors: {
            vendorTrade: false,
            ownerName: false,
            monFriTime: false,
            satTime: false,
            sunTime: false,
            phone: false,
            email: false,
            formW9Files: false,
            bankRoutingNumber: false,
            bankAccountNumber: false,
            businessLicense: false,
            licenseExpDate: false,
            businessInsurance: false,
            insuranceExpDate: false,
            taxpayerName: false,
            taxpayerId: false,
            websiteLink: false,
          },
          trades: "",
          isCountryDropdown: false,
          countriesCode: []
    
            // Customizable Area End
        };
    }
    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        this.getAreaCode();
        // Customizable Area End
    }

    // Customizable Area Start

 

handleNextMainEdit = () => {
   
  this.setState(prevState => ({
    
    activeStepedit: prevState.activeStepedit + 1
  }));
 
};

handleBackMainEdit = () => {
  this.setState(prevState => ({
    activeStepedit: prevState.activeStepedit - 1
  }));
};




handleMainItemClicksEdit = (labelKey: string) => {
  this.setState({ activeMainItemsedit: labelKey },()=>{
    this.handleEditDashboardMenu();
  });
};

handleLandlordDrawersEdit = () => {
  this.setState({
    openLandlordDrawersedit: !this.state.openLandlordDrawersedit
});
};

  handleEditDashboardMenu = () => {
    const { activeMainItemsedit } = this.state;
    const toMsg = new Message(getName(MessageEnum.NavigationMessage));
    toMsg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      activeMainItemsedit
    );
    toMsg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(toMsg);
  };

  handleNext = () => {
    this.setState(prevState => ({ activeStep: prevState.activeStep + 1 }));
  };

  handleBack = () => {
    this.setState(prevState => ({ activeStep: prevState.activeStep - 1 }));
  };

  handleVendorDashboard = () => {
    const toVendorMsg = new Message(getName(MessageEnum.NavigationMessage));
    toVendorMsg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "TaskList"
    );
    toVendorMsg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(toVendorMsg);
  };

  handleSubmit = () => {
    const { email } = this.state;
    if (email) {
      this.handleVendorDashboard();
    } else {
      this.setState({
        openSnack: true,
        errorMessage: "Please enter email address"
      });
    }
  };

  handlePopupClose = () => {
    this.setState({
      openSnack: false
    });
  };

  handlePaymentType = (payEvent: SelectChangeEvent<string>) => {
    this.setState({ paymentType: payEvent.target.value });
  };

  handleVendorType = (vendorEvent: SelectChangeEvent<string>) => {
    this.setState({ vendorType: vendorEvent.target.value });
  };

  handleRadioChange = (sendEvent: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ send1099: (sendEvent.target as HTMLInputElement).value });
  };

  handleFileDocChange = (names: string, files: FileWithPreview[]) => {
    this.setState({
      filesDocuments: files,
      filesName: names
    });
  };

  handleAccFileChange = (names: string, files: FileWithPreview[]) => {
    this.setState({
      filesAccountType: files,
      filesName: names
    });
  };

  handleUploadInsurance = (names: string, files: FileWithPreview[]) => {
    this.setState({
      filesInsurance: files,
      filesName: names
    });
  };
  
  handleDateChange = (key: keyof S) => (newValue: Date | null) => {
    this.setState({ [key]: newValue } as unknown as Pick<S, keyof S>);
  };

  handleInsuranceLicence = (names: string, files: FileWithPreview[]) => {
    this.setState({
      licenceInsurance: files,
      filesName: names
    });
  };


  validatesName = (name: string) => {
    const regex = configJSON.vendorRegax
    if (!regex.test(name)) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          ownerName: true,
        },
      }));
    } else {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          ownerName: false,
        },
      }));
    }
  };

  validatesPhone = (phone: string) => {
    const phoneRegex = configJSON.vendornumber
    if (!phoneRegex.test(phone)) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          phone: true,
        },
      }));
    } else {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          phone: false,
        },
      }));
    }
  };

  validatesBankRoutingNumber = (number: string) => {
    const routingRegex = configJSON.vendorPhone
    if (!routingRegex.test(number)) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          bankRoutingNumber: true,
        },
      }));
    } else {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          bankRoutingNumber: false,
        },
      }));
    }
  };

  validateTrade = () => {
    if (this.state.vendorTrade.length === 0) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          vendorTrade: true,
        },
      }));
    } else {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          vendorTrade: false,
        },
      }));
    }
  };

  validatesEmail = (email: string) => {
    const emailRegex = configJSON.emailRegex
    if (!emailRegex.test(email)) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          email: true,
        },
      }));
    } else {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          email: false,
        },
      }));
    }
  };

  validatesBankAccountNumber = (number: string) => {
    const accountRegex = configJSON.accountRegax
    if (!accountRegex.test(number)) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          bankAccountNumber: true,
        },
      }));
    } else {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          bankAccountNumber: false,
        },
      }));
    }
  };

  validatesTaxpayerName = (name: string) => {
    const regex = configJSON.taxRegax
    if (!regex.test(name)) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          taxpayerName: true,
        },
      }));
    } else {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          taxpayerName: false,
        },
      }));
    }
  };

  validatesTaxpayerID = (id: string) => {
    const regex = configJSON.taxNumberIdRegax
    if (!regex.test(id)) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          taxpayerId: true,
        },
      }));
    } else {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          taxpayerId: false,
        },
      }));
    }
  };

  getHelperText = (field: string) => {
    const { errors } = this.state;

    switch (field) {
      case "ownerName":
        return errors.ownerName ? "Please enter a valid owner name using letters and spaces only." : null;

      case "phoneNumber":
        return errors.phone ? "Please enter a valid phone number." : null;

      case "vendorBankRoutingNumber":
        return errors.bankRoutingNumber ? "The input must be between 9 and 34 characters long and contain only letters and numbers." : null;

      case "vendorBankAccountNumber":
        return errors.bankAccountNumber ? "The input must be between 9 and 34 characters long and contain only letters and numbers." : null;

      case "taxpayerName":
        return errors.taxpayerName ? "Please enter a valid taxpayer name using letters and spaces only." : null;

      case "taxpayerID":
        return errors.taxpayerId ? "Please enter a valid taxpayer Id using alphanumerics only." : null;

      case "email":
        return errors.email ? "Please enter a valid email address." : "example@example.com";

      default:
        return null;
    }
  };

  handleChange = (
    events: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = events.target;
    this.setState({
      [name]: value,
    } as unknown as Pick<S, keyof S>);
    if (name === "ownerName") {
      this.validatesName(value);
    }else if (name === "phoneNumber") {
      this.validatesPhone(value);
    } else if (name === "vendorBankRoutingNumber") {
      this.validatesBankRoutingNumber(value);
    } else if (name === "email") {
      this.validatesEmail(value);
    } else if (name === "vendorBankAccountNumber") {
      this.validatesBankAccountNumber(value);
    } else if(name === "taxpayerName") {
      this.validatesTaxpayerName(value);
    }  else if(name === "taxpayerID") {
      this.validatesTaxpayerID(value);
    }
  };

  handleTradeInput = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = event.target;
    this.setState({
      trades: value,
    } as unknown as Pick<S, keyof S>);
  };

  handleAddTrade = () => {
    const newTrades = this.state.trades
      .split(",")
      .map((trade) => trade.trim())
      .filter((trade) => trade !== "");

    const combinedTrades = [...this.state.vendorTrade, ...newTrades];

    const uniqueTrades = combinedTrades.filter(
      (item, index) => combinedTrades.indexOf(item) === index
    );

    this.setState({ vendorTrade: uniqueTrades, trades: "" }, () => {
      this.validateTrade();
    });
  };

  handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      this.handleAddTrade();
    }
  };

  handleDelete = (trade: string) => {
    const updatedTrade = this.state.vendorTrade.filter(
      (item) => item !== trade
    );
    this.setState({ vendorTrade: updatedTrade });
  };

  handleCountryCodeDropdown = () => {
    this.setState({ isCountryDropdown: true });
  };

  handleCountryChange = (event: SelectChangeEvent<string>) => {
    this.setState({ areaCode: event.target.value, isCountryDropdown: false });
  };

  getAreaCode = async () => {
    this.getAreaCodeApiCallId = await this.newVendorApiCall({
      contentType: configJSON.dashboarContentType,
      method: configJSON.dashboarApiMethodType,
      endPoint: configJSON.getCountriesCodeAPIEndPoint
    });
  };

  apiSuccessCall = (apiRequestCallID: string, responseJson: Countries) => {
    if (apiRequestCallID === this.getAreaCodeApiCallId) {
      let countriesCode = responseJson.data.map((item: AreasCode) => {
        return {
          name: item.attributes.name,
          emoji_flag: item.attributes.emoji_flag,
          country_code: item.attributes.country_code,
        };
      });

      this.setState({ countriesCode }, () => {});
    }
  };

  newVendorApiCall = async (data: APIPayloadType) => {
    let { contentType, method, endPoint} = data;
    const token = await storage.get("authToken");
    const header = {
      "Content-Type": contentType,
      token: token
    };

    let requestNewMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestNewMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestNewMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestNewMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    requestNewMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    
    runEngine.sendMessage(requestNewMessage.id, requestNewMessage);
    return requestNewMessage.messageId;
  };

  receive = async (from: string, message: Message) => {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestsCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (!responseJson.errors && responseJson) {
        this.apiSuccessCall(apiRequestsCallId, responseJson);
      }
    }
  };

    // Customizable Area End
}
