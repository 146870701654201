// Customizable Area Start
import React from "react";
import Accordion from '@mui/material/Accordion';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {
  Box,
  Typography,
  InputBase,
  styled,
  Button,
  Divider,
  IconButton,
  Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, DialogTitle, Pagination,TextField
} from "@mui/material";


import {
  createTheme,
  ThemeProvider,


} from "@mui/material/styles";
import {
  searchSearch, downDown, plusPlus, filterFilter, keylogoLogo,
  homemyruch,
  lockLock,
  dialogDialogimage, closeImage, pencil, imageIcon, imageOne, imageTwo, imageThree, plusIcon
} from "./assets";
import { activeMainDashboard, activeMainSetting, activeMainAccount, activeMainOrder, activeMainVendor, activeMainReminder, activePropertyMain, activeMainFinancial, activeMainReport, keyMainChain, activeKeyMain, settingsMain, accountsMain, dashboardLandLogo, financialMain, ordersMain, propertyMain, remindersMain, reportMain, vendorMain } from "../../dashboard/src/assets"
import DrawIcon from '@mui/icons-material/Draw';
import PortfolioManagementController, {
  Props,
  configJSON,
  PropertyLabel,
  PropertyData,
  Unit,
  Data
} from "./PortfolioManagementController";
import NavigationSidebar from "../../../components/src/NavigationSidebar.web";
import { ArrowDropUp, ArrowDropDown } from '@mui/icons-material';
interface CustomChipProps {
  label: string;
  id: string
}

const PortfoliyoStyle = styled(Box)({

  "& .UpperAddunitStyle": {
    fontSize: "16px",
  fontWeight: 700,
  lineHeight: "18px",
  fontfamily: "outfit",
  color: "#000000",
  "@media(max-width:600px)": {
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "18px",
    fontfamily: "outfit",
    color: "#000000",
  },
  },
  "& .UpperAddunitStyletwo": {
    fontSize: "16px",
  fontWeight: 400,
  lineHeight: "18px",
  fontfamily: "outfit",
  color: "#000000",
  "@media(max-width:600px)": {
    fontSize: "11px",
    fontWeight: 400,
    lineHeight: "18px",
    fontfamily: "outfit",
    color: "#000000",

  },
  },
  "& .heading-text": {
    fontSize: "24px",
    fontWeight: 700,
    color: "#000000",
    marginTop: "42px",
  },
  "& .main-content": {
    display: "flex"
  },
  "& .search-main-content": {

    display: "flex",
    gap: '1rem',
    "@media(max-width:600px)": {
      width: "100%",
      height: "100%",
      display: "flex",
      position: "relative",
      flexDirection: "row",

    },
  },
  "& .search-content": {

    height: "44px",
    border: "1px solid #CBD5E1",
    borderRadius: "50px",
    display: "flex",
    alignItems: "center",
    paddingLeft: "15px",
    gap: "13px",
  },
  "& .search-icon": {
    width: "24px",
    height: "24px",
  },
  "& .search-text": {
    fontSize: "16px",
    fontWeight: 400,
    color: "#A3978F"
  },
  "& .search-input": {
    width: "800px"
  },
  "& .properties-dropdown": {
    backgroundColor: "#FFF1CC",
    width: "142px",
    height: "44px",
    display: "flex",
    alignItems: "center",
    marginLeft: "15px",
    borderRadius: "8px",
    justifyContent: "space-around",
  },
  "& .icon": {
    mixBlendMode: "multiply"
  },
  "& .text-data": {
    fontSize: "16px",
    fontWeight: 700,
    fontfamily: "outfit",
  },
  "& .new-property": {
    backgroundColor: "#FFC123",
    width: "166px",
    height: "44px",
    display: "flex",
    alignItems: "center",

    borderRadius: "8px",
    justifyContent: "space-evenly",
    paddingLeft: "5px",
    paddingRight: "5px",
  },
  "& .filter-content": {

    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "15px"
  },
  "& .filter-contenttwo": {

    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap:'1rem'
    
  },
  "& .filter": {
    display: "flex",
    alignItems: "center",
    gap: "15px",
  },
  "& .filter-text": {
    fontSize: "12px",
    fontWeight: 700,
    color: "#000000"
  },
  "& .clear-text": {
    fontSize: "12px",
    fontWeight: 700,
    color: "#CC9200",
    cursor: "pointer"
  },
  "& .unit-data": {
    border: "1px solid #FFD466",
    borderRadius: "8px",
    marginTop: "60px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

  },
  "& .unit-text": {
    display: "flex",
    alignItems: "center",
    justifyContent: " flex-start",
    gap: "14px",
    padding: "25px"
  },
  "& .chip-content": {
    width: "88px",
    height: "22px",
    backgroundColor: "#D1FAE5",
    color: "#059669",
    fontSize: "12px",
    fontWeight: 700
  },
  "& .info-text": {
    fontSize: "14px",
    fontWeight: 700,
    color: "#CC9200"
  },
  "& .unit-content": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "30px",
    marginRight: "75px"
  },
  "& .unit-sub-content": {
    display: "flex",
    gap: "4px",
    alignItems: "center",
    justifyContent: "center"
  },
  "& .home-icon": {
    width: "19px",
    height: "19px",
  },
  "& .addunit-content": {
    display: "flex",
    justifyContent: "end",
    "@media(max-width:600px)": {
      display: "flex",
      justifyContent: "left",
      marginTop: "1rem"
    },



  },
  "& .button-content": {
    width: "120px",
    height: "32px",
    backgroundColor: "#FFF1CC",
    gap: "6px",
    color: "#000000",
    fontSize: "16px",
    fontWeight: 700,
    display: "flex",
    alignItems: "center !important",
    justifyContent: "center !important",
    borderRadius:"8px"
  },
  "& .add-icon": {
    width: "20px",
    mixBlendMode: "multiply"
  },
  "& .slick-next::before": {
    color: "#FFC123",
    lineHeight: "0",
    fontSize: "58px",
    "@media(max-width:600px)": {
      fontSize: "43px",
    }
  },
  "& .slick-prev::before": {
    color: "#FFC123",
    lineHeight: "0",
    fontSize: "58px",
    "@media(max-width:600px)": {
      fontSize: "43px",
    }
  },
  "& .slick-prev": {
    left: "-74px",
    "@media(max-width:600px)": {
      left: "-47px",
    }
  },
  "& .slick-next": {
    right: "30px",
    "@media(max-width:600px)": {
      right: "8px",
    }
  },
  "& .slick-list": {
    width: "487px",
    "@media(max-width:600px)": {
      width: "150px",
    }
  },
  "& .sliderContainer": {
    height: "147px",
    "@media(max-width:600px)": {
      marginTop: "16px",
      marginLeft: "28px",
    }
  },
  "& .add-unit": {
    backgroundColor: "#FFC123",
    width: "74px",
    height: "35px",
    display: "flex",
    alignItems: "center",

    borderRadius: "8px",
    justifyContent: "space-around",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginTop: "17px",
    marginLeft: "78%",
  },
  "& .unit-text-data": {
    fontSize:"16px",
    fontWeight: 700,
    color:"#000000"
  }
});


const theme = createTheme({
  palette: {
    primary: {
      main: "#6200ee",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
const Boxnone = styled(Box)({
  display: "block", marginRight:"28px", marginLeft:"28px",

  "@media(max-width:600px)": {
    display: "none"


  },


})
const Boxparentdiv = styled(Box)({
  display: "flex", justifyContent: "space-between", alignItems: 'center', paddingLeft: "4rem", paddingRight: "4rem", marginTop: "2rem",

  "@media(max-width:600px)": {
    display: "none"


  },


})
const Boxgivemobile = styled(Box)({

  border: '1px solid #FFD466', borderRadius: "8px", padding: "1rem", marginTop: "3rem",

  "@media(max-width:600px)": {
    borderRadius: "8px 8px 20px",
    marginTop: "2rem"

  },
  "& .buttoms-content": {
    display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "2rem",
    "@media(max-width:600px)": {
      marginTop: "1rem"
    },
  }
});
const Boxaddtenents = styled(Box)({

  display: "flex", gap: '0.5rem', justifyContent: "center", alignItems: 'center',

  "@media(max-width:600px)": {
    display: "none"


  },
});
const Boxaddtenent = styled(Box)({
  display: "none",
  marginTop: "0.8rem",


  "@media(max-width:600px)": {
    display: "flex", gap: '0.5rem', justifyContent: "left", alignItems: 'left',


  },
});
const Divcontainer = styled(Box)({
  display: "flex", justifyContent: "space-between",


  "@media(max-width:600px)": {
    flexDirection: "column",

    textAlign: "left"


  },
});
const Gapless = styled(Box)({
  display: "flex", justifyContent: "space-between", alignItems: "center", gap: "2rem",


});
const Boxfordesknones = styled(Box)({
  display: "none",


  "@media(max-width:600px)": {
    display: 'block'


  },
});

const Boxfordesknone = styled(Box)({
  display: "flex", justifyContent: "space-between", width: "100%", marginTop: "1rem",
  "& .text-data": {
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "10px",
    fontfamily: "outfit",
    color: '#A3978F'
  }
});

const getStyles = () => {
  const windowWidth = window.innerWidth;

  if (windowWidth >= 1200) {

    return {
      padding: "4rem",
    };
  } else if (windowWidth <= 600) {

    return {
      padding: "1rem",
    };
  } else {

    return {
      padding: 0,
    };
  }
};

const Boxbottmfilter = styled(Box)({
  display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: '1rem',
  "@media(max-width:600px)": {
    flexDirection: "column",
    textAlign: "left",
    display: "table-row-group",
    marginTop: "1rem"


  },


})

const Boxfilterchild = styled(Box)({

  display: "flex", justifyContent: "flex-start", alignItems: "center", gap: '1rem',
  "@media(max-width:600px)": {

    marginTop: "1rem"


  },

})
const BoxEditStyle = styled(Box)({

  display: "flex", justifyContent: "space-between",
  "@media(max-width:600px)": {

   flexDirection:'column'


  },

})
const BoxChipformobile = styled(Box)({
display:"none",
 
  "@media(max-width:600px)": {
display:"block",
marginTop:"1rem"



  },

})
const BoxChipfordesk = styled(Box)({
display:"block",
 
  "@media(max-width:600px)": {
display:"none"


  },

})
const BoxImage = styled(Box)({

 
  "@media(max-width:600px)": {
mmarginTop:"0.6rem"


  },

})
const CustomIconButtonUp = styled(Typography)({
  marginBottom: "-14px",
  cursor: "pointer"
});

const CustomIconButtonDown = styled(Typography)({
  marginTop: "0px",
  cursor: "pointer"
});

export default class PortfolioManagement extends PortfolioManagementController {
  constructor(props: Props) {
    super(props);
  }

  handleValue = (value: boolean) => {
    if(value === true){
      return "Yes"
    }
    return "No"
  }

  CustomChips = ({ label, id }: CustomChipProps) => {
    return (
      <Box style={webStyle.customChips} data-test-id="delete-btn" >
        <IconButton size="small" sx={{ padding: "0" }}>
          < CloseIcon onClick={() => this.handleDelete(id)} />
        </IconButton>
        <Typography sx={{ marginTop: "3px" }}>{label}</Typography>
      </Box>
    );
  };
  render() {
    const { currentPage } = this.state;
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      <ThemeProvider theme={theme}>
        <Grid container >
          <Grid item md={1} sm={2} xs={2}>
            <NavigationSidebar
              data-test-id={"sidebarMenuIds"}
              activeItem={this.state.activeMyproperty}
              sidebarItems={[
                { label: configJSON.dashboardLandloard1, icon: dashboardLandLogo, labelKey: configJSON.dashboardLandlabel, activeIcon: activeMainDashboard, pathName: configJSON.dashboardLandlabel },
                { label: configJSON.myAccountlandloard1, icon: accountsMain, labelKey: configJSON.settinglandlabel,  activeIcon: activeMainAccount, pathName: configJSON.settinglandlabel },
                { label: configJSON.worklandloard1, icon: ordersMain, labelKey: configJSON.workOrderslandlabel, activeIcon: activeMainOrder, pathName: configJSON.workOrderslandlabel },

                { label: configJSON.preferredlandloard1, icon: vendorMain, labelKey: configJSON.prevendorlandlabel, activeIcon: activeMainVendor, pathName: configJSON.prevendorlandlabel },
                { label: configJSON.reminderslandloard1, icon: remindersMain, labelKey: configJSON.reminlandlabel, pathName: configJSON.reminlandlabel, activeIcon: activeMainReminder },
                {
                  label: configJSON.myproplandloard1, icon: propertyMain, labelKey: configJSON.myproplandlabel1, activeIcon: activePropertyMain, pathName: configJSON.myproplandlabel1,
                },

                { label: configJSON.financialandloard1, icon: financialMain, labelKey: configJSON.financlandlabel, activeIcon: activeMainFinancial, pathName: configJSON.financlandlabel },
                { label: configJSON.reportlandloard1, icon: reportMain, labelKey: configJSON.reportlandlabel, pathName: configJSON.reportlandlabel, activeIcon: activeMainReport },
                { label: configJSON.chatLandloard1, icon: keyMainChain, labelKey: configJSON.chatlandlabel, pathName: configJSON.chatlandlabel, activeIcon: activeKeyMain },

                { label: configJSON.settingLandlord1, icon: settingsMain, labelKey: configJSON.settingLandlord1, pathName: configJSON.settingLandlord1,  activeIcon: activeMainSetting }
              ]}
              onClickSidebar={this.handleMainItemMyproClicks}
              keasylogo={keylogoLogo}
              openDrawer={this.state.openLandlordDrawermypro}
              onClickDrawer={this.handleLandlordMyproDrawers} />
          </Grid>
          <Grid item md={11} xs={12} lg={11} style={getStyles()}>
            <PortfoliyoStyle>
              <Box>
                <Typography data-test-id="heading-text-data" className="heading-text" onClick={() =>this.handleClick("31")}>My Properties</Typography>
              </Box>
              <Grid container spacing={2} sx={{marginTop:"5px"}}>
                <Grid item md={6} xs={12}>
                  <Box className="search-content">
                    <img
                      className="search-icon"
                      src={searchSearch} alt="Location Icon" />
                    <InputBase
                      data-test-id="search-input"
                      className="search-input"
                      placeholder="Search"
                      inputProps={{ 'aria-label': 'search' }}
                      onChange={this.handleSearch}
                      value={this.state.searchQuery}
                    />
                  </Box>

                </Grid>

                <Grid item xs={12} md={6} justifyContent="flex-start">

                  <Box className="search-main-content">

                    <Box sx={webStyle.dropdowncontainer} >
                      <Box data-test-id="Properties" sx={webStyle.dropdowncontainerchild} onClick={this.handleClickpro} >
                        <Typography className="text-data">Properties</Typography>
                        <img className="icon" src={downDown} alt="down" />
                      </Box>
                      {this.state.isPopupVisible && (
                        <>
                            <Box sx={webStyle.popup}>
                          {this.state.filterData.map((data: PropertyData) => {
                          return(
                            <Box
                              sx={webStyle.flexContection}
                              onClick={() => this.handleClickblv(data.id, data.attributes.property_name)}
                              data-test-id="drop-btn"
                            >
                              <Typography style={webStyle.fontsizepoptext}>
                                {data.attributes.property_name}
                              </Typography>
                              <ChevronRightIcon />
                            </Box>
                          )
                        })}
                        </Box>


                        </>
                      )}
                       </Box>
                        <Box data-test-id="new-propertyBtn" className="new-property" onClick={() => this.navigateTo({ props: this.props, screenName: "PortfolioNewpropertymanagement" })}>
                      <img
                        className="icon"
                        src={plusPlus} alt="plus" />
                      <Typography className="text-data" >New property</Typography>

                    </Box>


                  </Box>


                </Grid>
                <Grid item md={6} xs={12}>
                  <Box className="filter-content">

                  <Box className="filter-contenttwo">
                  <Box className="filter">
                      <img
                        className="icon"
                        src={filterFilter} alt="filter" />
                      <Typography className="filter-text">Filters</Typography>
                    </Box>
                    <BoxChipfordesk sx={{ display: "flex", gap: "8px", flexWrap: "nowrap" }}>
                      {this.state.searchData.map((label: PropertyLabel) => (
                        <this.CustomChips
                          key={label.id}
                          id={label.id}
                          label={label.label}
                          data-test-id="handledelete"
                        
                        />
                      ))}
                    </BoxChipfordesk>
                  </Box>

                   <Box data-test-id="all-clear-btn" onClick={this.handleAllDelete}>
                   <Typography className="clear-text">Clear all</Typography>
                   </Box>

                  </Box>
                
                </Grid>
                {
                  this.state.isPopupVisibletwo &&
                  <Grid item md={6} xs={12} display="flex" justifyContent="center" alignItems="center">
                    <Box sx={webStyle.popuptwo} >
                      <Box style={{ width: "82px", padding: "1rem", borderRadius: "8px" }} data-test-id="UnitID" onClick={this.handleClickunitfalse}>
                        <Typography style={webStyle.fontsizepoptext}>Unit 1</Typography><br />
                        <Typography style={webStyle.fontsizepoptext}>Unit 1</Typography><br />
                        <Typography style={webStyle.fontsizepoptext}>Unit 1</Typography>
                      </Box>
                    </Box>
                  </Grid>
                }




              </Grid>





            </PortfoliyoStyle>
            <PortfoliyoStyle>
              <Grid item md={12}>

                <Boxbottmfilter>
                  <Boxfilterchild>
                    <PortfoliyoStyle>
                    <Typography className="UpperAddunitStyle">{this.state.queryData}</Typography>
                    {this.state.searchData.map((label: PropertyLabel) => (
                      <Typography className="UpperAddunitStyle">{label.label}</Typography>
                    ))}
                    </PortfoliyoStyle>
                    <Box>
                      <Typography  className="UpperAddunitStyletwo" >144 39th st. Bellevue WA 98005 | Pierce County</Typography>
                    </Box>
                  </Boxfilterchild>
                  <Box className="addunit-content">
                    <Box data-test-id="add-unit-btn" className="button-content" onClick={() => this.handleClickUnit()}>
                      <img className="add-icon" src={plusPlus} alt="filter" />
                      <Typography className="unit-text-data">Add unit</Typography>
                    </Box>
                  </Box>
                </Boxbottmfilter>


              </Grid>
            </PortfoliyoStyle>
                
            {this.state.propertiedData ? (
            this.state.propertiedData.map((property: PropertyData) => (
              property.attributes.units && property.attributes.units?.map((unit: Unit) => (
                <Boxgivemobile>
                  <Grid container style={{ width:"106%", marginTop: "16px"}}>
                    <Grid item md={10} xs={12}>
                      <Divcontainer>
                        <div style={{ display: "flex", justifyContent: "flex-start", gap: '1rem', alignItems: "center" }}>
                          <div>
                            <Typography data-test-id="unit-text" className="text-data">{unit.unit_name}</Typography>
                          </div>
                          <Box style={{ background: "#D1FAE5", borderRadius: "40px", alignItems: "center" }}>
                            <Button className="button-content">
                              <span style={webStyle.addUitStyle}>OCCUPIED</span>
                            </Button>
                          </Box>
                        </div>

                        <div className="buttoms-content">
                          <div data-test-id="openBtn" style={{ display: "flex", gap: '0.5rem', justifyContent: "center", alignItems: 'center' }} onClick={() => this.handleClickOpen(property.id)}>
                            <img src={homemyruch} alt="home" />
                            <Typography sx={webStyle.commontextSyle}>Property information</Typography>
                          </div>
                          <div data-test-id="findkey-btn" style={{ display: "flex", gap: '0.4rem', justifyContent: "center", alignItems: 'center', cursor: "pointer" }} onClick={() => this.handleClickOpendialog(property.id)}>
                            <img src={lockLock} alt="lock" />
                            <Typography sx={webStyle.commontextSyle}>Find keys</Typography>
                          </div>
                        </div>
                      </Divcontainer>
                    </Grid>

                    <Grid item md={1} style={webStyle.gridStyle} xs={12}>
                      <Boxaddtenents data-test-id="addtenent-btn" onClick={() => this.navigateTo({ props: this.props, screenName: "NewTenant" })}>
                        <img src={plusIcon} alt="lock" />
                        <Typography sx={webStyle.commontextSyle}>Add tenant</Typography>
                      </Boxaddtenents>
                    </Grid>

                    <Boxaddtenent>
                      <div style={{ display: "flex", gap: '0.5rem', justifyContent: "center", alignItems: 'center' }}>
                        <img src={plusIcon} alt="lock" />
                        <Typography sx={webStyle.commontextSyle}>Add tenant</Typography>
                      </div>
                    </Boxaddtenent>
                  </Grid>

                  {unit?.tenants?.map((tenantData: Data) => {
                    const dateOfBirth = this.formatDate(tenantData?.tenant.date_of_birth);
                    const renewalDate = this.formatDate(tenantData?.tenant.renewal_date);

                    return (
                      <React.Fragment key={tenantData?.tenant.name}>
                        <Boxparentdiv>
                          <Gapless>
                            <div> <Typography style={webStyle.fontsizeStyles}>Status</Typography></div>
                            <Box display="flex" flexDirection="column" marginLeft={"10px"}>
                             <Box>
                              <ArrowDropUp style={{ color:"#A3978F"}}/>
                            </Box>
                            <Box style={{ marginTop: "-16px"}}>
                             <ArrowDropDown style={{ color:"#A3978F"}}/>
                            </Box>
                           </Box>
                          </Gapless>

                          <Gapless>
                            <div> <Typography style={webStyle.fontsizeStyles}>Tenant name</Typography></div>
                            <Box display="flex" flexDirection="column" marginLeft={"10px"}>
                             <Box>
                              <ArrowDropUp style={{ color:"#A3978F"}}/>
                            </Box>
                            <Box style={{ marginTop: "-16px"}}>
                             <ArrowDropDown style={{ color:"#A3978F"}}/>
                            </Box>
                           </Box>
                          </Gapless>

                          <Gapless>
                            <div> <Typography style={webStyle.fontsizeStyles}>Date of birth</Typography></div>
                            <Box display="flex" flexDirection="column" marginLeft={"10px"}>
                             <Box>
                              <ArrowDropUp style={{ color:"#A3978F"}}/>
                            </Box>
                            <Box style={{ marginTop: "-16px"}}>
                             <ArrowDropDown style={{ color:"#A3978F"}}/>
                            </Box>
                           </Box>
                          </Gapless>

                          <Gapless>
                            <div> <Typography style={webStyle.fontsizeStyles}>Email</Typography></div>
                            <div style={{ color: "white", display: "none" }}> <ChevronRightIcon /></div>
                          </Gapless>

                          <Gapless>
                            <div> <Typography style={webStyle.fontsizeStyles}>Phone</Typography></div>
                            <div style={{ color: "white", display: "none" }}> <ChevronRightIcon /></div>
                          </Gapless>

                          <Gapless>
                            <div> <Typography style={webStyle.fontsizeStyles}>Lease Start</Typography></div>
                            <Box display="flex" flexDirection="column" marginLeft={"10px"}>
                             <Box>
                              <ArrowDropUp style={{ color:"#A3978F"}}/>
                            </Box>
                            <Box style={{ marginTop: "-16px"}}>
                             <ArrowDropDown style={{ color:"#A3978F"}}/>
                            </Box>
                           </Box>
                          </Gapless>
                          <Gapless>
                            <div> <Typography style={webStyle.fontsizeStyles}>Lease End</Typography></div>
                            <Box display="flex" flexDirection="column" marginLeft={"10px"}>
                             <Box>
                              <ArrowDropUp style={{ color:"#A3978F"}}/>
                            </Box>
                            <Box style={{ marginTop: "-16px"}}>
                             <ArrowDropDown style={{ color:"#A3978F"}}/>
                            </Box>
                           </Box>
                          </Gapless>
                        </Boxparentdiv>

                        <Boxnone>
                          <Accordion sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1-content"
                              id="panel1-header"
                            >
                              <Box sx={webStyle.statusStyle}>
                                <Box style={{ background: "#D1FAE5", borderRadius: "40px" }}>
                                  <Button className="button-content">
                                    <span style={webStyle.addUitStyle}>CURRENT</span>
                                  </Button>
                                </Box>
                                <Box sx={webStyle.statusName}>
                                  <Typography style={webStyle.tablestylemy}>{tenantData?.tenant.name}</Typography>
                                </Box>
                                <Box sx={webStyle.dateOfBirth}>
                                  <Typography style={webStyle.tablestylemy}>{dateOfBirth}</Typography>
                                </Box>
                                <Box sx={webStyle.emailAdd}>
                                  <Typography style={webStyle.tablestylemy}>{tenantData?.tenant?.email_address}</Typography>
                                </Box>
                                <Box sx={webStyle.phoneNumber}>
                                  <Typography style={webStyle.tablestylemy}>{tenantData?.tenant?.phone_number}</Typography>
                                </Box>
                                <Box sx={webStyle.leaseStart}>
                                  <Typography style={webStyle.tablestylemy}>09 Dec 2019(static date)</Typography>
                                </Box>
                                <Box sx={webStyle.leaseEnd}>
                                  <Typography style={webStyle.tablestylemy}>09 Dec 2019(static date)</Typography>
                                </Box>
                              </Box>
                            </AccordionSummary>
                            <AccordionDetails sx={{ backgroundColor:"#F3F2F0", borderRadius:"8px"}}>
                              <Grid container>
                                <Grid item md={12}>
                                  <Grid container justifyContent="center">
                                    <Grid item md={11}>
                                      <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", gap: "0.5rem", marginTop:"32px", width:"103%" }}>
                                        <Box data-test-id="Updateinformation" sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "0.5rem", cursor: "pointer" }}>
                                          <img src={pencil} style={{ mixBlendMode:"multiply"}} alt="pencil-icon"/>
                                          <Typography style={webStyle.pencilStyle}>Update Tenant Information</Typography>
                                        </Box>
                                      </Box>

                                      <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                                        <Box sx={{ paddingLeft: "1rem" }}>
                                          <Typography style={webStyle.insuRance}>Insurance</Typography>
                                        </Box>
                                      </Box>

                                      <TableContainer >
                                        <Table aria-label="custom table">
                                          <TableHead>
                                            <TableRow>
                                              <TableCell style={webStyle.borderNoneStyleone}>Name</TableCell>
                                              <TableCell style={webStyle.borderNoneStyleone}>Uploaded by</TableCell>
                                              <TableCell style={webStyle.borderNoneStyleone}>Upload Date</TableCell>
                                              <TableCell style={webStyle.borderNoneStyleone}>Renewal date</TableCell>
                                            </TableRow>
                                          </TableHead>
                                          <TableBody>
                                            <TableRow>
                                              <TableCell style={webStyle.borderNoneStyleIns}>
                                                <img src={imageIcon} alt="Placeholder" style={{ width: "24px", height: "24px", mixBlendMode: "multiply" }} />
                                                <Typography style={webStyle.borderStyleTwo}>Image.jpeg(static image)</Typography>
                                              </TableCell>
                                              <TableCell style={webStyle.borderNoneStyle}>Liat Arama(static name)</TableCell>
                                              <TableCell style={webStyle.borderNoneStyle}>09 Dec 2023(static date)</TableCell>
                                              <TableCell style={webStyle.borderNoneStyle}>{renewalDate}</TableCell>
                                              <TableCell style={webStyle.downStyleload}>Download(static)</TableCell>
                                              <TableCell style={webStyle.downStyleload}>Preview(static)</TableCell>
                                            </TableRow>
                                          </TableBody>
                                        </Table>
                                      </TableContainer>

                                      <Box sx={{ marginTop: '2.1rem' }}>
                                        <Typography sx={{ paddingLeft: "1rem", ...webStyle.insuRance }}>Vehicles</Typography>
                                      </Box>

                                      <Grid container style={{ paddingLeft: "1rem" }}>
                                        <Grid item md={3} xs={3}>
                                          <Typography style={webStyle.borderNoneStylethree}>Class of vehicle</Typography>
                                          <Typography style={webStyle.borderNoneStylethree}>Brand</Typography>
                                          <Typography style={webStyle.borderNoneStylethree}>Model</Typography>
                                          <Typography style={webStyle.borderNoneStylethree}>Vehicle registration plate</Typography>
                                        </Grid>
                                        <Grid item md={7} xs={7}>
                                          <Typography style={webStyle.borderNoneStyleTwo}>{tenantData?.tenant?.class_of_vehicle}</Typography>
                                          <Typography style={webStyle.borderNoneStyleTwo}>{tenantData?.tenant?.brand_name}</Typography>
                                          <Typography style={webStyle.borderNoneStyleTwo}>{tenantData?.tenant?.model}</Typography>
                                          <Typography style={webStyle.borderNoneStyleTwo}>{tenantData?.tenant?.vehicle_registration_plate}</Typography>
                                        </Grid>
                                      </Grid>

                                      <Box sx={{ marginTop: '4rem' }}>
                                        <Typography sx={{ paddingLeft: "1rem", ...webStyle.insuRance }}>Pets</Typography>
                                      </Box>

                                      <Grid container style={{ paddingLeft: "1rem", marginBottom: "40px" }}>
                                        <Grid item md={3} xs={3}>
                                          <Typography style={webStyle.borderNoneStylethree}>Type Of Pet</Typography>
                                          <Typography style={webStyle.borderNoneStylethree}>Pet Name</Typography>
                                          <Typography style={webStyle.borderNoneStylethree}>Age</Typography>
                                          <Typography style={webStyle.borderNoneStylethree}>Pet ID</Typography>
                                        </Grid>
                                        <Grid item md={7} xs={7}>
                                          <Typography style={webStyle.borderNoneStyleTwo}>{tenantData?.tenant?.type_of_pet}</Typography>
                                          <Typography style={webStyle.borderNoneStyleTwo}>{tenantData?.tenant?.pet_name}</Typography>
                                          <Typography style={webStyle.borderNoneStyleTwo}>3 Years(static year)</Typography>
                                          <div style={{ display: "flex", gap: "30px" }}>
                                            <div style={webStyle.downloadImg}>
                                              <img src={imageIcon} alt="Placeholder" style={{ width: "24px", height: "24px", mixBlendMode: "multiply" }} />
                                              <Typography style={webStyle.borderStyleTwo}>Image.jpeg(static image)</Typography>
                                            </div>
                                            <Typography style={webStyle.petStyle}>Download</Typography>
                                            <Typography style={webStyle.petStyle}>Preview</Typography>
                                          </div>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                        </Boxnone>

                        <Boxfordesknones>
                          <Boxfordesknone>
                            <div style={{ display: "flex", gap: "1rem", alignItems:"center", justifyContent:"center" }}>
                              <Typography className="text-data">Status</Typography>
                              <Box display="flex" flexDirection="column" marginLeft={"10px"}>
                               <Box>
                                <ArrowDropUp style={{ color:"#A3978F"}}/>
                               </Box>
                               <Box style={{ marginTop: "-16px"}}>
                                <ArrowDropDown style={{ color:"#A3978F"}}/>
                               </Box>
                              </Box>
                            </div>
                            <div style={{ display: "flex", gap: "1rem", alignItems:"center", justifyContent:"center" }}>
                              <Typography className="text-data">Name</Typography>
                              <Box display="flex" flexDirection="column" marginLeft={"10px"}>
                               <Box>
                                <ArrowDropUp style={{ color:"#A3978F"}}/>
                               </Box>
                               <Box style={{ marginTop: "-16px"}}>
                                <ArrowDropDown style={{ color:"#A3978F"}}/>
                               </Box>
                              </Box>
                            </div>
                            <div style={{ display: "flex", gap: "1rem", alignItems:"center", justifyContent:"center" }}>
                              <Typography className="text-data">Email</Typography>
                            </div>
                          </Boxfordesknone>

                          <div>
                            <Divider style={{ color: "grey" }} />
                          </div>

                          <div style={{ display: "flex", justifyContent: "space-between", width: "100%", marginTop: "1rem" }}>
                            <div>
                              <Box style={{ background: "#D1FAE5", borderRadius: "40px" }}>
                                <Button className="button-content">
                                  <span style={webStyle.addUitStylefont}>CURRENT</span>
                                </Button>
                              </Box>
                              <Box style={{ background: "#D1FAE5", borderRadius: "40px", marginTop: '1rem' }}>
                                <Button className="button-content">
                                  <span style={webStyle.addUitStylefont}>DELINQUENT</span>
                                </Button>
                              </Box>
                            </div>
                            <div>
                              <Typography style={webStyle.borderNoneStylemattie}>Mattie Blooman</Typography>
                              <Typography style={webStyle.borderNoneStylemattie1}>Mattie Blooman</Typography>
                            </div>
                            <div>
                              <Typography style={webStyle.borderNoneStylemattie}>Email@gmail.com</Typography>
                              <Typography style={webStyle.borderNoneStylemattie1}>Email@gmail.com</Typography>
                            </div>
                          </div>
                        </Boxfordesknones>
                      </React.Fragment>
                    );
                  })}
                </Boxgivemobile>
              ))
        ))
      ) : (<Typography style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "117px" }} className="noproperty-text">No Property Found</Typography>)}

          
          </Grid>
        </Grid>

        <React.Fragment>
        
          <Dialog
          data-test-id="openDialog"
            open={this.state.isOpenDialog}
            onClose={this.handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
              style: {
                borderRadius: '8px 8px 32px 8px',
              },
            }}
          >
             <DialogContent >
              <Box data-test-id="closeIcon" sx={{display: "flex", justifyContent: "right", alignItems: "right",cursor: 'pointer',marginBottom:"0.6rem"}} onClick={this.handleCloseopopup}>
                <CloseIcon/>
              </Box>
              {this.state.keyData.map((keydatas: PropertyData) => {
                return (
                  <BoxEditStyle style={{ gap: "10px", marginTop: "3%", marginBottom: "10%"}}>
                  <Box>
                    <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "0.5rem", }}>
                    <img src={pencil}/>
                    <Typography style={webStyle.pencilStyle}>Edit</Typography>
                    </Box>
                 
                    <Typography style={webStyle.KeyslocatedmaiBox}>Keys located in the mailbox,enter code #{keydatas?.attributes?.code}. Keep door closed at all times.</Typography>
                    <Box>
                    <Box>
                        <Typography style={webStyle.keyLocation}>Keys Location </Typography>
                        <Typography style={webStyle.keyLocation1}>Floor: {keydatas?.attributes?.floor}</Typography>
                        <Typography style={webStyle.keyLocation1}>Entrance: {keydatas?.attributes?.entrance}</Typography>
                        <Typography style={webStyle.keyLocation1}>Code #{keydatas?.attributes?.code}</Typography>
                      </Box>
               </Box>
                  </Box>
  
                  <BoxImage>
                    <img src = {dialogDialogimage} alt="edit"/>
                  </BoxImage>
  
                  
                 
                </BoxEditStyle>
                )
              })}
             
           
              <Box>
            
              </Box>
            </DialogContent>

          </Dialog>
        </React.Fragment>

        <React.Fragment>
          <Dialog
                maxWidth={false}
                sx={{
                  "& .MuiDialog-paper": {
                    width: "100%",
                    maxWidth: "1151px",
                  },
                }}
              open={this.state.isOpen}
              onClose={this.handleClose}
              aria-labelledby="responsive-dialog-title"
          >
              <DialogTitle id="responsive-dialog-title">
                <img aria-label="close"
                    onClick={this.handleClose}
                    data-test-id= 'close-dialog'
                    style={{
                        position: "absolute",
                        top: 8,
                        right: 8,
                    }}
                    src={closeImage} 
                    />
              </DialogTitle>

              {this.state.propertiInfoData.map((infoData: PropertyData) => {
                const yearBuild = this.formatYear(infoData?.attributes?.year_built);
                const startDate = this.formatDate(infoData?.attributes?.management_start);
                const renewalDate = this.formatDate(infoData?.attributes?.renewal_date);
                const insuranceExp = this.formatDate(infoData?.attributes?.insurance_expiration);
                return (
                  <DialogContent sx={{ background: "#F8FAFC" }}>
                <PortfoliyoStyle style={{ border: "1px solid #BAB1AB", padding: "20px", borderRadius: "8px", marginTop: "50px"}}>
                  <Grid container>
                    <Grid item md={6} xs={12}>
                    <Grid container>
                        <Grid item md={9} xs={6}>
                            <Box>
                                <Typography data-test-id="heading-data" style={webStyle.modalTitleName}>Hilltop Apartments | Unit 1</Typography>
                            </Box>

                        </Grid>
                        <Grid item md={3} xs={6}>
                            <Box style={webStyle.titleStyleContent}>
                              <Box style={webStyle.pencilEdit} data-test-id="handlepenciledit">
                                <img src={pencil} alt="edit"/>
                                <Typography style={webStyle.edittextData}>Edit</Typography>
                              </Box>
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid container spacing={1}>
                      <Grid item md={4} xs={6}>
                          <Typography style={webStyle.modalKeyStyle}>Property type</Typography>
                          <Typography style={webStyle.modalKeyStyle}>Address</Typography>
                          <Typography style={webStyle.modalKeyStyle}>Site manager</Typography>
                      </Grid>
                      <Grid item md={8} xs={6}>
                          <Typography style={webStyle.modalValueStyle}>{infoData?.attributes?.property_type}</Typography>
                          <Typography style={webStyle.modalValueStyle}>{infoData?.attributes?.address === null ? ("--") : infoData?.attributes?.address}</Typography>
                          <Typography style={webStyle.modalValueStyle}>{`${infoData?.attributes?.site_manager_prefix} ${infoData?.attributes?.site_manager_first_name} ${infoData?.attributes?.site_manager_last_name}`}</Typography>
                      </Grid>
                    </Grid>
                    </Grid>
                    <Grid className="sliderContainer" item md={6} xs={12}>
                        <div className="slider-container">
                          <Slider {...settings}>
                            <div>
                              <img src={imageOne}/>
                            </div>
                            <div>
                              <img src={imageTwo}/>
                            </div>
                            <div>
                              <img src={imageThree}/>
                            </div>
                            <div>
                              <img src={imageTwo}/>
                            </div>
                          </Slider>
                        </div>
                    </Grid>
                  </Grid>
                </PortfoliyoStyle>
                  <Grid container  >
                      <Grid item md={6}
                          sx={{
                            padding: "1rem",
                            "@media (max-width: 600px)": {
                                padding: 0,
                            }
                          }}
                      >
                          <Grid container style={{ marginTop: "2rem" }} >
                            <Grid item md={6} xs={6}>
                                <Box>
                                    <Typography style={webStyle.modalTitleName}>Property Information</Typography>
                                </Box>
                            </Grid>
                            <Grid item md={6} xs={6}>
                                <Box style={webStyle.titleStyleContent}>
                                  <IconButton style={webStyle.editContent} size="small">
                                    <img src={pencil} alt="pencil-icon-data"/>
                                    <Typography style={webStyle.edittextData}>Edit</Typography>
                                  </IconButton>
                                </Box>
                            </Grid>
                          </Grid>
                          <Grid container spacing={1}>
                            <Grid item md={4} xs={6}>
                                <Typography style={webStyle.modalKeyStyle}>Description</Typography>
                                <Typography style={webStyle.modalKeyStyle}>Portfolio</Typography>
                                <Typography style={webStyle.modalKeyStyle}>Site manager</Typography>
                                <Typography style={webStyle.modalKeyStyle}>Year built</Typography>
                                <Typography style={webStyle.modalKeyStyle}>Class</Typography>
                                <Typography style={webStyle.modalKeyStyle}>Management start date</Typography>
                                <Typography style={webStyle.modalKeyStyle}>Management end date</Typography>
                                <Typography style={webStyle.modalKeyStyle}>Management end reason</Typography>
                                <Typography style={webStyle.modalKeyStyle}>FolioGuard Policy</Typography>
                                <Typography style={webStyle.modalKeyStyle}>Rental license</Typography>
                                <Typography style={webStyle.modalKeyStyle}>Renewal date</Typography>
                                <Typography style={webStyle.modalKeyStyle}>Additional Interest</Typography>
                            </Grid>
                            <Grid item md={8} xs={6}>
                                <Typography style={webStyle.modalValueStyle}>{infoData?.attributes?.description}</Typography>
                                <Typography style={webStyle.modalValueStyle}>{infoData?.attributes?.portfolio}</Typography>
                                <Typography style={webStyle.modalValueStyle}>{`${infoData?.attributes?.site_manager_prefix} ${infoData?.attributes?.site_manager_first_name} ${infoData?.attributes?.site_manager_last_name}`}</Typography>
                                <Typography style={webStyle.modalValueStyle}>{yearBuild}</Typography>
                                <Typography style={webStyle.modalValueStyle}>{infoData?.attributes?.property_class}</Typography>
                                <Typography style={webStyle.modalValueStyle}>{startDate}</Typography>
                                <Typography style={webStyle.modalValueStyle}>09 Dec 2023</Typography>
                                <Typography style={webStyle.modalValueStyle}>Lorem ipsum dolor sit amet, consectetur adipiscing elit</Typography>
                                <Typography style={webStyle.modalValueStyle}>{this.handleValue(infoData?.attributes?.folio_guard_policy)}</Typography>
                                <Typography style={webStyle.modalValueStyle}>{this.handleValue(infoData?.attributes?.rental_license)}</Typography>
                                <Typography style={webStyle.modalValueStyle}>{renewalDate}</Typography>
                            </Grid>
                          </Grid>


                          <Grid container style={{ marginTop: "2rem" }}>
                              <Grid item md={6} xs={6}>
                                <Box>
                                    <Typography style={webStyle.modalTitleName}>Maintenance Information</Typography>
                                </Box>
                              </Grid>
                              <Grid item md={6} xs={6}>
                                  <Box style={webStyle.titleStyleContent}>
                                    <IconButton size="small" style={webStyle.editContentInfo}>
                                    <img src={pencil} alt="pencil-data"/>
                                    <Typography style={webStyle.edittextDataInfo}>Edit</Typography>
                                    </IconButton>
                                  </Box>
                              </Grid>
                          </Grid>
                          <Grid container spacing={1}>
                              <Grid item md={4} xs={6}>
                                  <Typography style={webStyle.modalKeyStyle}>Maintenance limit</Typography>
                                  <Typography style={webStyle.modalKeyStyle}>Insurance Expiration</Typography>
                                  <Typography style={webStyle.modalKeyStyle}>Has home warranty coverage</Typography>
                                  <Typography style={webStyle.modalKeyStyle}>Unit entry pre-authorized</Typography>
                                  <Typography style={webStyle.modalKeyStyle}>Maintenance notes</Typography>
                                  <Typography style={webStyle.modalKeyStyle}>Online maintenance</Typography>
                                  <Typography style={webStyle.modalKeyStyle}>Request instructions</Typography>
                              </Grid>
                              <Grid item md={8} xs={6}>
                                  <Typography style={webStyle.modalValueStyle}>09 Dec 2023</Typography>
                                  <Typography style={webStyle.modalValueStyle}>{insuranceExp}</Typography>
                                  <Typography style={webStyle.modalValueStyle}>{this.handleValue(infoData?.attributes?.warranty_coverage)}</Typography>
                                  <Typography style={webStyle.modalValueStyle}>{this.handleValue(infoData?.attributes?.unit_entry_pre_authorized)}</Typography>
                                  <Typography style={webStyle.modalValueStyle}>{infoData?.attributes?.maintenance_notes}</Typography>
                                  <Typography style={webStyle.modalValueStyle}>{infoData?.attributes?.maintenance_request}</Typography>
                                  <Typography style={webStyle.modalValueStyle}>1989</Typography>
                              </Grid>
                          </Grid>

                          <Grid container style={{ marginTop: "2rem" }} >
                            <Grid item md={6} xs={6}>
                                <Box>
                                    <Typography style={webStyle.modalTitleName}>Vacancy Posting Contact Info</Typography>
                                </Box>
                            </Grid>
                            <Grid item md={6} xs={6}>
                                <Box style={webStyle.titleStyleContent}>
                                  <IconButton size="small" style={webStyle.editContent}>
                                    <img src={pencil} alt="pencil-icon"/>
                                    <Typography style={webStyle.edittextData}>Edit</Typography>
                                  </IconButton>
                                </Box>
                            </Grid>
                          </Grid>

                          <Grid container spacing={1}>
                              <Grid item md={4} xs={6}>
                                  <Typography style={webStyle.modalKeyStyle}>Email address</Typography>
                                  <Typography style={webStyle.modalKeyStyle}>Phone</Typography>
                                  <Typography style={webStyle.modalKeyStyle}>Extension</Typography>
                              </Grid>
                              <Grid item md={8} xs={6}>
                                  <Typography style={webStyle.modalValueStyle}>{infoData?.attributes?.vacancy_posting_email_address}</Typography>
                                  <Typography style={webStyle.modalValueStyle}>{infoData?.attributes?.vacancy_posting_phone_number}</Typography>
                                  <Typography style={webStyle.modalValueStyle}>{infoData?.attributes?.vacancy_posting_area_code}</Typography>
                              </Grid>
                          </Grid>
                      </Grid>

                      <Grid item md={6}
                          sx={{
                            padding: "1rem",
                            "@media (max-width: 600px)": {
                                padding: 0,
                            }
                          }}
                      >
                          <Grid container style={{ marginTop: "2rem" }} >
                            <Grid item md={6} xs={6}>
                                <Box>
                                    <Typography style={webStyle.modalTitleName}>Amenities</Typography>
                                </Box>
                            </Grid>
                            <Grid item md={6} xs={6}>
                                <Box style={webStyle.titleStyleContent}>
                                  <IconButton size="small" style={webStyle.editContent}>
                                    <img src={pencil} alt="pencilIcon"/>
                                    <Typography style={webStyle.edittextData}>Edit</Typography>
                                  </IconButton>
                                </Box>
                            </Grid>
                          </Grid>
                          <Grid container spacing={1}>
                            <Grid item md={4} xs={6}>
                                <Typography style={webStyle.modalKeyStyle}>Cats Allowed</Typography>
                                <Typography style={webStyle.modalKeyStyle}>Dogs Allowed</Typography>
                                <Typography style={webStyle.modalKeyStyle}>Amenities</Typography>
                            </Grid>
                            <Grid item md={8} xs={6}>
                                <Typography style={webStyle.modalValueStyle}>{this.handleValue(infoData?.attributes?.cats_allowed)}</Typography>
                                <Typography style={webStyle.modalValueStyle}>{this.handleValue(infoData?.attributes?.dogs_allowed)}</Typography>
                                <Typography style={webStyle.modalValueStyle}>Code restricted access loby, Free WiFi, on-site laundry room</Typography>
                            </Grid>
                          </Grid>


                          <Grid container style={{ marginTop: "2rem" }}>
                              <Grid item md={6} xs={6}>
                                <Box>
                                    <Typography style={webStyle.modalTitleName}>Marketing information</Typography>
                                </Box>
                              </Grid>
                              <Grid item md={6} xs={6}>
                                  <Box style={webStyle.titleStyleContent}>
                                    <IconButton style={webStyle.editContent} size="small">
                                    <img src={pencil} alt="pencil"/>
                                    <Typography style={webStyle.edittextData}>Edit</Typography>
                                    </IconButton>
                                  </Box>
                              </Grid>
                          </Grid>
                          <Grid container spacing={1}>
                              <Grid item md={4} xs={6}>
                                  <Typography style={webStyle.modalKeyStyle}>Property marketing name</Typography>
                                  <Typography style={webStyle.modalKeyStyle}>Marketing description</Typography>
                                  <Typography style={webStyle.modalKeyStyle}>Lising Type</Typography>
                              </Grid>
                              <Grid item md={8} xs={6}>
                                  <Typography style={webStyle.modalValueStyle}>{infoData?.attributes?.property_marketing_name}</Typography>
                                  <Typography style={webStyle.modalValueStyle}>{infoData?.attributes?.marketing_description}</Typography>
                                  <Typography style={webStyle.modalValueStyle}>{infoData?.attributes?.lising_type}</Typography>
                              </Grid>
                          </Grid>

                          <Grid container style={{ marginTop: "2rem" }} >
                            <Grid item md={6} xs={6}>
                                <Box>
                                    <Typography style={webStyle.modalTitleName}>Attachments</Typography>
                                </Box>
                            </Grid>
                            <Grid item md={6} xs={6}>
                                <Box style={webStyle.titleStyleContent}>
                                  <IconButton size="small" style={webStyle.editContent}>
                                    <img src={pencil} alt="Pencil"/>
                                    <Typography style={webStyle.edittextData}>Edit</Typography>
                                  </IconButton>
                                </Box>
                            </Grid>

                            <Box >
                            <TableContainer >
                              <Table sx={{ borderCollapse: "collapse" }}>
                                  <TableHead>
                                      <TableRow>
                                          <TableCell style={webStyle.nameliststyle}>Name</TableCell>
                                          <TableCell style={webStyle.nameliststyle}>Uploaded by</TableCell>
                                          <TableCell style={webStyle.nameliststyle}>Date</TableCell>
                                          <TableCell style={webStyle.nameliststyle}>Shared</TableCell>

                                      </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {Array.from({ length: 5 }, (_, index) => (
                                      <TableRow key={index}>
                                        <TableCell sx={{ borderBottom: "none" }}>
                                          <img src={imageIcon} alt="Placeholder" /> Sample Name {index + 1}
                                        </TableCell>
                                        <TableCell style={webStyle.nameliststyle1}>Uploader {index + 1}</TableCell>
                                        <TableCell style={webStyle.nameliststyle1}>Date {index + 1}</TableCell>
                                        <TableCell style={webStyle.nameliststyle1}>Yes/No</TableCell>
                                        <TableCell style={webStyle.downloadstyle}>Download</TableCell>
                                        <TableCell style={webStyle.downloadstyle}>Preview</TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                              </Table>
                            </TableContainer>
                            <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                              <Pagination count={8} page={currentPage} />
                              <Typography variant="body1" style={{ marginLeft: 10 }}>1 of 8</Typography>
                            </Box>
                          </Box>
                          </Grid>
                      </Grid>
                  </Grid>
              </DialogContent>
                )
              })}
          </Dialog>
        </React.Fragment>

        <React.Fragment>
          <Dialog
                maxWidth={false}
                sx={{
                  "& .MuiDialog-paper": {
                    width: "100%",
                    maxWidth: "25%",
                  },
                }}
              open={this.state.isUnitOpen}
              onClose={this.handleCloseUnit}
              aria-labelledby="responsive-dialog-title"
          >
              <DialogTitle id="responsive-dialog-title">
                <img aria-label="close"
                    data-test-id="close-btn-data"
                    onClick={this.handleCloseUnit}
                    style={{
                        position: "absolute",
                        top: 8,
                        right: 8,
                    }}
                    src={closeImage} 
                    />
              </DialogTitle>

              <DialogContent sx={{ background: "#F8FAFC" }}>
                <Box style={{ padding: "20px", borderRadius: "8px"}}>
                  <PortfoliyoStyle>
                  <Typography style={{ fontSize:"18px"}}>Add unit name</Typography>
                  <TextField
                    style={{ marginTop: "10px"}}
                    className="textField"
                    variant="outlined"
                    fullWidth
                    size="small"
                    value={this.state.unitName}
                    data-test-id="field-value-unit"
                    onChange={(event) => {
                      this.onUnitChange(event.target.value)
                    }}
                  />
                  <Button
                    data-test-id="add-btn"
                    disabled={!this.state.unitName}
                      onClick={this.handleAdd}
                      style={{
                        width: "74px",
                        height: "35px",
                        fontFamily: "Outfit",
                        fontSize: "16px",
                        fontWeight: 700,
                        color: "#000000",
                        textAlign: "center",
                        backgroundColor: this.state.unitName ? "#fab03d" : "rgb(238, 228, 213)", 
                        borderRadius: "8px",
                        textTransform: "none",
                        cursor: this.state.unitName ? "pointer" : "not-allowed", 
                        marginLeft: "81%",
                        marginTop: "17px",
                      }}
                  >
                    Add
                  </Button>
                 </PortfoliyoStyle>
                </Box>
              </DialogContent>
          </Dialog>
        </React.Fragment>
      </ThemeProvider>

    );
  }

}
const webStyle = {
  customChips: {
    alignItems: "center",
    height: "26px",
    borderRadius: "4px",
    border: "1px solid black",
    display: "flex",
    padding: "1px 6px 1px 2px",
    gap: "8px"
},
  taatomChips: {
    alignItems: "center",
    height: "26px",
    borderRadius: "4px",
    border: "1px solid black",
    display: "flex",
    padding: "1px 6px 1px 2px",
    gap: "8px"
},
  gridStyle: {
    display: "flex", justifyContent: "center", marginLeft:"16px",
    cursor: "pointer"
  },
  popuptwo:{
    borderRadius: "8px",
    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
     width: "192px",
    backgroundColor: "#ffffff",
    position: "absolute",
    top: "50%",
  left: "71%"
  },
  KeyslocatedmaiBox:{
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "32px",
    fontfamily: "outfit",
    color: "#CC9200",
    marginTop:'1.4rem'
   
  },
  keyLocation:{
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "26px",
    fontfamily: "outfit",
    color: "#000000",
    marginTop:'1rem'
  },
  keyLocation1:{
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "26px",
    fontfamily: "outfit",
    color: "#000000",
    marginTop:'1rem'
  },

  unittextStyle: {
    marginTop: '1rem',
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    fontfamily: "outfit",
  },
  sideSidepop: {


    position: "absolute",
    left: "100%",
    top: "70%",
    borderRadius: "8px",
    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
    width: "80vw",  // Use viewport width for responsiveness
    maxWidth: "100px",  // Set a maximum width
    background: "red",
    transform: "translate(-50%, -50%)",
    padding: "1rem",


  },
  fontsizepoptextall: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    fontfamily: "outfit",


    color: '#CC9200'
  },
  fontsizepoptext: {

    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    fontfamily: "outfit",
    color: '#0F172A'
  },
  flexContectionC: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "0.6rem",
    position: "relative"

  },
  flexContection: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "0.6rem",

  },
  dropdowncontainer: {
    position: "relative",
    backgroundColor: "#FFF1CC",
    borderRadius: "8px",
    padding: '0.5rem',
    cursor: "pointer",
    paddingLeft:"0.5rem",
    paddingRight:"0.5rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  popup: {
    borderRadius: "8px",
    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
    position: "absolute",
    top: "100%",
    left: 0,
    width: "192px",
    backgroundColor: "#ffffff",
    marginTop: "1rem",

    overflow: "hidden",
    whiteSpace: "nowrap",
    zIndex: 1,
    padding: "10px"
  },
  dropdowncontainerchild: {
    width: "136px",
    display: "flex",
    alignItems: "center",

    borderRadius: "8px",
    justifyContent: "space-around",
    paddingLeft: "5px",
    paddingRight: "5px",
  },
  statusStyle: {
    display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", paddingLeft: "1rem", paddingRight: "1rem"
  },
  statusName: {
    marginLeft: "70px"
  },
  dateOfBirth: {
    marginLeft:"10%"
  },
  emailAdd: {
    marginLeft:"36px", marginRight: "-27px"
  },
  phoneNumber: {
    marginLeft: "27px", marginRight: "32px"
  },
  leaseStart: {
    marginRight: "16px", marginLeft: "-13px"
  },
  leaseEnd: {
    marginLeft: "4px"
  },
  borderNoneStylemattie1: {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "12px",
    fontfamily: "outfit",
    border: "none",
    color: "#000000",
    marginTop: "1.5rem"
  },
  borderNoneStylemattie: {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "12px",
    fontfamily: "outfit",
    border: "none",
    color: "#000000",
    marginTop: "0.5rem"

  },
  borderNoneStyle: {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "12px",
    fontfamily: "outfit",
    border: "none",
    color: "#000000"
  },
  borderNoneStyleTwo: {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "12px",
    fontfamily: "outfit",
    border: "none",
    color: "#000000",
    marginTop: '1rem',
  },
  borderNoneStylethree: {
    marginTop: '1rem',
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "12px",
    fontfamily: "outfit",
    border: "none",
    color: '#BAB1AB'
  },
  borderNoneStyleone: {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "12px",
    fontfamily: "outfit",
    border: "none",
    color: '#BAB1AB'
  },
  fontsizeStyles: {
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "10px",
    fontfamily: "outfit",
    color: '#A3978F'

  },
  tablestylemy: {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "12px",
    fontfamily: "outfit",
    color: "#000000"

  },
  downStyleload: {
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "10px",
    fontfamily: "outfit",
    color: '#CC9200',
    border: "none"

  },
  addUitStyle: {
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "10px",
    fontfamily: "outfit",
    color: '#059669'

  },
  addUitStylefont: {
    fontSize: "10px",
    fontWeight: 700,
    lineHeight: "10px",
    fontfamily: "outfit",
    color: '#059669'

  },
  insuRance: {
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    fontfamily: "outfit",
    color: '#000000'
  },
  pencilStyle: {
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    fontfamily: "outfit",
    color: '#CC9200'

  },
  commontextSyle: {
    fontSize: "14px",
    fontWeight: "bold",
    lineHeight: "10px",
    fontfamily: "outfit",
    color: '#CC9200'

  },
  modalTitleName: {
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: 700,
    fontFamily: "Outfit, sans-serif",
},

nameliststyle: {
    fontSize: "12px",
    fontWeight: 400,
    fontFamily: "Outfit, sans-serif",
    lineHeight: "12px",
    borderBottom: "none",
    color: "#BAB1AB"

},
nameliststyle1: {
    fontSize: "12px",
    fontWeight: 400,
    fontFamily: "Outfit, sans-serif",
    lineHeight: "12px",
    borderBottom: "none",
    color: "#000000"

},
modalKeyStyle: {
    fontSize: "12px",
    fontWeight: 400,
    fontFamily: "Outfit, sans-serif",
    lineHeight: "12px",
    margin: "8px 0",
    color: "#BAB1AB"
},
bordernonestyle: {
    borderBottom: "none",
    color: "#CC9200",
    fontSize: "12px",
    fontWight: 700,
    fontFamily: "outfit"

},
downloadstyle: {
    color: "#CC9200",
    fontSize: "12px",
    fontWight: 700,
    fontFamily: "outfit",
    borderBottom: "none",
},
modalValueStyle: {
    fontSize: "12px",
    fontWeight: 400,
    fontFamily: "Outfit, sans-serif",
    lineHeight: "12px",
    margin: "8px 0",
    color: "#000000", 
},

inputContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '14px',
    marginRight: '20px',
    border: '1px solid #CBD5E1',
    borderRadius: '98px',
    padding: '0 10px',
    height: '40px',
  },
  input: {
    border: 'none',
    outline: 'none',
    flex: 1,
    borderRadius: '98px',
    padding: '10px 0',
  },
  adornment: {
    marginRight: '10px',
  },
  titleStyleContent: {
    display: "flex",
    alignItems: "center",
    marginBottom: "1rem",
},
edittextData: {
  cursor: "pointer",
  fontSize: "12px",
  fontWeight: 700,
  fontFamily: "outfit",
  lineHeight: "10px",
  color: "#CC9200"
},
editContent: {
  marginLeft: "auto"
},
pencilEdit: {
  display: "flex", gap: "0.3rem" 
},
editContentInfo: {
  marginLeft: "auto"
},
edittextDataInfo: {
  cursor: "pointer",
  fontSize: "12px",
  fontWeight: 700,
  fontFamily: "outfit",
  lineHeight: "10px",
  color: "#CC9200"
},
petStyle: {
  fontSize: "12px",
    fontWeight: 700,
    lineHeight: "10px",
    fontfamily: "outfit",
    color: '#CC9200',
    border: "none",
    marginTop: '1rem',
},
borderStyleTwo: {
  fontSize: "12px",
    fontWeight: 400,
    lineHeight: "12px",
    fontfamily: "outfit",
    border: "none",
    color: "#000000"
},
downloadImg: {
  display: "flex", 
  gap:"8px",
  alignItems: "center", 
  justifyContent: "center", 
  marginTop: "7px"
},
borderNoneStyleIns: {
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "12px",
  fontfamily: "outfit",
  border: "none",
  color: "#000000",
  display: "flex", 
  alignItems: "center", 
  gap:"8px",
}
};



// Customizable Area End
