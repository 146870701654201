Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Analytics";
exports.labelBodyText = "Analytics Body";
exports.dashboardOrder = "Dashboard";
exports.myAccountOrder = "My Account";
exports.workOrder = "Work Orders";
exports.preferredOrder = "Preferred Vendors";
exports.remindersOrder = "Reminders";
exports.mypropOrder = "My Properties";
exports.financiaOrder = "Financial";
exports.reportOrder = "Delinquency Report";
exports.chatOrder = "Chat with Keasy";
exports.settingOrder = "Settings";
exports.dashboardOrderlabel = "Dashboard";
exports.myAccountOrderabel = "landlordaccount";
exports.worksOrderlabel = "Appointments";
exports.prevendorOrderlabel = "VendorDashboard";
exports.reminOrderlabel = "Notifications";
exports.mypropOrderlabel = "PortfolioManagement";
exports.financOrderlabel = "landlordfinancial";
exports.reportOrderlabel = "VisualAnalytics";
exports.chatOrderlabel = "Chat";
exports.settingOrderlabel = "Settings2";
exports.btnExampleTitle = "CLICK ME";

exports.dashboardOrder = "Dashboard";
exports.myAccountOrder = "My Account";
exports.workOrder = "Work Orders";
exports.preferredOrder = "Preferred Vendors";
exports.remindersOrder = "Reminders";
exports.mypropOrder = "My Properties";
exports.financiaOrder = "Financial";
exports.reportOrder = "Delinquency Report";
exports.chatOrder = "Chat with Keasy";
exports.settingOrder = "Settings";
exports.dashboardOrderlabel = "Dashboard";
exports.myAccountOrderabel = "landlordaccount";
exports.worksOrderlabel = "Appointments";
exports.prevendorOrderlabel = "VendorDashboard";
exports.reminOrderlabel = "Notifications";
exports.mypropOrderlabel = "PortfolioManagement";
exports.financOrderlabel = "Analytics";
exports.reportOrderlabel = "VisualAnalytics";
exports.chatOrderlabel = "Chat";
exports.settingOrderlabel = "Settings2";
exports.settings = "Settings";
exports.getPropertiesApiEndPoint = "/account_block/properties";
exports.getLedgerTypesApiEndPoint = "/bx_block_visual_analytics/account_transactions/get_ledger_type";
exports.addTransactionApiEndPoint = "/bx_block_visual_analytics/account_transactions";
exports.descriptionOptions = ["Income", "Expense", "Credit"];
exports.getTransactionlistAPIEndPoint="/bx_block_visual_analytics/account_transactions";
exports.getPropertyListAPIEndPoint = "/account_block/properties/show_all_properties";
exports.reversePaymentIdAPIEndPoint="/bx_block_visual_analytics/account_transactions/change_transaction_status"
exports.getApiDataMethod="GET";
exports.putApiDataMethod="PUT";
exports.failed="failed";
exports.completed="completed";
exports.pending="pending"

// Customizable Area End