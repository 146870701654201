import React from "react";
// Customizable Area Start
import {Box, Grid} from "@mui/material";
import NavigationSidebar from "../../../components/src/NavigationSidebar.web";
import { activeMainDashboard, activeMainAccount, keasyIcon, accountsMain, dashboardLandLogo, activeProjects, projects } from "./assets";
// Customizable Area End
import VendorDashboardController, {
    Props,
    configJSON
} from "./VendorDashboardController.web";

export default class VendorDashboard extends VendorDashboardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <Grid container>
                <Grid item xl={1} lg={1} md={1} sm={2} xs={2}>
                    <NavigationSidebar
                        data-test-id={"vendorId"}
                        activeItem={this.state.activeVendorItem}
                        sidebarItems={[
                            { label: configJSON.dashboardLandloard, labelKey: configJSON.vendorDashboard, icon: dashboardLandLogo, activeIcon: activeMainDashboard, pathName: configJSON.vendorDashboard },
                            { label: configJSON.vendorProfile, labelKey: configJSON.vendorLabelProfile, icon: accountsMain, activeIcon: activeMainAccount, pathName: configJSON.vendorLabelProfile },
                            { label: configJSON.projects, labelKey: configJSON.vendorProjects, icon: projects, activeIcon: activeProjects, pathName: configJSON.vendorProjects },
                        ]}
                        onClickSidebar={this.handleVendoritemClick}
                        keasylogo={keasyIcon}
                        openDrawer={this.state.openVendorDrawer}
                        onClickDrawer={this.handleVendorDrawer}
                    />
                </Grid>
                <Grid>
                    <Box sx={{
                        padding: {
                            flexGrow: 1,
                            borderLeft: "1px solid rgb(208,203,199)",
                        },
                    }}>{configJSON.vendor}</Box>
                </Grid>
            </Grid>
        );
        // Customizable Area End
    }
}

// Customizable Area Start

// Customizable Area End
