Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start

exports.getUserListApiContentType = "application/json";
exports.getCountriesApiContentType = "application/json";

exports.getUserListApiMethod = "GET";
exports.getUserListApiEndPoint = "/bx_block_custom_forms/custom_forms";

exports.addUserApiMethod = "POST";
exports.addUserApiEndPoint = "/bx_block_custom_forms/custom_forms";

exports.editUserApiMethod = "PUT";
exports.editUserApiEndPoint = "/bx_block_custom_forms/custom_forms";

exports.getCountriesApiMethod = "GET";
exports.getCountriesAPIEndPoint =
  "/account_block/landlord_accounts/countries_listing";

exports.addLandlordDetailsApiApiMethod = "POST";
exports.addLandlordDetailsApiEndPoint = "/account_block/landlord_accounts";

exports.createFormAPiEndPoint = "bx_block_custom_forms/custom_forms";

exports.token =
  "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6OCwiZXhwIjoxNjg2MzE3NzU3LCJ0b2tlbl90eXBlIjoibG9naW4ifQ.qtajs3wt_9FozBlJjL8iOYodM4KuZ0dVrsQbMYmH9798DOv21rRFy8FMgMlDzKDaKg7oZryerKM8ihX1x59fog";
exports.formAPiMethod = "POST";
exports.updateFormAPiMethod = "PUT";
exports.fetchFormDataMethod = "GET";
exports.formApiContentType = "multipart/form-data";
exports.errorPasswordNotValid = "Not valid.";
exports.labelHeader = " Custom Form ";
exports.btnTxtSubmit = "Submit";
exports.labelOr = "OR";
exports.placeHolderFirstName = "First Name";
exports.placeHolderLastName = "Last Name";
exports.placeHolderPhoneNo = "Phone Number";
exports.placeHolderOrg = "Organization";
exports.placeHolderTeamName = "Team Name";
exports.placeHolderEmail = "Email";
exports.placeHolderAddress = "Address";
exports.placeHolderCountry = "Country";
exports.placeHolderState = "State";
exports.placeHolderCity = "City";

exports.errorEmptyFields = "All fields are required.";
exports.errorEmailNotValid = "Email not valid.";
exports.errorTitle = "Error";
exports.labelTitle = "Submit";

exports.emailRegex =
/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
exports.dashboardGetUrl = "/bx_block_dashboard/candidates";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.totalCandidateslabelTitleText = "Total Candidates";
exports.viewDetailsBtn = "View Details";
exports.candidateslabelTitleText = "Candidates";
exports.baseUrl = "";
exports.labelBodyText = "dashboard Body";

exports.dashboardLandloard = "Dashboard";
exports.myAccountlandloard = "My Account";
exports.worklandloard = "Work Orders";
exports.preferredlandloard = "Preferred Vendors";
exports.reminderslandloard = "Reminders";
exports.myproplandloard = "My Properties";
exports.financialandloard = "Financial";
exports.reportlandloard = "Delinquency Report";
exports.chatLandloard = "Chat with Keasy";
exports.settingLandlord = "Settings";
exports.dashboardLandlabel = "landlordashboard";
exports.myAccountlandlabel = "landlordaccount";
exports.workOrderslandlabel = "landlordworkOrders";
exports.prevendorlandlabel = "landlordvendors";
exports.reminlandlabel = "landlordreminders";
exports.myproplandlabel = "landlordproperties";
exports.financlandlabel = "landlordfinancial";
exports.reportlandlabel = "landlordreport";
exports.chatlandlabel = "landlordchat";
exports.settinglandlabel = "landlordsettings";
exports.serviceRequests = "Service Requests";
exports.lease = "Lease";
exports.paymentHistory = "Payment History";
exports.servicelabel = "tenantservice";
exports.leaselabel = "tenantlease";
exports.paymentHistorylabel = "tenanthistory";
exports.tenantDashboard = "tenantdashboard";
exports.chatTenantabel = "tenantchat";
exports.vendorProfile = "Vendor Profile";
exports.projects = "Project History";
exports.vendorLabelProfile = "vendorprofile";
exports.vendorProjects = "vendorprojects";
exports.vendorDashboard = "vendordashboard";
exports.landlord = "Landlord Dashboard";
exports.tenant = "Tenant Dashboard";
exports.vendor = "Vendor Dashboard";
exports.vendorIconSide = "Preferred Dashboard";
exports.preffredVendors = "preferredVendors";
exports.secondNumber = "Secondary Contact Phone*";

// Customizable Area End
