import React, { Component } from "react";
import { Box, Drawer, Typography } from "@mui/material";
import { withStyles, createStyles } from "@mui/styles";
import { Close, Menu } from "@mui/icons-material";

const webStyles = createStyles({
    sidebar: {
        width: "100px",
        height: "100%",
        display: "flex",
        padding: "0px 10px 0px 10px",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#ffffff",
        paddingTop: "16px",
        gap: "16px",
        cursor: "pointer",
        "@media(max-width: 992px)": {
            paddingTop: "0px",
            alignItems: "flex-start",
            width: "0px"
        }
    },
    sidebarMain: {
        width: "100px",
        display: "flex",
        padding: "0px 10px 10px 10px",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#ffffff",
        borderRight: "1px solid rgb(208,203,199)",
        paddingTop: "16px",
        gap: "16px",
        cursor: "pointer"
    },
    menuItem: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "8px 0",
        color: "#A19890",
        textDecoration: "none",
        "&:hover": {
            backgroundColor: "#f5f5f5"
        },
    },
    activeMenuItem: {
        color: "#DAA520",
    },
    icon: {
        width: "24px",
        height: "24px",
    },
    navText: {
        fontSize: "12px",
        fontWeight: 700,
        wordBreak: "break-word",
        textAlign: "center"
    },
    logo: {
        marginBottom: "16px"
    },
    hamburgerIcon: {
        display: "none",
        "@media(max-width: 991px)": {
            display: "block"
        }
    },
    customDrawer: {
        display: "none",
        "@media(max-width: 992px)": {
            display: "block",
            width: "100%",
            paddingBottom: "10px",
            height: "100%"
        }
    },
    navbarMenu: {
        "@media(max-width: 992px)": {
            display: "none"
        }
    },
    closeIcon: {
        display: "none",
        "@media(max-width: 992px)": {
            display: "flex",
            alignSelf: "flex-end",
            padding: "10px 10px 0px 0px",
            cursor: "pointer"
        }
    }
});

interface MenuItem {
    label?: string;
    icon?: string;
    activeIcon?: string;
    labelKey?: string;
    pathName: string;
}

type Props = {
    activeItem: string;
    sidebarItems: MenuItem[];
    classes: Record<string, string>;
    onClickSidebar: (labelKey: string, pathName: string) => void;
    keasylogo: string;
    openDrawer: boolean;
    onClickDrawer: () => void;
}

class NavigationSidebar extends Component<Props> {
    render() {
        const { openDrawer, onClickDrawer, classes } = this.props;

        return (
            <Box className={classes.sidebar}>
                <Box className={classes.navbarMenu}>
                    {this.renderNavigation()}
                </Box>
                <Box className={classes.hamburgerIcon}>
                    <Menu onClick={() => { onClickDrawer() }} />
                </Box>
                <Drawer open={openDrawer} className={classes.customDrawer}>
                    {this.renderNavigation()}
                </Drawer>
            </Box>
        )
    }

    renderNavigation() {
        const { activeItem, sidebarItems, onClickSidebar, keasylogo, classes, onClickDrawer } = this.props;

        return (
            <>
                <Box className={classes.closeIcon}>
                    <Close onClick={() => { onClickDrawer() }} />
                </Box>
                <Box className={classes.sidebarMain}>
                    <img src={keasylogo} alt="keasylogo" className={classes.logo} />
                    {sidebarItems.map((items: MenuItem) => (
                        <Box
                            key={items.labelKey}
                            className={`${classes.menuItem} ${items.labelKey === activeItem ? classes.activeMenuItem : ""
                                }`}
                            onClick={() => onClickSidebar(items.labelKey || "", items.pathName)}>
                            <img
                                src={items.labelKey === activeItem ? items.activeIcon : items.icon}
                                alt={items.label}
                                className={classes.icon}
                            />
                            <Typography className={classes.navText}>{items.label}</Typography>
                        </Box>
                    ))}
                </Box>
            </>
        );
    }
}

export default withStyles(webStyles)(NavigationSidebar);
