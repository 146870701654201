export const keasyIcon = require("../../dashboard/assets/keasylogo.svg");
export const dashboardLandLogo = require("../../dashboard/assets/dashboard.png");
export const activeMainDashboard = require("../../dashboard/assets/activeDashboard.png");
export const accountsMain = require("../../dashboard/assets/profile.png");
export const activeMainAccount = require("../../dashboard/assets/activeProfile.png");
export const ordersMain = require("../../dashboard/assets/order.png");
export const activeMainOrder = require("../../dashboard/assets/activeorder.png");
export const vendorMain = require("../../dashboard/assets/vendor.png");
export const activeMainVendor = require("../../dashboard/assets/activeSave.png");
export const remindersMain = require("../../dashboard/assets/reminder.png");
export const activeMainReminder = require("../../dashboard/assets/activeReminder.png");
export const propertyMain = require("../../dashboard/assets/property.png");
export const activePropertyMain = require("../../dashboard/assets/activeProperty.png");
export const financialMain = require("../../dashboard/assets/financial.png");
export const activeMainFinancial = require("../../dashboard/assets/activeFinancial.png");
export const reportMain = require("../../dashboard/assets/report.png");
export const activeMainReport = require("../../dashboard/assets/activeReport.png");
export const keyMainChain = require("../../dashboard/assets/keyChain.png");
export const activeKeyMain = require("../../dashboard/assets/activeKey.png");
export const imageOne = require("../../portfoliomanagement/assets/img1.jpg");
export const imageTwo = require("../../portfoliomanagement/assets/img2.jpg");
export const next = require("../assets/next.svg");
export const previous= require("../assets/previous.svg");