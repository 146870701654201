export const getStatusStyles = (status: string) => {
    switch (status) {
      case "active":
      case "occupied":
        return {
          backgroundColor: "#D1FAE5",
          color: "#059669",
          borderColor: "#D1FAE5",
        };
      case "in debt":
        return {
          backgroundColor: "#FEF3C7",
          color: "#D97706",
          borderColor: "#FEF3C7",
        };
      case "delinquent":
      case "vacant":
        return {
          backgroundColor: "#FEE2E2",
          color: "#DC2626",
          borderColor: "#FEE2E2",
        };
      case "evicting":
        return {
          backgroundColor: "#FEF3C7",
          color: "#D97706",
          borderColor: "#FEE2E2",
        };
      default:
        return {
          backgroundColor: "#E5E7EB",
          color: "#374151",
          borderColor: "#E5E7EB",
        };
    }
  };