import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config.js");
// Customizable Area Start
import { Message } from "../../../framework/src/Message";
const navigation = require("react-navigation");
  
// Customizable Area End

export interface Props {
    navigation: typeof navigation;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    activeVendorItem: string;
    openVendorDrawer: boolean;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}
export default class TenantDashboardController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
            // Customizable Area End
        ];
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            activeVendorItem: configJSON.vendorDashboard,
            openVendorDrawer: false
            // Customizable Area End
        };
    }
    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    handleVendoritemClick = (vendorKey: string) => {
        this.setState({ activeVendorItem: vendorKey },()=>{
            this.handleVendorMenu();
        });
    };

    handleVendorDrawer = () => {
        this.setState({
            openVendorDrawer: !this.state.openVendorDrawer
        });
    };

    handleVendorMenu = () => {
        const { activeVendorItem } = this.state;
        const toMsgWork = new Message(getName(MessageEnum.NavigationMessage));
        toMsgWork.addData(
            getName(MessageEnum.NavigationTargetMessage),
            activeVendorItem
        );
        toMsgWork.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
        );
        this.send(toMsgWork);
    };
    // Customizable Area End
}
