// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import JsFileDownloader from "js-file-downloader";
import { getStorageData } from "../../../../packages/framework/src/Utilities";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isVisibleModal: boolean;
  downloadData: { [keyData: string]: string }[];
  activeTenantItem:string;
  openTenantDrawer: boolean;
  name: string;
  email: string;
  phoneNumber: string;
  numberOfOccupants: number;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class DownloadOptionsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getApicallData: string = "";
  getLeaseDataApiCallId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isVisibleModal: false,
      downloadData: [],
      activeTenantItem:"Lease",
      openTenantDrawer: false,
      name: "",
      email: "",
      phoneNumber: "",
      numberOfOccupants: 0,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    this.getUserDocumentsData();
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived in");

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getApicallData) {
        if (!responseJson.errors) {
        }        
      }
      if(apiRequestCallId == this.getLeaseDataApiCallId){
        let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
        const tenantInfo = responseJson.data.attributes.tenant_information;

        this.setState({
          name: tenantInfo.name || "",
          email: tenantInfo.email || "",
          phoneNumber: tenantInfo.phone || "",
          numberOfOccupants: tenantInfo.number_of_occupants || 0,
        });  
       if(!!responseJson){
        
       }
      }
    }

    // Customizable Area End
  }

  // web events
  // Customizable Area Start


  getUserDocumentsData() {
    this.getLeaseData();
  }

  async handleDownload(imageUrl: string) {
    try {
      new JsFileDownloader({
        url: imageUrl,
      });
    } catch (error) {}
  }
  handleMainItemClick = (labelKey: string) => {
    this.setState({ activeTenantItem: labelKey }, () => {
      this.handleWorkOrderMenu();
    });
  };
  handleWorkOrderMenu = () => {
    const { activeTenantItem } = this.state;
    const toMsgWork = new Message(getName(MessageEnum.NavigationMessage));
    toMsgWork.addData(
      getName(MessageEnum.NavigationTargetMessage),
      activeTenantItem
    );
    toMsgWork.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(toMsgWork);
  };
  handleTenantDrawer = () => {
    this.setState({
        openTenantDrawer: !this.state.openTenantDrawer
    });
};
  getLeaseData = async  () => {
    const token = await getStorageData("authToken")

    const header = {
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getLeaseDataApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getLeaseDataApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
