import React, { CSSProperties } from 'react';

// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  createTheme,
  styled,
  RadioGroup,
  Radio,
  FormControlLabel,
  Checkbox,
  FormGroup,
} from '@mui/material';
import FileUpload from "../../accountgroups/src/FileUpload";
import CustomDatePicker from "../../../components/src/CustomDatePicker.web";
import CustomTimePicker from "../../../components/src/CustomTimePicker.web";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { FileWithPreview } from './AddAppointmentController';
import NavigationSidebar from "../../../components/src/NavigationSidebar.web";
export const keasyIcon = require("../../customform/assets/keasylogo.svg");
export const dashboardLandLogo = require("../../customform/assets/dashboard.png");
export const activeMainDashboard = require("../../customform/assets/activeDashboard.png");
export const accountsMain = require("../../customform/assets/profile.png");
import { activeOrderFinancial } from './assets'
export const activeMainAccount = require("../../customform/assets/activeProfile.png");
export const ordersMain = require("../../customform/assets/order.png");
export const activeMainOrder = require("../../customform/assets/activeorder.png");
export const vendorMain = require("../../customform/assets/vendor.png");
export const activeMainVendor = require("../../customform/assets/activeSave.png");
export const remindersMain = require("../../customform/assets/reminder.png");
export const activeMainReminder = require("../../customform/assets/activeReminder.png");
export const propertyMain = require("../../customform/assets/property.png");
export const activePropertyMain = require("../../customform/assets/activeProperty.png");
export const financialMain = require("../../customform/assets/financial.png");
export const activeMainFinancial = require("../../customform/assets/activeFinancial.png");
export const reportMain = require("../../customform/assets/report.png");
export const activeMainReport = require("../../customform/assets/activeReport.png");
export const keyMainChain = require("../../customform/assets/keyChain.png");
export const activeKeyMain = require("../../customform/assets/activeKey.png");
export const settingsMain = require("../../customform/assets/settings.png");
export const activeMainSetting = require("../../customform/assets/activeSetting.png");
export const projects = require("../../customform/assets/projects.png");
export const activeProjects = require("../../customform/assets/activeprojects.png");
export const prefferedVendor = require("../../customform/assets/PrefferedVendors.png");

const theme = createTheme({
  palette: {
    primary: {
      main: '#0000ff',
      contrastText: '#fff',
    },
  },
});
const validationSchema = Yup.object().shape({
  property: Yup.number().required('Property is required'),
  category: Yup.number().required('Category is required'),
  workNeeded: Yup.string().required('Work needed is required'),
  dueDate: Yup.date()
    .required('Due date is required')
    .nullable()
    .test('is-later', 'Due date must be later than the reminder date', function (value) {
      const { remindMeOnDate } = this.parent;
      return remindMeOnDate ? value > remindMeOnDate : true;
    }),
  dueHour: Yup.string().required('Due time is required'),
  dueFrequency: Yup.string().required('Select frequency'),
  remindMeOnDate: Yup.date()
    .required('Reminder date is required')
    .nullable()
    .test('is-earlier', 'Reminder date must be before the due date', function (value) {
      const { dueDate } = this.parent;
      return dueDate ? value < dueDate : true;
    }),
  remindMeOnHour: Yup.string().required('Reminder time is required'),
  remindMeFrequency: Yup.string().required('Select frequency'),
  permissionToEnter: Yup.boolean().nullable().required('Permission to enter is required'),
  permissionDescription: Yup.string().required('Permission description is required'),
  description: Yup.string().required('Description is required'),
  documents: Yup.array().min(1, 'At least one document is required'),
  unit: Yup.number().required('Unit is required'),
  vendorId: Yup.number().required('Please choose a vendor'),
});
// Customizable Area End

import AddAppointmentController, {
  Props,
  configJSON,
} from './AddAppointmentController';

export default class AddAppointment extends AddAppointmentController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const navigation = {}; 
    const id = 'addWorkOrder';
    const { currentVendorIndex, units  } = this.state;
    
    const currentVendor = this.state.allVendors[currentVendorIndex];
    const { allProperties } = this.state;
    const { allCatagory } = this.state;
    
    return (
      <AddWorkOrderStyle>
        <NavigationSidebar
          data-test-id={"navId"}
          activeItem={this.state.activeMainItem}
          sidebarItems={[
            { label: configJSON.dashboardOrder, labelKey: configJSON.dashboardOrderlabel, icon: dashboardLandLogo, activeIcon: activeMainDashboard, pathName: configJSON.dashboardOrderlabel },
            { label: configJSON.myAccountOrder, labelKey: configJSON.settingOrderlabel, icon: accountsMain, activeIcon: activeMainAccount, pathName: configJSON.myAccountOrderabel },
            { label: configJSON.workOrder, labelKey: configJSON.worksOrderlabel, icon: ordersMain, activeIcon: activeMainOrder, pathName: configJSON.worksOrderlabel },
            { label: configJSON.preferredOrder, labelKey: configJSON.prevendorOrderlabel, icon: vendorMain, activeIcon: activeMainVendor, pathName: configJSON.prevendorOrderlabel },
            { label: configJSON.remindersOrder, labelKey: configJSON.reminOrderlabel, icon: remindersMain, activeIcon: activeMainReminder, pathName: configJSON.reminOrderlabel },
            { label: configJSON.mypropOrder, labelKey: configJSON.mypropOrderlabel, icon: propertyMain, activeIcon: activePropertyMain, pathName: configJSON.mypropOrderlabel },
            { label: configJSON.financiaOrder, labelKey: configJSON.financOrderlabel, icon: financialMain, activeIcon: activeOrderFinancial, pathName: configJSON.financOrderlabel },
            { label: configJSON.reportOrder, labelKey: configJSON.reportOrderlabel, icon: reportMain, activeIcon: activeMainReport, pathName: configJSON.reportOrderlabel },
            { label: configJSON.chatOrder, labelKey: configJSON.chatOrderlabel, icon: keyMainChain, activeIcon: activeKeyMain, pathName: configJSON.chatOrderlabel },
            { label: configJSON.settingOrder, labelKey: configJSON.settings, icon: settingsMain, activeIcon: activeMainSetting, pathName: configJSON.settings }
          ]}
          onClickSidebar={this.handleMainItemClick}
          keasylogo={keasyIcon}
          openDrawer={this.state.openLandlordDrawer}
          onClickDrawer={this.handleLandlordDrawer}
        />
        <Box className="rightBox">
          <Box className="heading" style={{display: "flex", justifyContent: "space-between"}}>
            <Box>New Work Order</Box>
            <Button className="goBackBtn" style={{ textTransform: 'none' }} data-test-id="backBtnTest" onClick={this.navigateToAppointments}>Go Back</Button>
          </Box>
          <Box className="workOrderForm">
          <Formik
              enableReinitialize={true}
              validateOnBlur={true}
              initialValues={{
                property: '',
                category: '',
                workNeeded: '',
                dueDate: null,
                dueHour: '',
                dueFrequency: '',
                remindMeOnDate: null,
                remindMeOnHour: '',
                remindMeFrequency: '',
                permissionToEnter: null,
                permissionDescription: '',
                cost: false,
                description: '',
                documents: [],
                unit: '',
                vendorId: '',
              }}
              validationSchema={validationSchema}
              onSubmit={async (values, formikHelpers) => {
                try {
                  this.addAppointment();
                } catch (error) {
                }
              }}
              data-test-id="Formik"
            >
              {({ errors, values, handleSubmit, setFieldValue }) => {
                return (
            <form onSubmit={handleSubmit}>
              <Box className="InputWrapper">
                <Box className="InputWrapperLeft">
                  Property
                </Box>
                <Box className="InputWrapperRight">
                  <Box style={{marginRight: "25px"}} className="selectBox">
                  <select 
                    className='select' 
                    onChange={(e) => {
                      setFieldValue("property", e.target.value);
                      this.setProperty(Number(e.target.value));
                    }}
                    data-test-id="propertyTest"
                  >
                    <option value="">Property</option>
                      {allProperties?.map((property:any) => (
                        <option key={property.id} value={Number(property.id)}>
                          {property.attributes.property_name}
                        </option>
                      ))}
                  </select>
                    <div className="error">{errors?.property}</div>
                  </Box>
                  <Box className="selectBox">
                  <select 
                    className='select marginTop'
                    onChange={(e) => {
                      setFieldValue("unit", e.target.value);
                      this.setUnit(Number(e.target.value));
                    }}
                    data-test-id="unitTest"
                  >
                    <option value="">Unit</option>
                    {units.map((unit: any) => (
                      <option key={unit.id} value={unit.id}>
                        {unit.unit_name}
                      </option>
                    ))}
                  </select>
                    <div className="error">{errors?.unit}</div>
                  </Box>
                </Box>
              </Box>
              <Box className="InputWrapper">
                <Box className="InputWrapperLeft">
                  Category
                </Box>
                <Box style={{display: 'block'}} className="InputWrapperRight">
                  <select 
                    className='selectLong' 
                    onChange={(e) => {
                      setFieldValue("category", e.target.value);
                      this.setCatagory(Number(e.target.value));
                    }}
                    data-test-id="catagoryTest"
                  >
                    <option value=""></option>
                    {allCatagory?.map((catagory:any) => (
                        <option key={catagory.id} value={catagory.id}>
                          {catagory.name}
                        </option>
                      ))}
                  </select>
                  {errors.category && (
                    <div className="error">{errors.category}</div>
                  )}
                </Box>
              </Box>
              <Box className="InputWrapper">
                <Box className="InputWrapperLeft">
                  Work needed
                </Box>
                <Box style={{display: 'block'}} className="InputWrapperRight">
                  <input 
                    className='input'
                    maxLength={100}
                    onChange={(e) => {
                      setFieldValue("workNeeded", e.target.value);
                      this.setWorkNeeded(e.target.value);
                    }}
                    data-test-id="workNeededTest"
                  />
                  {errors.workNeeded && (
                    <div className="error">{errors.workNeeded}</div>
                  )}
                </Box>
              </Box>
              <Box className="InputWrapper" style={{paddingBottom: "17px"}}>
                <Box className="InputWrapperLeft">
                  Due
                </Box>
                <Box className="InputWrapperRight">
                  <Box style={{marginRight: "25px"}} className="dateSelect">
                    <Typography
                      sx={{ marginBottom: "8px" }}
                    >
                      Date
                    </Typography>
                    <CustomDatePicker
                      value={this.state.dueDate}
                      onChange={(newValue)=>{
                        setFieldValue("dueDate", newValue);
                        this.handleDateChange(
                        "dueDate", newValue
                        )}} 
                        data-test-id="dueDateTest"
                      />
                      {errors.dueDate && (
                        <div className="error">{errors.dueHour}</div>
                      )}
                  </Box>
                  <Box  className="dateSelect marginTop">
                  <Typography
                    sx={{ marginBottom: "8px" }}
                  >
                    Time
                  </Typography>
                  <CustomTimePicker
                      value={this.state.dueHour}
                      onChange={(newValue)=>{
                        setFieldValue("dueHour", newValue);
                        this.handleDateChange(
                        "dueHour",newValue
                        )}} 
                      data-test-id="dueHourTest"
                      />
                      {errors.dueHour && (
                        <div className="error">{errors.dueHour}</div>
                      )}
                  </Box>
                </Box>
              </Box>
              <Box className="InputWrapper" style={{paddingTop: "0px"}}>
                <Box className="InputWrapperLeft">
                </Box>
                <Box className="frequencyWrapper">
                  <Box>
                  <select className='frequency' data-test-id="dueFrequencyTest" onChange={(e) => {
                      setFieldValue("dueFrequency", e.target.value);
                      this.setDueFrequency(e.target.value);
                    }}>
                    <option>Frequency</option>
                    <option value="daily">Daily</option>
                    <option value="weekly">Weekly</option>
                    <option value="biweekly">Biweekly</option>
                    <option value="monthly">Monthly</option>
                    <option value="yearly">yearly</option>
                  </select>
                  {errors.dueFrequency && (
                    <div className="error">{errors.dueFrequency}</div>
                  )}
                  </Box>
                </Box>
              </Box>
              <Box className="InputWrapper" style={{paddingBottom: "17px"}}>
                <Box className="InputWrapperLeft">
                  Remind me on
                </Box>
                <Box className="InputWrapperRight">
                  <Box style={{marginRight: "25px"}} className="dateSelect">
                    <Typography
                      sx={{ marginBottom: "8px" }}
                    >
                      Date
                    </Typography>
                    <CustomDatePicker
                      value={this.state.remindMeOnDate}
                      onChange={(newValue)=>{
                        setFieldValue("remindMeOnDate", newValue);
                        this.handleDateChange(
                        "remindMeOnDate",newValue
                        )}} 
                        data-test-id="remindMeOnDateTest"
                      />
                      {errors.remindMeOnDate && (
                        <div className="error">{errors.remindMeOnDate}</div>
                      )}
                  </Box>
                  <Box  className="dateSelect marginTop">
                  <Typography
                    sx={{ marginBottom: "8px" }}
                  >
                    Time
                  </Typography>
                  <CustomTimePicker
                      value={this.state.remindMeOnHour}
                      onChange={(newValue)=>{
                        setFieldValue("remindMeOnHour", newValue);
                        this.handleDateChange(
                        "remindMeOnHour",newValue
                        )}} 
                        data-test-id="remindMeOnHourTest"  
                      />
                      {errors.remindMeOnHour && (
                        <div className="error">{errors.remindMeOnHour}</div>
                      )}
                  </Box>
                </Box>
              </Box>
              <Box className="InputWrapper" style={{paddingTop: "0px"}}>
                <Box className="InputWrapperLeft">
                </Box>
                <Box className="frequencyWrapper">
                  <Box>
                  <select className='frequency' data-test-id="remindMeOnFrequencyTest" onChange={(e) => {
                      setFieldValue("remindMeFrequency", e.target.value);
                      this.setRemindMeFrequency(e.target.value);
                    }}>
                    <option>Frequency</option>
                    <option value="daily_remind">Daily</option>
                    <option value="weekly_remind">Weekly</option>
                    <option value="biweekly_remind">Biweekly</option>
                    <option value="monthly_remind">Monthly</option>
                    <option value="yearly_remind">yearly</option>
                  </select>
                  {errors.remindMeFrequency && (
                    <div className="error">{errors.remindMeFrequency}</div>
                  )}
                  </Box>
                </Box>
              </Box>
              <Box className="InputWrapper">
                <Box className="InputWrapperLeft">
                  Assign a vendor
                </Box>
                <Box className="assignVenderCarouselWrapper">
                  <Box className="assignVenderCarousel">
                    <Box className="assignVenderNavBtn" data-test-id="navRightBtnTest" onClick={this.handlePrev}><img style={{height: '40px'}} src={require('../assets/left.svg')}/></Box>
                    <Box className="carouselBox">
                      <Box className="carouselImgWrapper"><img data-test-id="carouselImg" className="carouselImg" src={currentVendor?.profile_pic}/></Box>
                      <Box style={{marginTop: "10px"}} className="carouselText">
                        <Box data-test-id="carouselText" style={{fontWeight: 700}}>{currentVendor?.name}</Box>
                        <Box className="assignVenderBox1">
                          <span data-test-id="carouselPhone" style={{color: "#64748B", fontSize: "14px"}}>Phone number: {currentVendor?.phone_number}</span>
                          <span className='keaseSelected'>Keasy Selected</span>
                        </Box>
                        <Box style={{color: "#64748B", fontSize: "14px", marginTop: "5px", marginBottom: "10px"}}>E-mail:{currentVendor?.email_address}</Box>
                        <Box className="assignVenderBox1">
                          <Box style={{fontSize: "12px"}}>
                            <span style={{fontWeight: 700}}>Working hours</span><br/>
                            <span style={{color: "#000000"}}>Mon-Fri:{currentVendor?.mon_fri_working_hours}, {currentVendor?.sat_working_hours ? `Sat:${currentVendor?.sat_working_hours},` : ""}{currentVendor?.sun_working_hours ? `Sun:${currentVendor?.sun_working_hours},` : ""} Min. cost ${currentVendor?.minimum_cost}</span>
                          </Box>
                          <Button className="chooseVenderBtn" style={{ textTransform: 'none' }} data-test-id="chooseVendorTest" onClick={(e) => {
                              setFieldValue("vendorId", currentVendor.id);
                              this.setVendorId(currentVendor.id);
                            }}>Choose Vendor</Button>
                        </Box>
                      </Box>
                    </Box>
                    <Box className="assignVenderNavBtn" data-test-id="navLeftBtnTest" onClick={this.handleNext}><img style={{height: '40px'}} src={require('../assets/right.svg')}/></Box>
                  </Box>
                  {errors.vendorId && (
                      <div className="error">{errors.vendorId}</div>
                    )}
                </Box>
              </Box>
              <Box className="InputWrapper">
                <Box className="InputWrapperLeft">
                  Permission to enter
                </Box>
                <Box className="radioOuterWrapper">
                  <Box className="radioWrapper">
                    <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          name="radio-buttons-group"
                          sx={{
                            display: 'flex',
                            flexDirection: 'row', 
                          }}
                          data-test-id="permissionRadioGroupTest"
                        >
                          {[
                            "Yes",
                            "No",
                          ].map((label) => (
                            <FormControlLabel
                              key={label}
                              value={label}
                              control={
                                <Radio
                                  sx={{ "&.Mui-checked": { color: "#CC9200" } }}
                                  data-test-id="radio"
                                  onChange={(e) => {
                                    setFieldValue("permissionToEnter", e.target.checked);
                                    this.setPermissionToEnter(e.target.checked);
                                  }}
                                />
                              }
                              label={label}
                            />
                          ))}
                    </RadioGroup>
                    {errors.permissionToEnter && (
                      <div className="error">{errors.permissionToEnter}</div>
                    )}
                  </Box>
                  <input className='input' maxLength={100} data-test-id="permissionInputTest" onChange={(e) => {
                              setFieldValue("permissionDescription", e.target.value);
                              this.setPermissionDescription(e.target.value);
                            }}/>
                    {errors.permissionDescription && (
                      <div className="error">{errors.permissionDescription}</div>
                    )}
                </Box>
              </Box>
              <Box className="InputWrapper">
                <Box className="InputWrapperLeft">
                  Documents
                </Box>
                <Box className="InputWrapperRight fileUpload">
                  <FileUpload
                    label=""
                    name="documents"
                    files={this.state.documents}
                    onFilesChange={(name: string, files: FileWithPreview[]) => {
                      this.handleFilesChange(name, files);
                      setFieldValue(name, files); 
                    }}
                    data-test-id="fileUploadTest"
                  />
                  {errors.documents && (
                    <div className="error">{errors.documents}</div>
                  )}
                </Box>
              </Box>
              <Box className="InputWrapper">
                <Box className="InputWrapperLeft">
                  Cost
                </Box>
                <Box className="InputWrapperRight">
                  <FormGroup
                    sx={{
                        flexDirection: { xs: "row", md: "column" },
                        }}
                    data-test-id="costTest"  
                  >
                    {[
                      "I approve that job will cost not more than $500",
                      ].map((label) => (
                        <FormControlLabel
                          key={label}
                          control={
                          <Checkbox
                            name={label}                            
                            onChange={(e) => {
                              setFieldValue("cost", e.target.value);
                              this.setCost(e.target.value);
                            }}
                            sx={{ "&.Mui-checked": { color: "#CC9200" } }}
                            data-test-id="costCheckBoxTest"  
                          />
                          }
                          label={label}
                          data-test-id="costCheckBoxOuterTest"  
                        />
                    ))}
                  </FormGroup>
                </Box>
              </Box>
              <Box className="InputWrapper">
                <Box className="InputWrapperLeft">
                  Description
                </Box>
                <Box style={{display: 'block'}} className="InputWrapperRight">
                  <textarea 
                    className='textArea'
                    maxLength={100}
                    rows={6}
                    onChange={(e) => {
                      setFieldValue("description", e.target.value);
                      this.setDescription(e.target.value);
                    }}
                    data-test-id="descriptionTest"
                  />
                  {errors.description && (
                      <div className="error">{errors.description}</div>
                    )}
                </Box>
              </Box>
              <Box className="InputWrapper">
                <Box className="InputWrapperLeft">
                </Box>
                <Box className="submitBtnWrapper">
                  <Button className="submitBtn" style={{ textTransform: 'none' }} data-test-id="btnSubmit" type="submit">Submit Work Order</Button>
                </Box>
              </Box>
            </form>
            );
          }}
          </Formik>
          </Box>
          <Box className="backgroundImgWrapper">
            <Box className="backgroundImg">
              <img src={require('../assets/hexagon.svg')}/>
            </Box>
            <Box className="backgroundImg">
              <img src={require('../assets/hexagon.svg')}/>
            </Box>
            <Box className="backgroundImg">
              <img src={require('../assets/hexagon.svg')}/>
            </Box>
            <Box className="backgroundImg">
              <img src={require('../assets/hexagon.svg')}/>
            </Box>
          </Box>
        </Box>
      </AddWorkOrderStyle>   
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const AddWorkOrderStyle = styled(Box)({
  display: "flex",
  fontFamily: "Outfit, sans-serif",
  width: "100%",
  "& .rightBox": {
    width: "100%",
    overflow: "hidden",
  },
  "& .heading": {
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '32px',
    marginLeft: "2rem",
    marginTop: "2rem",
    marginRight: "2rem",
    "@media (max-width:1000px)": {
      marginLeft: "0.5rem",
    },
  },
  "& .workOrderForm": {
    width: "90%",
    border: "1px solid #FFE299",
    borderRadius: "8px",
    margin: "2rem",
    padding: "2rem",
    "@media (max-width:1200px)": {
      width: "95%",
      padding: "0rem",
    },
    "@media (max-width:1000px)": {
      margin: "0.5rem",
    },
    "@media (max-width:600px)": {
      width: "90%",
      border: "none",
    }
  },
  "& .select": {
    width: "100%",
    height: "44px",
    border: "1px solid #BAB1AB",
    borderRadius: "8px",
    backgroundColor: "#ffffff",
    paddingLeft: "10px",
  },
  "& .selectBox": {
    width: "322px",
    "@media (max-width:1200px)": {
      width: "48%",
    },
    "@media (max-width:600px)": {
      width: "100%",
    }
  },
  "& .selectLong": {
    width: "670px",
    height: "44px",
    border: "1px solid #BAB1AB",
    borderRadius: "8px",
    backgroundColor: "#ffffff",
    paddingLeft: "10px",
    "@media (max-width:1200px)": {
      width: "100%",
    }
  },
  "& .dateSelect": {
    width: "322px",
    "@media (max-width:1200px)": {
      width: "48%",
    },
    "@media (max-width:600px)": {
      width: "100%",
    }
  },
  "& .InputWrapper": {
    display: "flex",
    padding: "2rem 0rem 2rem 2rem",
    "@media (max-width:600px)": {
      display: "block",
      padding: "1rem 0rem 1rem 0rem",
    }
  },
  "& .InputWrapperLeft": {
    width: "20%",
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "17px",
    color: "#BAB1AB",
    "@media (max-width:600px)": {
      width: "100%",
    }
  },
  "& .InputWrapperRight": {
    display: "flex",
    width: "80%",
    marginRight: "25px",
    "@media (max-width:600px)": {
      width: "100%",
      display: "block",
      marginTop: "1rem",
    }
  },
  "& .input": {
    width: "670px",
    height: "44px",
    border: "1px solid #BAB1AB",
    borderRadius: "8px",
    paddingLeft: "10px",
    "@media (max-width:1200px)": {
      width: "100%",
    }
  },
  "& .radioBtn": {
    accentColor: "#CC9200",
  },
  "& .radioOuterWrapper": {
    width: "80%", 
    marginRight: "25px",
    "@media (max-width:600px)": {
      width: "100%",
    }
  },
  "& .radioWrapper": {
    marginBottom: "1rem",
  },
  "& .textArea": {
    width: "670px",
    border: "1px solid #BAB1AB",
    borderRadius: "8px",
    padding: "10px",
    marginRight: "25px",
    "@media (max-width:1000px)": {
      width: "100%",
    }
  },
  "& .submitBtnWrapper": {
    width: "670px",
    display: "flex",
    justifyContent: "flex-end",
    cursor: "pointer",
    "@media (max-width:600px)": {
      width: "100%",
      justifyContent: "flex-start",
    }
  },
  "& .submitBtn": {
    width: "175px",
    height: "44px",
    backgroundColor: "#FFC123",
    borderRadius: "8px",
    color: "#000000",
    marginRight: "25px",
    cursor: "pointer",
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '24px',
    fontFamily: "Outfit, sans-serif",
    "&:hover": {
      backgroundColor: "#FFD700", 
    },
    "@media (max-width:600px)": {
      marginRight: "0px",
    }
  },
  "& .backgroundImgWrapper": {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "2.5rem",
    "@media (max-width:600px)": {
      display: "none",
    }
  },
  "& .backgroundImg": {
    width: "250px",
  },
  "& .assignVenderCarouselWrapper": {
    "@media (max-width:1000px)": {
      width: "75%",
    },
    "@media (max-width:600px)": {
      width: "100%",
      marginTop: "1rem",
    }
  },
  "& .assignVenderCarousel": {
    display: "flex",
    justifyContent: "space-between",
  },
  "& .carouselBox": {
    marginLeft: "1rem",
    marginRight: "1rem",
    "@media (max-width:1000px)": {
      width: "80%",
    },
    "@media (max-width:600px)": {
      width: "100%",
      marginLeft: "-1rem",
      marginRight: "-1rem",
    }
  },
  "& .carouselText": {
    width: "530px",
    fontFamily: "Outfit, sans-serif",
    "@media (max-width:1000px)": {
      width: "100%",
    }
  },
  "& .assignVenderNavBtn": {
    width: "40px",
    height: "40px",
    backgroundColor: "#FFC123",
    borderRadius: "50%",
    marginTop: "100px",
    cursor: "pointer",
    "@media (max-width:1000px)": {
      marginTop: "70px",
    },
    "@media (max-width:600px)": {
      marginTop: "50px",
      zIndex: "10",
    },
  },
  "& .keaseSelected": {
    color: "#CC9200",
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "17px",
  },
  "& .assignVenderBox1": {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  "& .goBackBtn": {
    width: "80px",
    height: "32px",
    fontWeight: 700,
    backgroundColor: "#FFC123",
    borderRadius: "8px",
    color: "#000000", 
    fontSize: "14px",
    padding: "0px",
    letterSpacing: 0,
    cursor: "pointer",
    fontFamily: "Outfit, sans-serif",
    "&:hover": {
      backgroundColor: "#FFD700", 
    },
  },
  "& .chooseVenderBtn": {
    width: "118px",
    height: "32px",
    fontWeight: 700,
    backgroundColor: "#FFC123",
    borderRadius: "8px",
    color: "#000000", 
    fontSize: "14px",
    padding: "0px",
    letterSpacing: 0,
    cursor: "pointer",
    fontFamily: "Outfit, sans-serif",
    "&:hover": {
      backgroundColor: "#FFD700", 
    },
  },
  "& .carouselImgWrapper": {
    width: "530px",
    height: "224px",
    backgroundImage: `url(${require('../assets/vendor.svg')})`,
    backgroundSize: 'cover', 
    backgroundPosition: 'center',
    borderRadius: "18px",
    "@media (max-width:1000px)": {
      width: "100%",
    }
  },
  "& .carouselImg": {
    borderRadius: "18px",
    width: "530px",
    height: "224px",
    "@media (max-width:1000px)": {
      width: "100%",
    }
  },
  "& .frequencyWrapper": {
    display: "flex",
    justifyContent: "flex-end",
    width: "670px",
    "@media (max-width:600px)": {
      width: "100%",
    }
  },
  "& .frequency": {
    width: "100px",
    height: "30px",
    border: "none",
    borderRadius: "8px",
    paddingLeft: "10px",
    backgroundColor: "#FFF1CC",
    "@media (max-width:1000px)": {
      marginRight: "25px",
    }
  },
  "& .marginTop": {
    "@media (max-width:600px)": {
      marginTop: "1rem",
    }
  },
  "& .fileUpload": {
    display: "block",
    width: "670px",
    marginRight: "25px",
    "@media (max-width:600px)": {
      width: "100%",
    }
  },
  "& .error": {
    color: "#ff0000",
    fontSize: "12px",
    marginTop: '3px',
  }
});
// Customizable Area End
