import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import moment, { Moment } from "moment";

import storage from "../../../framework/src/StorageProvider";

export interface WorkOrderDetails {
    id: number;
    work_needed: string;
    permission_to_enter: boolean;
    permission_description: null;
    availability: null;
    cost: boolean;
    description: null;
    status: string;
    created_at: string;
    updated_at: string;
    created_by: null;
    category: null;
    assigned_vendor: string;
    property: null;
    due_date_date: string;
    due_date_time: string;
    remind_me_date: string;
    remind_me_time: string;
    vendor_account_id: number;
    property_name: string;
    documents: Array<WorkDescriptions>;
    work_order_audits: Array<Updates>;
  }

export interface WorkDescriptions {
  id: number;
  file_name: string;
  file_url: string;
}

export interface Updates {
  date: string;
  updated_by: string;
  description: string;
  cost: false;
  status: string;
}
export interface Property {
  name: string;
  units: string[];
}

export interface InvalidResponseType {
  errors: string;
}

export interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  token?: string;
  body?: object;
  type?: string;
}

export interface Properties {
  data: Array<PropertiesDetails>;
}

export interface PropertiesDetails {
  id: string;
  type: string;
  attributes: {
    property_type: string;
    property_name: string;
    address: string;
    units: Array<Units>;
  }
}

export interface Units {
  id: number;
  unit_name: string;
  status: string;
  tenants: [];
  landlord_work_orders: Array<WorkOrderDetails>;
}

export interface ApprovedDetails {
  message: string;
}

// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  available_date: Moment;
  start_time: Moment | null;
  end_time: Moment | null;
  id: string | number;
  token: string;
 
  showPicker: boolean;
  activeOrderItem: string;
  openWorkOrder: boolean;
  expanded: number | null;
  selected: {[unitId: number]: number[]};
  workOrderData: Array<WorkOrderDetails>;
  anchorEl: null | HTMLElement;
  selectedProperty: PropertiesDetails | null;
  selectedUnit: string | null;
  subMenuAnchorEl: null | HTMLElement;
  properties: PropertiesDetails[];
  selectedMenuItemIndex: null | number;
  approveDetails:  string[];
  openApprove:{[key: number]: boolean };
  selectedApprove: {[unitId: number]: string};
  selecteValueApprove: {[unitId: number]: string};
  anchorElement: { [key: number]: HTMLElement | null };
  showAllItems: boolean;
  itemsToShow: number;
  sortColumn: string;
  sortDirection: "asc" | "desc";
  anchorNewElement: { [key: number]: HTMLElement | null };
  sortState: {
    [key: number]: {
      sortColumn: string;
      sortDirection: "asc" | "desc";
    };
  },
  reloadLoader: boolean;
  messagePopup: boolean;
  messageForPopup: string;
  activeMainNewrequestdash:string;
  openTenentDrawersdash:boolean
  // Customizable Area End
}

export interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ServicerequestController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAppointmentsListApiCallId?: string;
  getWorkOrderApiCallId: string = "";
  getPropertiesApiCallId: string = "";
  postApprovedApiCallId: string = "";
  postWaitingApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    let endTime = new Date();
    endTime.setMinutes(endTime.getMinutes() + 30);

    this.state = {
      // Customizable Area Start
      id: 0,
      start_time: moment(new Date()),
      end_time: moment(endTime),
      available_date: moment(new Date()),
      // appointmentsList: [],
      token: "",
      showPicker: false,
      activeOrderItem: configJSON.worksOrderlabel,
      openWorkOrder: false,
      expanded: null,
      selected: {},
      workOrderData: [],
      anchorEl: null,
      selectedProperty: null,
      selectedUnit: null,
      subMenuAnchorEl: null,
      selectedMenuItemIndex: null,
      properties: [],
      approveDetails: ["Assign a vendor","Complete Work", "Decline"],
      openApprove: {},
      selectedApprove: {},
      selecteValueApprove: {},
      anchorElement: {},
      showAllItems: false,
      itemsToShow: 4,
      sortColumn: "id",
      sortDirection: "asc",
      anchorNewElement: {},
      sortState: {},
      reloadLoader: false,
      messagePopup: false,
      messageForPopup: "",
      activeMainNewrequestdash: "landlordproperties",
      openTenentDrawersdash:false
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    await super.componentDidMount();
    this.getToken();
    if (!this.isPlatformWeb()) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    
    // Customizable Area End
  }

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  receive = async (from: String, message: Message) => {
    // Customizable Area Start
    runEngine.debugLog("Message Received", message);

  
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getAppointmentsListApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors && responseJson.data) {
        this.setState({
          // appointmentsList: responseJson.data.attributes.time_slots,
        });
      } else {
        const errorResponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        this.parseApiCatchErrorResponse(errorResponse);

       
      }
    }
   
    // Customizable Area End
  };

  // Customizable Area Start


  update(value: Partial<{ [K in keyof S]: S[K] }>) {
    this.setState((state) => ({ ...state, ...value, showPicker: false }));
  }

  getDate(value: string, format: string) {
    return moment(value).format(format);
  }

  toMomentDate(value?: string | Date | null, format?: string) {
    return moment(value, format);
  }

  toDateFromMoment(value: Moment) {
    return value.toDate();
  }

  navigateToAddAppointment() {
    // Merge Engine - Navigation - Start
    const navigationMessage = new Message(getName(MessageEnum.NavigationMessage));
    navigationMessage.addData(getName(MessageEnum.NavigationTargetMessage), "AddAppointment");
    navigationMessage.addData(
      getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigationMessage);
    // Merge Engine - Navigation - End
  }
  
  handleExpandClick = (rowId: number) => {
    this.setState((prevState) => ({
      expanded: prevState.expanded === rowId ? null : rowId
    }));
  };
  
  handleMainItemClicknewrequest = (labelKey: string) => {
    this.setState({ activeMainNewrequestdash: labelKey });
  };


  handleMenuClosesd = (rowId: number) => {
    this.setState({
      anchorNewElement: {
        ...this.state.anchorNewElement,
        [rowId]: null
      },
    });
  };


 




  handleChangeColor = (itemIndex: number) =>{
    return this.state.selectedMenuItemIndex === itemIndex ? "changeColor" : "";
  };

  
  handleTenentrequestDrawers = () => {
    this.setState({
      openTenentDrawersdash: !this.state.openTenentDrawersdash
    });
  };
 
  // Customizable Area End
}
