import React from "react";

// Customizable Area Start
import {
    Container,
    Button,
    Grid,
    TextField,
    Box,
    Typography,
    Paper,
    InputAdornment
  } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import PopupMessage from "../../../components/src/PopupMessage.web";
import NavigationSidebar from "../../../components/src/NavigationSidebar.web";
export const keasyIcon = require("../../customform/assets/keasylogo.svg");
export const dashboardLandLogo = require("../../customform/assets/dashboard.png");
export const activeMainDashboard = require("../../customform/assets/activeDashboard.png");
export const accountsMain = require("../../customform/assets/profile.png");
export const activeMainAccount = require("../../customform/assets/activeProfile.png");
export const ordersMain = require("../../customform/assets/order.png");
export const activeMainOrder = require("../../customform/assets/activeorder.png");
export const vendorMain = require("../../customform/assets/vendor.png");
export const activeMainVendor = require("../../customform/assets/activeSave.png");
export const remindersMain = require("../../customform/assets/reminder.png");
export const activeMainReminder = require("../../customform/assets/activeReminder.png");
export const propertyMain = require("../../customform/assets/property.png");
export const activePropertyMain = require("../../customform/assets/activeProperty.png");
export const financialMain = require("../../customform/assets/financial.png");
export const activeMainFinancial = require("../../customform/assets/activeFinancial.png");
export const reportMain = require("../../customform/assets/report.png");
export const activeMainReport = require("../../customform/assets/activeReport.png");
export const keyMainChain = require("../../customform/assets/keyChain.png");
export const activeKeyMain = require("../../customform/assets/activeKey.png");
export const settingsMain = require("../../customform/assets/settings.png");
export const activeMainSetting = require("../../customform/assets/activeSetting.png");
export const projects = require("../../customform/assets/projects.png");
export const activeProjects = require("../../customform/assets/activeprojects.png");
export const prefferedVendor = require("../../customform/assets/PrefferedVendors.png");
export const bottomImageIcon = require("../../user-profile-basic/assets/bottomImage.svg");
import CustomDatePicker from "../../../components/src/CustomDatePicker.web";
// Customizable Area End

import NewTransactionController, { Props, configJSON } from "./NewTransactionController";

export default class NewTransaction extends NewTransactionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Grid container direction="row" wrap="nowrap" 
          sx={{ flexDirection: {xs: "column", sm: "column", md: "row"}}}
      >
        <PopupMessage
          open={this.state.messageOpen}
          type={this.state.messageType}
          message={this.state.message}
          handleClose={this.handlePopupMessageClose}
        />
        <Grid item>
          <NavigationSidebar
              data-test-id={"navId"}
              activeItem={this.state.activeVendorItem}
              sidebarItems={[
                { label: configJSON.dashboardOrder, labelKey: configJSON.dashboardOrderlabel, icon: dashboardLandLogo, activeIcon: activeMainDashboard, pathName: configJSON.dashboardOrderlabel },
                { label: configJSON.myAccountOrder, labelKey: configJSON.settingOrderlabel, icon: accountsMain, activeIcon: activeMainAccount, pathName: configJSON.myAccountOrderabel },
                { label: configJSON.workOrder, labelKey: configJSON.worksOrderlabel, icon: ordersMain, activeIcon: activeMainOrder, pathName: configJSON.worksOrderlabel },
                { label: configJSON.preferredOrder, labelKey: configJSON.prevendorOrderlabel, icon: vendorMain, activeIcon: activeMainVendor, pathName: configJSON.prevendorOrderlabel },
                { label: configJSON.remindersOrder, labelKey: configJSON.reminOrderlabel, icon: remindersMain, activeIcon: activeMainReminder, pathName: configJSON.reminOrderlabel },
                { label: configJSON.mypropOrder, labelKey: configJSON.mypropOrderlabel, icon: propertyMain, activeIcon: activePropertyMain, pathName: configJSON.mypropOrderlabel },
                { label: configJSON.financiaOrder, labelKey: configJSON.financOrderlabel, icon: financialMain, activeIcon: activeMainReport, pathName: configJSON.financOrderlabel },
                { label: configJSON.reportOrder, labelKey: configJSON.reportOrderlabel, icon: reportMain, activeIcon: activeMainReport, pathName: configJSON.reportOrderlabel },
                { label: configJSON.chatOrder, labelKey: configJSON.chatOrderlabel, icon: keyMainChain, activeIcon: activeKeyMain, pathName: configJSON.chatOrderlabel },
                { label: configJSON.settingOrder, labelKey: configJSON.settings, icon: settingsMain, activeIcon: activeMainSetting, pathName: configJSON.settings }
              ]}
              onClickSidebar={this.handleVendoritemClick}
              keasylogo={keasyIcon}
              openDrawer={this.state.openVendorDrawer}
              onClickDrawer={this.handleVendorDrawer}
          />
        </Grid>
        <Grid item sx={webStyles.mainContent}>
        <Container maxWidth={false} sx={{ position: "relative", paddingBottom: "100px" }}
          >
              <Typography sx={webStyles.vendor_profile_header} paragraph>
                  New Transaction
              </Typography>
              
              <Paper elevation={3} sx={webStyles.contentPaper}>
                  <Grid container sx={webStyles.container}>
                    
                      <Box sx={webStyles.form_style} >
                        <form id="transactionForm" onSubmit={this.handleFormSubmit}>
                        <Grid container>
                          <Grid item md={4} xs={12} sx={{paddingTop: "15px", marginBottom: "20px"}}>
                            <Typography sx={webStyles.input_label}>Property</Typography>
                          </Grid>
                          <Grid item md={8} xs={12} sx={{marginBottom: "35px"}}>
                            <TextField
                              required
                              id="property"
                              name="property"
                              fullWidth
                              select
                              SelectProps={{ native: true }}
                              variant="outlined"
                              size="small"
                              value={this.state.property}
                              onChange={this.handleInputChange}
                            >
                              <option value="">Please Select</option>
                              {this.state.allProperties?.map((property) => (
                                <option key={property.unit_id} value={property.name}>{property.name}</option>
                              ))}
                            </TextField>
                          </Grid>
                        </Grid>

                        <Grid container>
                          <Grid item md={4} xs={12} sx={{paddingTop: "15px", marginBottom: "20px"}}>
                            <Typography sx={webStyles.input_label}>Ledger Type</Typography>
                          </Grid>
                          <Grid item md={8} xs={12} sx={{marginBottom: "35px"}}>
                            <TextField
                              required
                              id="ledgerType"
                              name="ledgerType"
                              fullWidth
                              select
                              SelectProps={{ native: true }}
                              variant="outlined"
                              size="small"
                              value={this.state.ledgerType}
                              onChange={this.handleInputChange}
                            >
                              <option value="">Please Select</option>
                              {this.state.allLedgerTypes?.map((ledgerType) => (
                                <option key={ledgerType} value={ledgerType}>{ledgerType}</option>
                              ))}
                            </TextField>
                          </Grid>
                        </Grid>

                        <Grid container>
                          <Grid item md={4} xs={12} sx={{paddingTop: "15px", marginBottom: "20px"}}>
                            <Typography sx={webStyles.input_label}>Contact</Typography>
                          </Grid>
                          <Grid item md={8} xs={12} sx={{marginBottom: "35px"}}>
                            <TextField
                              required
                              id="contact"
                              name="contact"
                              fullWidth
                              select
                              SelectProps={{ native: true }}
                              variant="outlined"
                              size="small"
                              value={this.state.contact}
                              onChange={this.handleInputChange}
                            >
                              <option value="">Please Select</option>
                              {this.state.allContacts?.map((contact) => (
                                <option key={contact.id} value={contact.id}>{contact.name}</option>
                              ))}
                            </TextField>
                          </Grid>
                        </Grid>

                        <Grid container>
                          <Grid item md={4} xs={12} sx={{paddingTop: "15px", marginBottom: "20px"}}>
                            <Typography sx={webStyles.input_label}>Amount</Typography>
                          </Grid>
                          <Grid item md={8} xs={12} sx={{marginBottom: "35px"}}>
                          <TextField
                            required
                            id="amount"
                            name="amount"
                            variant="outlined"
                            size="small"
                            fullWidth
                            InputProps={{
                              startAdornment: <InputAdornment position="start">$</InputAdornment>
                            }}
                            placeholder="0.00"
                            error={this.state.errors.amount}
                            helperText={this.getHelperText("amount")}
                            value={this.state.amount}
                            onChange={this.handleInputChange}
                          />
                          </Grid>
                        </Grid>

                        <Grid container>
                          <Grid item md={4} xs={12} sx={{paddingTop: "15px", marginBottom: "20px"}}>
                            <Typography sx={webStyles.input_label}>Reference number</Typography>
                          </Grid>
                          <Grid item md={8} xs={12} sx={{marginBottom: "35px"}}>
                            <TextField
                              required
                              id="refNumber"
                              name="refNumber"
                              fullWidth
                              variant="outlined"
                              size="small"
                              error={this.state.errors.refNumber}
                              helperText={this.getHelperText("refNumber")}
                              value={this.state.refNumber}
                              onChange={this.handleInputChange}
                            />
                          </Grid>
                        </Grid>

                        <Grid container>
                          <Grid item md={4} xs={12} sx={{paddingTop: "15px", marginBottom: "20px"}}>
                            <Typography sx={webStyles.input_label}>Date</Typography>
                          </Grid>
                          <Grid item md={8} xs={12} sx={{marginBottom: "35px"}}>
                            <CustomDatePicker
                              onChange={
                                this.handleDateChange("date")
                              }
                              required={true}
                              value = {this.state.date}>
                            </CustomDatePicker>
                          </Grid>
                        </Grid>

                        <Grid container>
                          <Grid item md={4} xs={12} sx={{paddingTop: "15px", marginBottom: "20px"}}>
                            <Typography sx={webStyles.input_label}>Details</Typography>
                          </Grid>
                          <Grid item md={8} xs={12} sx={{marginBottom: "35px"}}>
                            <TextField
                              id="details"
                              name="details"
                              multiline
                              rows={3}
                              sx={{display: "flex"}}
                              variant="outlined"
                              helperText={
                                <span style={{ float: "right" }}>
                                  {this.state.details.length}/100
                                </span>
                              }
                              inputProps={{ maxLength: 100 }}
                              value={this.state.details}
                              onChange={this.handleInputChange}
                            />
                          </Grid>
                        </Grid>
                        <Box display={"flex"} justifyContent={"end"} sx={{ padding: "10px" }}>
                              <Button
                              size="medium"
                              id="submit"
                              type="submit"
                              sx={webStyles.form_button}
                              >
                                Submit
                              </Button>
                          </Box>
                          </form>
                      </Box>
                    
                  </Grid>
              </Paper>
              <img
                  src={bottomImageIcon}
                  alt="Description"
                  style={{
                      position: "absolute",
                      bottom: "-15px",
                      right: "0"
                  }}
              />
          </Container>
        </Grid>
      </Grid>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const webStyles = {
    vendor_profile_header: {
      fontFamily: "Outfit, sans-serif",
      fontSize: "30px",
      fontWeight: 600,
      lineHeight: "40px",
      letterSpacing: "-0.005em",
      marginBottom: "35px",
    },
    contentPaper: {
      border: "2px solid #FFF1CC",
      borderRadius: "8px",
      padding: "50px",
      boxShadow: "unset",
    },
    mainContent: {
      flexGrow: 1,
      "@media (min-width: 992px)": {
          borderLeft: "1px solid rgb(208,203,199)",
      }
    },
    container: {
      padding: "20px",
      "@media (max-width: 600px)": {
        flexDirection: "column",
      },
    },
    input_label: {
      fontFamily: "Outfit, sans-serif",
      fontSize: "18px",
      fontWeight: 700,
      lineHeight: "17px",
      color: "#BAB1AB",
    },
    form_button: {
        backgroundColor: "#FFC123",
        fontFamily: "Outfit, sans-serif",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        textTransform: "capitalize",
        color: "#000000",
        marginTop: "72px",
        height: "44px",
        width: "184px",
        "&:hover": {
          backgroundColor: "#FFC123",
        },
        "&.Mui-disabled": {
          backgroundColor: "#FFF1CC",
        },
      },
      form_style: {
        width: "80%",
        "@media (max-width: 600px)": {
            width: "100%",
          },
      }
  };
// Customizable Area End