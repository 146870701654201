export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgVisbility = require("../assets/visibility.svg");
export const imgVisbilityOff = require("../assets/visibilityOff.svg");



export const dashboard_g = require("../assets/dashboard.png");
export const bell_b = require("../assets/bell.png");
export const Home_h = require("../assets/home.png");
export const financial_f = require("../assets/financial.png");
export const Key_k = require("../assets/image_key_on_24px.png");
export const Key_logo = require("../assets/key.png");








export const keasIcon = require("../assets/keasylogo.svg");
export const dashboardOrderLogo = require("../assets/dashboard.png");
export const activeOrderDashboard = require("../assets/activeDashboard.png");
export const accountsOrder = require("../assets/profile.png");
export const activeOrderAccount = require("../assets/activeProfile.png");
export const ordersIcon = require("../assets/order.png");
export const activeOrderIcon = require("../assets/activeorder.png");
export const vendorOrder = require("../assets/vendor.png");
export const activeOrderVendor = require("../assets/activeSave.png");
export const remindersOrder = require("../assets/reminder.png");
export const activeOrderReminder = require("../assets/activeReminder.png");
export const propertyOrder = require("../assets/property.png");
export const activePropertyOrder = require("../assets/activeProperty.png");
export const financialOrder = require("../assets/financial.png");
export const activeOrderFinancial = require("../assets/activeFinancial.png");
export const reportOrder = require("../assets/report.png");
export const activeOrderReport = require("../assets/activeReport.png");
export const keyOrderChain = require("../assets/keyChain.png");
export const activeKeyOrder = require("../assets/activeKey.png");
export const settingsOrder = require("../assets/settings.png");
export const activeOrderSetting = require("../assets/activeSetting.png");
export const searchIcon = require("../assets/searchIcon.svg");
export const filterIcon = require("../assets/filter.svg");
export const plusIcon = require("../assets/plus.svg");
export const scheduledDate = require("../assets/scheduleDate.svg");
export const waitingBill = require("../assets/waitingBill.svg");
export const findKeys = require("../assets/findKeys.svg");
export const editIcon = require("../assets/editor.svg");
export const workIcon = require("../assets/work.svg");
export const transferIcon = require("../assets/transfer.svg");
export const declineIcon = require("../assets/decline.svg");
export const doneIcon = require("../assets/done.svg");
export const hexagon = require("../assets/hexagon.svg");
export const halfHexagon = require("../assets/halfHexagon.svg");

