import React from "react";

import {
    Container,
    Box,
    Grid,
    Button,
    
    Typography,
    // Customizable Area Start
    StepLabel,
    Stepper,
    Step,
    Divider,
    FormControl,
    MenuItem,
    TextField,
    RadioGroup,
    FormControlLabel,
    FormGroup,
    FormLabel,
    TextareaAutosize,
    styled,
    Select,
    IconButton,
    Chip
    // Customizable Area End
} from "@mui/material";

// Customizable Area Start
import {
    createTheme,
    ThemeProvider,
  
} from "@mui/material/styles";
import NavigationSidebar from "../../../components/src/NavigationSidebar.web";
import {AddCircleOutline, Close} from '@mui/icons-material';
import { activeMainDashboard, activeMainSetting, activeMainAccount, activeMainOrder, activeMainVendor, activeMainReminder, activePropertyMain, activeMainFinancial, activeMainReport, keyMainChain, activeKeyMain, settingsMain, keasyIcon, accountsMain, dashboardLandLogo, financialMain, ordersMain, propertyMain, remindersMain, reportMain, vendorMain, bottomImageIcon } from "./assets";
import PopupMessage from "../../../components/src/PopupMessage.web";
import FileUpload from "../../accountgroups/src/FileUpload";
import { FileWithPreview } from "./VendorEditDashboardController.web";
import CustomDatePicker from "../../../components/src/CustomDatePicker.web";
import { CustomRadioButton } from "../../../components/src/CustomRadioButton.web";
const getStepsedit = () => {
    return ['Contact information', 'Taxes and payments', 'Compliance'];
}
const theme = createTheme({
    palette: {
        primary: {
            main: "#6200ee",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});

// Customizable Area End

import VendorEditDashboardController, {
    Props,
    configJSON,

} from "./VendorEditDashboardController.web";

export default class VendorDashboardEdit extends VendorEditDashboardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
  renderDividerEnrolfirst() {
    return (
      <>
        <Grid container justifyContent="flex-end" sx={{ marginTop: "3rem" }}>
          <Grid item md={12}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Divider sx={{ width: '80%' }} />
            </Box>
          </Grid>
        </Grid>
      </>
    )
  }

  getStepContentEdit(activeStep: number){
    const {
      vendorType, ownerName, vendorTrade,
      taxpayerName, taxpayerID, send1099,
      workersCompExpiration, generalLiabilityExpiration, epaCertificationExpiration,
      autoInsuranceExpiration, stateLicenseExpiration, contractExpiration,
      email,
      address,
      expirationDate1,
      expirationDate2,
      notes,
      useOnlinePayables,
      paymentType,
      vendorBankRoutingNumber,
      vendorBankAccountNumber,
    } = this.state;

    return(
        <ThemeProvider theme={theme}>
            <Box sx={{ width: "100%" }}>
                {activeStep === 0 && (
                    <Box>
                        <Grid container padding= "2rem">
                            <Grid item md={3} xs={12}>
                                <Box>
                                    <Typography style={webStyle.labeltextsyle2}>{configJSON.vendorInformation}</Typography>
                                </Box>
                            </Grid>
                            <Grid item md={7} xs={12}>
                                <Box style={webStyle.marginBorderstyle}>
                                    <label style={webStyle.labeltextsyle}>{configJSON.vendorType}</label>
                                    <FormControl fullWidth sx={webStyle.marginManage}>
                                        <Select
                                            data-test-id = "vendorTypeId"
                                            labelId="vendor-type-label"
                                            name="vendorType"
                                            value={vendorType}
                                            onChange={this.handleVendorType}
                                            >
                                            {this.state.vendorsItem.map((vendorTypes: string, vendorIndex: number)=>{
                                                return(
                                                    <MenuItem key={vendorIndex} value={vendorTypes}>{vendorTypes}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box style={webStyle.marginBorderstyle}>
                                    <label style={webStyle.labeltextsyle}>{configJSON.ownerName}</label>
                                    <TextField
                                        data-test-id = "ownerNameId"
                                        variant="outlined"
                                        fullWidth
                                        name="ownerName"
                                        value={ownerName}
                                        onChange={this.handleChange}
                                        sx={webStyle.marginManage}
                                        error={this.state.errors.ownerName}
                                        helperText={this.getHelperText("ownerName")}
                                    />
                                </Box>
                                <Box style={webStyle.marginBorderstyle}>
                                    <FormLabel htmlFor="vendorTrade" sx={webStyle.input_label}>
                                        {configJSON.venTrade}
                                    </FormLabel>
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        sx={{
                                            border: 1,
                                            borderColor: "grey.400",
                                            borderRadius: 1,
                                            "&:hover": {
                                                borderColor: "black",
                                            },
                                        }}
                                    >
                                        <TextField
                                            data-test-id="vendorTradeId"
                                            name="vendorTrade"
                                            fullWidth
                                            placeholder="Separate with comma"
                                            size="medium"
                                            value={this.state.trades}
                                            onChange={this.handleTradeInput}
                                            onKeyPress={this.handleKeyPress}
                                            sx={{
                                                flex: 1,
                                                mr: 1,
                                                "& fieldset": { border: "none" },
                                            }}
                                        />
                                        <IconButton
                                            data-test-id="addIconButtonId"
                                            onClick={this.handleAddTrade}
                                            sx={{ color: this.state.trades !== "" ? "black" : "#BAB1AB" }}
                                        >
                                            <AddCircleOutline />
                                        </IconButton>
                                    </Box>
                                    <Box mt={2}>
                                        {vendorTrade.map((trade: string, index: number) => (
                                            <Chip
                                                key={index}
                                                label={trade}
                                                variant="outlined"
                                                icon={
                                                    <IconButton
                                                        data-test-id="deleteTradeId"
                                                        aria-label="delete"
                                                        size="small"
                                                        onClick={() => this.handleDelete(trade)}
                                                        sx={{ mr: -1 }}
                                                    >
                                                        <Close fontSize="small" />
                                                    </IconButton>
                                                }
                                                sx={{ mr: 1, mb: 1, border: 2, borderRadius: 1 }}
                                            />
                                        ))}
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                        {this.renderDividerEnrolfirst()}
                        <Grid container  padding= "2rem" marginTop= "1rem">
                            <Grid item md={3} xs={12}>
                                <Box>
                                    <Typography style={webStyle.labeltextsyle2}>{configJSON.contact}</Typography>
                                </Box>
                            </Grid>
                            <Grid item md={7} xs={12}>
                            <Grid container spacing={2}>
                                    <Grid item xs={5} sm={3}>
                                        <FormControl fullWidth>
                                        <label style={webStyle.textstylechange12}>{configJSON.areaCode}</label>
                                            <Select
                                                required
                                                data-test-id="countryCodeId"
                                                name="countryCode"
                                                variant="outlined"
                                                size="medium"
                                                value={this.state.areaCode}
                                                onClose={() => { this.setState({ isCountryDropdown: false }) }}
                                                onChange={this.handleCountryChange}
                                                onOpen={this.handleCountryCodeDropdown}
                                                sx={{
                                                    border: "1px solid #e0e0e0",
                                                    borderRadius: "4px",
                                                }}
                                            >
                                                <MenuItem value="">Select a country</MenuItem>
                                                {this.state.countriesCode?.map((country) => (
                                                    <MenuItem key={country.name} value={country.country_code}>
                                                        <span style={{ display: this.state.isCountryDropdown ? "block" : "none" }}>
                                                            {country.emoji_flag} {country.name} (+{country.country_code})
                                                        </span>
                                                        <span style={{ display: this.state.isCountryDropdown ? "none" : "block" }}>+{country.country_code}</span>
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={9}>
                                    <label style={webStyle.textstylechange12}>{configJSON.phoneNumber}</label>
                                        <TextField
                                            required
                                            data-test-id="phoneId"
                                            name="phoneNumber"
                                            fullWidth
                                            variant="outlined"
                                            size="medium"
                                            error={this.state.errors.phone}
                                            helperText={this.getHelperText("phoneNumber")}
                                            value={this.state.phoneNumber}
                                            onChange={this.handleChange}
                                        />
                                    </Grid>
                                </Grid>
                                <Box style={webStyle.marginBorderstyle}>
                                    <label style={webStyle.labeltextsyle}>{configJSON.email}</label>
                                    <TextField
                                        data-test-id="emailId"
                                        variant="outlined"
                                        fullWidth
                                        name="email"
                                        value={email}
                                        onChange={this.handleChange}
                                        sx={webStyle.marginManage}
                                        error={this.state.errors.email}
                                        helperText={this.getHelperText("email")}
                                    />
                                </Box>

                                <Box style={webStyle.marginBorderstyle}>
                                    <label style={webStyle.labeltextsyle}>{configJSON.address}</label>
                                    <TextField
                                        data-test-id="addressId"
                                        variant="outlined"
                                        fullWidth
                                        name="address"
                                        value={address}
                                        onChange={this.handleChange}
                                        sx={webStyle.marginManage}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                )}

                {activeStep === 1 && (
                    <Box>
                        <Box display= "flex">
                            <Grid container padding= "2rem">
                                <Grid item md={3} xs={12}>
                                    <Box>
                                        <Typography style={webStyle.Marketingtext}>{configJSON.federalTax}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item md={7} xs={12}>
                                    <Box>
                                        <label style={webStyle.textstylechange}>{configJSON.taxpayer}</label>
                                        <TextField
                                            data-test-id="taxpayerNameId"
                                            variant="outlined"
                                            fullWidth
                                            sx={webStyle.textfieldmanage}
                                            name="taxpayerName"
                                            value={taxpayerName}
                                            onChange={this.handleChange}
                                            error={this.state.errors.taxpayerName}
                                            helperText={this.getHelperText("taxpayerName")}
                                        />
                                    </Box>
                                    <Box>
                                        <label style={webStyle.textstylechange}>{configJSON.taxpayerID}</label>
                                        <TextField
                                            data-test-id="taxpayersId"
                                            variant="outlined"
                                            fullWidth
                                            sx={webStyle.textfieldmanage}
                                            name="taxpayerID"
                                            value={taxpayerID}
                                            onChange={this.handleChange}
                                            error={this.state.errors.taxpayerId}
                                            helperText={this.getHelperText("taxpayerID")}
                                        />
                                    </Box>

                                    <Grid container marginTop= "3rem" justifyContent="center" alignItems="center">
                                        <Grid item md={12} xs={12}>
                                            <Box>
                                                <FormControl sx={{ marginBottom: "16px" }}>
                                                    <FormLabel htmlFor="send"
                                                        sx={{
                                                            ...webStyle.input_label,
                                                            '&.Mui-focused': {
                                                                color: 'rgba(0, 0, 0, 0.6)',
                                                            },
                                                        }}
                                                    >
                                                        {configJSON.send1099}
                                                    </FormLabel>
                                                    <RadioGroup
                                                        data-test-id="sendId"
                                                        defaultValue={send1099}
                                                        aria-labelledby="send1099"
                                                        name="send1099"
                                                        onChange={this.handleRadioChange}
                                                    >
                                                        <FormControlLabel
                                                            value="yes"
                                                            id="yes"
                                                            control={<CustomRadioButton />}
                                                            label="Yes"
                                                        />
                                                        <FormControlLabel id="no" data-test-id="no-radio" value="no" control={<CustomRadioButton />} label="No" />
                                                    </RadioGroup>
                                                </FormControl>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid container marginTop= "3rem"justifyContent="center" alignItems="center">
                                        <Grid item md={12} xs={12}>
                                            <Box>
                                                <label style={webStyle.labeltextsyles12}>{configJSON.formW}</label>
                                            </Box>
                                            <Box>
                                                <FileUpload
                                                    label=""
                                                    name="documents"
                                                    files={this.state.filesDocuments}
                                                    onFilesChange={(names: string, files: FileWithPreview[]) => {
                                                        this.handleFileDocChange(names, files);
                                                    }}
                                                    data-test-id="fileUploadTestId"
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>

                        {this.renderDividerEnrolfirst()}

                        <Box>
                            <Grid container padding= "2rem">
                                <Grid item md={3} xs={12}>
                                    <Box>
                                        <Typography style={webStyle.Marketingtext}>{configJSON.onlinePayable}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item md={7} xs={12}>
                                    <Grid container justifyContent="center" alignItems="center">
                                        <Grid item md={12} xs={12}>
                                            <Box>
                                                <FormControl sx={{ marginBottom: "16px" }}>
                                                    <FormLabel htmlFor="send"
                                                        sx={{
                                                            ...webStyle.input_label,
                                                            '&.Mui-focused': {
                                                                color: 'rgba(0, 0, 0, 0.6)',
                                                            },
                                                        }}
                                                    >
                                                        {configJSON.useOnline}
                                                    </FormLabel>
                                                    <RadioGroup
                                                        defaultValue={useOnlinePayables}
                                                        aria-labelledby="useOnlinePayables"
                                                        name="useOnlinePayables"
                                                        onChange={this.handleChange}
                                                    >
                                                        <FormControlLabel
                                                            value="yes"
                                                            id="yes"
                                                            control={<CustomRadioButton />}
                                                            label="Yes"
                                                        />
                                                        <FormControlLabel id="no" data-test-id="no-radio" value="no" control={<CustomRadioButton />} label="No" />
                                                    </RadioGroup>
                                                </FormControl>
                                            </Box>
                                        </Grid>
                                    </Grid>

                                    <Box sx={webStyle.marginBorderstylethree}>
                                        <label style={webStyle.labeltextsylethree}>{configJSON.paymentType}</label>
                                        <FormControl fullWidth sx={webStyle.marginManagethree}>
                                            <Select
                                                data-test-id = "paymentId"
                                                labelId="payment-type-label"
                                                name="paymentType"
                                                value={paymentType}
                                                onChange={this.handlePaymentType}
                                            >
                                                {this.state.payments.map((payment: string, payIndex: number)=>{
                                                    return(
                                                        <MenuItem key={payIndex} value={payment}>{payment}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Box>

                                    <Box sx={webStyle.marginBorderstylethree}>
                                        <label style={webStyle.textstylechange}>{configJSON.routingNumber}</label>
                                        <TextField
                                            data-test-id = "vendorBankRoutingNumberId"
                                            variant="outlined"
                                            fullWidth
                                            sx={webStyle.textfieldmanage}
                                            name="vendorBankRoutingNumber"
                                            value={vendorBankRoutingNumber}
                                            onChange={this.handleChange}
                                            error={this.state.errors.bankRoutingNumber}
                                            helperText={this.getHelperText("vendorBankRoutingNumber")}
                                        />
                                    </Box>

                                    <Box sx={webStyle.marginBorderstylethree}>
                                        <label style={webStyle.textstylechange}>{configJSON.accountNumber}</label>
                                        <TextField
                                            data-test-id="accountNumberId"
                                            variant="outlined"
                                            fullWidth
                                            sx={webStyle.textfieldmanage}
                                            name="vendorBankAccountNumber"
                                            value={vendorBankAccountNumber}
                                            onChange={this.handleChange}
                                            error={this.state.errors.bankAccountNumber}
                                            helperText={this.getHelperText("vendorBankAccountNumber")}
                                        />
                                    </Box>
                                    <Grid container marginTop= "3rem" justifyContent="center" alignItems="center">
                                        <Grid item md={12} xs={12}>
                                            <Box>
                                                <FileUpload
                                                    label=""
                                                    name="documents"
                                                    files={this.state.filesAccountType}
                                                    onFilesChange={(names: string, files: FileWithPreview[]) => {
                                                        this.handleAccFileChange(names, files);
                                                    }}
                                                    data-test-id="fileTestId"
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                )}

                {activeStep === 2 && (
                    <Box display="flex">
                        <Grid container padding="2rem" >
                            <Grid item md={3} xs={12}>
                                <Box>
                                    <Typography style={webStyle.keyssectiontext}>{configJSON.compliance}</Typography>
                                </Box>
                            </Grid>
                            <Grid item md={7} xs={12}>
                                <Box>
                                    <label style={webStyle.bcforguytext}>{configJSON.compExpiration}</label>
                                    <CustomDatePicker
                                        data-test-id="workerId"
                                        onChange={
                                            this.handleDateChange("workersCompExpiration")
                                        }
                                        required={true}
                                        value={workersCompExpiration}>
                                    </CustomDatePicker>
                                </Box>
                                <Box sx={webStyle.marginBorderstyle3}>
                                    <label style={webStyle.bcforguytext}>{configJSON.generalLiable}</label>
                                    <CustomDatePicker
                                        data-test-id="generalId"
                                        onChange={
                                            this.handleDateChange("generalLiabilityExpiration")
                                        }
                                        required={true}
                                        value={generalLiabilityExpiration}>
                                    </CustomDatePicker>
                                </Box>
                                <Box sx={webStyle.marginBorderstyle3}>
                                    <label style={webStyle.bcforguytext}>{configJSON.epaCertificates}</label>
                                    <CustomDatePicker
                                        data-test-id="epaId"
                                        onChange={
                                            this.handleDateChange("epaCertificationExpiration")
                                        }
                                        required={true}
                                        value={epaCertificationExpiration}>
                                    </CustomDatePicker>
                                </Box>
                                <Box sx={webStyle.marginBorderstyle3}>
                                    <label style={webStyle.bcforguytext}>{configJSON.auto}</label>
                                    <CustomDatePicker
                                        data-test-id="autoId"
                                        onChange={
                                            this.handleDateChange("autoInsuranceExpiration")
                                        }
                                        required={true}
                                        value={autoInsuranceExpiration}>
                                    </CustomDatePicker>
                                </Box>
                                <Box sx={webStyle.marginBorderstyle3}>
                                    <label style={webStyle.bcforguytext}>{configJSON.stateLicense}</label>
                                    <CustomDatePicker
                                        data-test-id="stateExpId"
                                        onChange={
                                            this.handleDateChange("stateLicenseExpiration")
                                        }
                                        required={true}
                                        value={stateLicenseExpiration}>
                                    </CustomDatePicker>
                                </Box>
                                <Box sx={webStyle.marginBorderstyle3}>
                                    <label style={webStyle.bcforguytext}>{configJSON.contract}</label>
                                    <CustomDatePicker
                                        data-test-id="contractId"
                                        onChange={
                                            this.handleDateChange("contractExpiration")
                                        }
                                        required={true}
                                        value={contractExpiration}>
                                    </CustomDatePicker>
                                </Box>
                                <Grid container marginTop="3rem" justifyContent="center" alignItems="center">
                                    <Grid item md={12} xs={12}>
                                        <Box textAlign="left">
                                            <label style={webStyle.pettextchange}>{configJSON.paperWork}</label>
                                        </Box>
                                        <Box>
                                            <FileUpload
                                                label=""
                                                name="documents"
                                                files={this.state.filesInsurance}
                                                onFilesChange={(names: string, files: FileWithPreview[]) => {
                                                    this.handleUploadInsurance(names, files);
                                                }}
                                                data-test-id="fileId"
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Box style={webStyle.marginBorderstyle3}>
                                    <label style={webStyle.bcforguytext}>{configJSON.expireDates}</label>
                                    <CustomDatePicker
                                        data-test-id="exp1Id"
                                        onChange={
                                            this.handleDateChange("expirationDate1")
                                        }
                                        required={true}
                                        value={expirationDate1}>
                                    </CustomDatePicker>
                                </Box>
                                <Grid container marginTop= "3rem" justifyContent="center" alignItems="center">
                                    <Grid item md={12} xs={12}>
                                        <label style={webStyle.pettextchange}>{configJSON.insurances}</label>
                                        <Box>
                                            <FileUpload
                                                label=""
                                                name="documents"
                                                files={this.state.licenceInsurance}
                                                onFilesChange={(names: string, files: FileWithPreview[]) => {
                                                    this.handleInsuranceLicence(names, files);
                                                }}
                                                data-test-id="fileInsuranceId"
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Box sx={webStyle.marginBorderstyle3}>
                                    <label style={webStyle.bcforguytext}>{configJSON.expireDates}</label>
                                    <CustomDatePicker
                                        data-test-id="expDateId"
                                        onChange={
                                            this.handleDateChange("expirationDate2")
                                        }
                                        required={true}
                                        value={expirationDate2}>
                                    </CustomDatePicker>
                                </Box>
                            </Grid>
                            {this.renderDividerEnrolfirst()}
                            <Grid container sx={webStyle.marginBorderstyle3}>
                                <Grid item md={3} xs={12}>
                                    <Box>
                                        <Typography sx={webStyle.keyssectiontext}>{configJSON.notes}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item md={7} xs={12}>
                                    <FormGroup>
                                        <TextField
                                            data-test-id="notesId"
                                            id="notes"
                                            variant="outlined"
                                            name="notes"
                                            multiline
                                            rows={3}
                                            helperText={
                                                <span style={{ float: "right" }}>
                                                    {notes.length}/100
                                                </span>
                                            }
                                            inputProps={{ maxLength: 100 }}
                                            value={notes}
                                            onChange={this.handleChange}
                                        />
                                    </FormGroup>
                                  </Grid>
                              </Grid>
                          </Grid>
                      </Box>
                  )}
              </Box>
          </ThemeProvider>
      )
  };
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start


            <>

                <ThemeProvider theme={theme}>
                      <AddVendor>
                            <NavigationSidebar
                                data-test-id={"sidebarMenuIds"}
                                sidebarItems={[
                                    { label: configJSON.dashboardLandlabel, icon: dashboardLandLogo, labelKey: configJSON.dashboardLandlabel, pathName: configJSON.dashboardLandlabel, activeIcon: activeMainDashboard },
                                    { label: configJSON.myAccountlandloard, icon: accountsMain, labelKey: configJSON.myAccountlandlabel, pathName: configJSON.myAccountlandlabel, activeIcon: activeMainAccount },
                                    { label: configJSON.worklandloard, icon: ordersMain, labelKey: configJSON.workOrderslandlabel, pathName: configJSON.workOrderslandlabel, activeIcon: activeMainOrder },

                                    { label: configJSON.preferredlandloard, icon: vendorMain, labelKey: configJSON.prevendorlandlabel, pathName: configJSON.preferredlandloard, activeIcon: activeMainVendor },
                                    { label: configJSON.reminderslandloard, icon: remindersMain, labelKey: configJSON.reminlandlabel, pathName: configJSON.reminlandlabel, activeIcon: activeMainReminder },
                                    { label: configJSON.myproplandloard, icon: propertyMain, labelKey: configJSON.myproplandlabel, pathName: configJSON.myproplandlabel, activeIcon: activePropertyMain },

                                    { label: configJSON.financialandloard, icon: financialMain, labelKey: configJSON.financlandlabel, pathName: configJSON.financialandloard, activeIcon: activeMainFinancial },
                                    { label: configJSON.reportlandloard, icon: reportMain, labelKey: configJSON.reportlandlabel, pathName: configJSON.reportlandloard, activeIcon: activeMainReport },
                                    { label: configJSON.chatLandloard, icon: keyMainChain, labelKey: configJSON.chatTenantabel, pathName: configJSON.chatlandlabel, activeIcon: activeKeyMain },

                                    { label: configJSON.settingLandlord, icon: settingsMain, labelKey: configJSON.settinglandlabel, pathName: configJSON.settinglandlabel, activeIcon: activeMainSetting }
                                ]}
                                onClickSidebar={this.handleMainItemClicksEdit}

                                openDrawer={this.state.openLandlordDrawersedit}
                                onClickDrawer={this.handleLandlordDrawersEdit} 
                                activeItem={this.state.activeMainItemsedit} 
                                keasylogo={keasyIcon} 
                                />
                        <Box display="flex" width="100%" alignItems="center" flexDirection="column" justifyContent="center" sx={{
                            padding: {
                                flexGrow: 1,
                                borderLeft: "1px solid rgb(208,203,199)",
                            },
                        }}>
                            <Box width={"90%"}>
                            <Grid container p={2}>
                                <Grid item xs={12}>
                                    <Box mt={2}>
                                   <Typography data-test-id="venId" style={webStyle.newproperty}>{configJSON.newVendor}</Typography>
                                    </Box>


                                </Grid>
                            </Grid>
                            <Grid container spacing={7}>
                                <Grid item xs={12} style={{ marginTop: '20px' }}>
                                    <Stepper 
                                    activeStep={this.state.activeStepedit} 
                                    alternativeLabel connector={null}
                                        sx={{
                                            "& .css-109el20-MuiStepLabel-root.MuiStepLabel-alternativeLabel": {
                                                flexDirection: "revert"

                                            },
                                        }}
                                    >
                                        {getStepsedit().map((label, index) => (
                                            <Step key={index}
                                                sx={{
                                                    "& .css-1q0s5d2-MuiStepLabel-root.MuiStepLabel-alternativeLabel": {
                                                        flexDirection: "revert"
                                                    },
                                                    "& .MuiStepLabel-root .Mui-completed": {
                                                        color: "#34D399"

                                                    },
                                                    "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel": {
                                                        color: "#000000",

                                                    },
                                                    "& .MuiStepLabel-root .Mui-active": {
                                                        color: "#CC9200"
                                                    },
                                                    "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel": {
                                                        color: "#000000",

                                                    },
                                                    "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                                                        fill: "#ffffff"
                                                    },
                                                    ".css-ea49we-MuiStep-root .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel": {
                                                        color: "#000000",

                                                    },
                                                    "& .css-109el20-MuiStepLabel-root.MuiStepLabel-alternativeLabel": {


                                                    },
                                                    "& .css-1vyamtt-MuiStepLabel-labelContainer.MuiStepLabel-alternativeLabel": {
                                                        textAlign: "left"
                                                    },
                                                    "& .css-1hv8oq8-MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
                                                        marginTop: "3px",
                                                        marginLeft: "1rem"
                                                    }
                                                }}

                                            >
                                                <StepLabel
                                                    sx={{
                                                        "& .css-109el20-MuiStepLabel-root.MuiStepLabel-alternativeLabel": {
                                                            flexDirection: "revert",


                                                        },

                                                        color: "#000000",

                                                    }}
                                                >

                                                    <span style={webStyle.stepperstyle}>   {label}</span>

                                                </StepLabel>
                                            </Step>
                                        ))}
                                    </Stepper>
                                </Grid>
                            </Grid>
                            <Container maxWidth="lg">
                                {this.getStepContentEdit(this.state.activeStepedit)}

                            </Container>
                            <Grid container spacing={4}>
                                <Grid item xs={10} style={{ marginTop: '80px', textAlign: "right" }}>
                                    <BackButton
                                        data-test-id="backbuttonIdone"
                                        variant="contained"
                                        className={this.state.activeStepedit === 0 ? "isButtonDisabled" : ""}
                                        onClick={this.handleBackMainEdit}
                                    >
                                            {configJSON.backButton}
                                        </BackButton>
                                        {this.state.activeStepedit === getStepsedit().length - 1 ? (
                                            <NextButton variant="contained"
                                                data-test-id="submitId"
                                                onClick={this.handleSubmit}>
                                                {configJSON.finish}
                                            </NextButton>
                                        ) : (
                                            <NextButton
                                                data-test-id="nextbuttonIdone"
                                                variant="contained"
                                            onClick={this.handleNextMainEdit}
                                        >
                                            {configJSON.nextButton}
                                        </NextButton>
                                    )}
                                </Grid>
                            </Grid>
                            </Box>
                            <Box className="bgImgWrapper" data-test-id="bgImgsId">
                                <Box className="backgroundImage">
                                    <img src={bottomImageIcon} alt="hexagon-add" className="hexImg" />
                                </Box>
                            </Box>
                        </Box>
                    </AddVendor>
                    <PopupMessage
                        data-test-id="popupId"
                        open={this.state.openSnack}
                        type="error"
                        message={this.state.errorMessage}
                        handleClose={this.handlePopupClose}
                    />

                </ThemeProvider>




            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    stepperstyle: {
        fontSize: "16px",
        fontWeght: 700,
        lineHeight: "24px",
        fontfamily: "outfit"
    },
    input_label: {
        fontFamily: "Outfit, sans-serif",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "22px",
    },
    newproperty: {
        fontSize: "24px",
        fontWeght: 800,
        lineHeight: "32px",
       
    },
    childWrapper: {
        paddingTop: '2rem',
    },
    inputStyle: {
        marginTop: 20,
        marginBottom: 20,
    },
    fileWrapper: {
        marginTop: 10,
        display: "flex",
        alignItems: "center",
    },
    fileName: {
        flex: 1,
    },
    submitBtnStyle: {
        marginTop: 20,
    },
    uploadBtnWrapper: {
        display: "flex",
        marginTop: 20,
    },
    uploadBtn: {
        padding: 7,
        paddingLeft: 15,
        paddingRight: 15,
        border: "none",
        backgroundColor: "rgb(98, 0, 238)",
        color: "#fff",
        borderRadius: 5,
        cursor: "pointer",
        marginRight: 15,
    },
    clearBtnStyle: {
        marginRight: 15,
    },
    bulkFileWrapper: {
        border: "1px solid #ccc",
        padding: 20,
        borderRadius: 5,
        marginBottom: 20,
    },
    bulkFileTopWrapper: {
        display: "flex",
        alignItems: "center",
    },
    textWrapper: {
        flex: 1,
    },
    fileItemWrapper: {
        display: "flex",
        border: "1px solid #ccc",
        padding: 10,
        marginTop: 15,
        borderRadius: 5,
        alignItems: "center",
    },
    textfieldmanage12: {
        '& .MuiOutlinedInput-root': {
            borderRadius: 2,
            marginTop: "1rem"
        }
    },
    textstylechange12: {
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "22px",
        fontFamily: "Outfit",
        color: 'rgba(0, 0, 0, 0.6)'
    },
    marginBorderstyle12: {
        borderRadius: "8px", marginTop: "2rem"
    },
    marginManage: {
        '& .MuiOutlinedInput-root': {
            borderRadius: 2,
            marginTop: "1rem"
        }
    },
    sitemaneger: {
        '& .MuiOutlinedInput-root': {
            borderRadius: 2,
            marginTop: "2.3rem"
        }
    },
    marginBorderstyle: {
        borderRadius: "8px", marginTop: "2rem"
    },
    labeltextsyle: {
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "22px",
        fontFamily: "Outfit",
        marginBottom: "2rem",
        color: 'rgba(0, 0, 0, 0.6)'
    },
    labeltextsyle2: {
        fontSize: "18px",
        fontWeight: 700,
        lineHeight: "17px",
        fontFamily: "Outfit",
        color: 'rgba(0, 0, 0, 0.6)'
    },
    addstyle: {
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "22px",
        fontFamily: "Outfit",
        color: '#CC9200'
    },
    labeltextsyles: {
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "22px",
        fontFamily: "Outfit",
        color: 'rgba(0, 0, 0, 0.6)'
    },
    mainWrapper: {
        display: "flex",
        fontFamily: "Roboto-Medium",
        flexDirection: "column",
        paddingBottom: "30px",
        background: "#fff",
    },
    radiocolorstyle: {
        color: "#FFC123"
    },
    marginBorderstylethree: {
        borderRadius: "8px", marginTop: "2rem"
    },
    aretextstyle: {
        height: "132px",
        marginTop: "1rem"
    },
    textfieldmanage: {
        '& .MuiOutlinedInput-root': {
            borderRadius: 2,
            marginTop: "1rem"
        }
    },
    radiocolorstyle12: {
        color: "#FFC123"
    },
    marginBorderstyle1: {
        borderRadius: "8px", marginTop: "2rem"
    },
    labeltextsylethree: {
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "22px",
        fontFamily: "Outfit",
        marginBottom: "2rem",
        color: 'rgba(0, 0, 0, 0.6)'
    },
    marginManagethree: {
        '& .MuiOutlinedInput-root': {
            borderRadius: 2,
            marginTop: "1rem"
        }
    },
    labeltextsyles12: {
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "22px",
        fontFamily: "Outfit",
        color: 'rgba(0, 0, 0, 0.6)'
    },
    textstylechange: {
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "22px",
        fontFamily: "Outfit",
        marginBottom: "2rem",
        color: 'rgba(0, 0, 0, 0.6)'
    },
    Marketingtext: {
        fontSize: "18px",
        fontWeight: 700,
        lineHeight: "17px",
        fontFamily: "Outfit",
        color: "#BAB1AB"
    },
    enroll3manage: {
        '& .MuiOutlinedInput-root': {
            borderRadius: 2,
            marginTop: "1rem"
        }
    },
    colorradio: {
        color: "#FFC123"
    },
    textstyleArea: {
        height: "132px",
    },
    flexradio: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: "1rem"
    },
    marginBorderstyle3: {
        borderRadius: "8px", marginTop: "3rem"
    },
    marginBorderstyle2: {
        borderRadius: "8px", marginTop: "1rem"
    },

    pettextchange: {
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "22px",
        fontFamily: "Outfit",
        marginBottom: "2rem"
    },
    keyssectiontext: {
        fontSize: "18px",
        fontWeight: 700,
        lineHeight: "17px",
        fontFamily: "Outfit",
        color: "#BAB1AB"

    },
    bcforguytext: {
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "22px",
        fontFamily: "Outfit",
        color: 'rgba(0, 0, 0, 0.6)'
    }
};

const BackButton = styled(Button)({
    width: "184px",
    height: "44px",
    fontFamily: "outfit",
    fontSize: "16px",
    color: "#000000",
    fontWeight: 600,
    textAlign: "center",
    borderRadius: "10px",
    backgroundColor: "#FFF1CC",
    textTransform: "none",
    "&:hover": {
        backgroundColor: "#FFF1CC"
    },
    "&.isButtonDisabled": {
        display: "none"
    }
});

const NextButton = styled(Button)({
    width: "184px",
    height: "44px",
    fontFamily: "outfit",
    fontSize: "16px",
    fontWeight: 700,
    color: "#000000",
    textAlign: "center",
    backgroundColor: "#fab03d",
    borderRadius: "8px",
    marginLeft: '1em',
    textTransform: "none",
    cursor: "pointer",
    "&:hover": {
        backgroundColor: "#fab03d"
    }
});

const StyledTextarea = styled(TextareaAutosize)`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
 
  &:focus {
    border-color: #3f51b5;
    outline: none;
  }
`;

const AddVendor = styled(Box)({
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    "@media(max-width: 992px)": {
      flexDirection: "column"
    },
    "&.webStyle": {
      backgroundColor: "red"
    },
    "& .hexImg": {
        objectFit: "cover"
    },
    "& .backgroundImage": {
      textAlign: "end",
      width: "100%"
      },
    "& .bgImgWrapper": {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: "2rem",
      width: "100%",
      "@media (max-width:992px)": {
        display: "none"
      }
    }
  });
// Customizable Area End