import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config.js");
// Customizable Area Start
const navigation = require("react-navigation");
// Customizable Area End

export interface Props {
    navigation: typeof navigation;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    activeTenantItem: string;
    openTenantDrawer: boolean;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}
export default class TenantDashboardController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
            // Customizable Area End
        ];
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            activeTenantItem: "tenantdashboard",
            openTenantDrawer: false
            // Customizable Area End
        };
    }
    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    handleTenantitemClick = (tenantKey: string) => {
        this.setState({ activeTenantItem: tenantKey });
    };

    handleTenantDrawer = () => {
        this.setState({
            openTenantDrawer: !this.state.openTenantDrawer
        });
    };

    // Customizable Area End
}
