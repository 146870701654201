import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
export interface FileWithPreview extends File {
  preview: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  activeMainNewrequest: string,
  openTenentDrawers: boolean,
  valueNew: string,
  errorNewRequest: string,
  dateOfBirthDateNewrequest: Date | null;
  dueHourNew:string,
  permissionDescription: string,
  permissionToEnter: boolean,
  cost: string,
  description:string,
  submiState:string,
  dueDate: null,
  dueHour: null,
  dueDateFrequency: string,
  remindMeOnDate: Date | null;
  remindMeOnHour: Date | null;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AutomatedworkflowController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      activeMainNewrequest: "landlordproperties",
      openTenentDrawers: false,
      valueNew: '',
      cost: "",
      dateOfBirthDateNewrequest: null,
      errorNewRequest: '',
      dueHourNew: "",
      permissionDescription: "",
      submiState:"",
      permissionToEnter: false,
      description:"",
      dueDate: null,
      dueHour: null,
      dueDateFrequency: "",
      remindMeOnDate: null,
      remindMeOnHour: null

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  
  handleMainItemClicknewrequest = (labelKey: string) => {
    this.setState({ activeMainNewrequest: labelKey });
  };
  handleTenentrequestDrawers = () => {
    this.setState({
      openTenentDrawers: !this.state.openTenentDrawers
    });
  };

  handleDateChange = (key: keyof S, newValue: Date | null) => {
  this.setState({ [key]: newValue } as unknown as Pick<S, keyof S>);
};

setFieldValue = (field: keyof S, value: string) => {
  this.setState({ [field]: value } as unknown as S);
};

setPermissionDescription = (value: string) => {
  this.setState({
    
    permissionDescription: value,
  });
};

setDescription = (value: string) => {
  this.setState({
    description: value,
  });
};

  // Customizable Area End
}
