import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React, { MouseEvent } from 'react';
interface FileWithPreview extends File {
  preview: string;
}

interface FormErrors {
  name: string;
  dateOfBirthDate: string;
  email: string;
  address: string;
  areaCode: string;
  phoneNumber: string;
  formFilesInsurance: string;
  renewalDate: string;
  brand: string;
  modelYear: string;
  vehicleReg: string;
  petName: string;
  ageYear: string;
  formFilesPet: string;
  vehicleType: string;
  petType: string;
  occupent: string;
  dateOffirstPayment: string;
  payPeriod: string;
  rent: string;
  SecurityDeposit: string;
  LateCharges: string;
  payType: string;
  collectedBy: string;
  LeaseStartDate:string;
  leaseaddress:string;
}

type ValidatedFieldValue = Date | null | FileWithPreview[];
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  activeMyproperty: string;
  openLandlordDrawer: boolean;
  formFilesInsurance: FileWithPreview[];
  formFilesPet: FileWithPreview[];
  modelYear: Date | null;
  ageYear: Date | null;
  dateOfBirthDate: Date | null;
  renewalDate: Date | null;
  name: string;
  address: string;
  email: string;
  areaCode: string;
  phoneNumber: string;
  errMsg: string;
  brand: string;
  vehicleReg: string;
  petName: string;
  vehicleType: string;
  petType: string;
  occupent: string;
  dateOffirstPayment: Date | null;
  payPeriod: string;
  rent: string;
  SecurityDeposit: string;
  LateCharges: string;
  payType: string;
  collectedBy: string;
  LeaseStartDate: Date | null;
  leaseaddress: string;
  validationErrors: {
    name?: string;
    dateOfBirthDate?: string;
    address?: string;
    email?: string;
    areaCode?: string;
    phoneNumber?: string;
    vehicleRegistrationPlate?: string;
    petType?: string;
    petName?: string;
    ageYear?: string;
    petID?: string;
    formFilesInsurance?: string;
    renewalDate?: string;
    brand?: string;
    modelYear?: string;
    vehicleReg?: string;
    formFilesPet?: string;
    vehicleType?: string;
    occupent?: string;
    dateOffirstPayment?: string;
    payPeriod?: string;
    rent?: string;
    SecurityDeposit?: string;
    LateCharges?: string;
    payType?: string;
    collectedBy?: string;
    LeaseStartDate?: string;
    leaseaddress?: string;
  };
  activeStep: number;
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class NewTenantController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start

  // Customizable Area End
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      activeMyproperty: "landlordproperties",
      openLandlordDrawer: false,
      formFilesInsurance: [],
      formFilesPet: [],
      modelYear: null,
      ageYear: null,
      dateOfBirthDate: null,
      renewalDate: null,
      name: "",
      address: "",
      email: "",
      areaCode: "",
      errMsg: "",
      dateOffirstPayment: null,
      validationErrors: {},
      phoneNumber: "",
      brand: "",
      vehicleReg: "",
      petName: "",
      vehicleType: "",
      petType: "",
      activeStep: 0,
      occupent: "",
      payPeriod: "",
      rent: "",
      SecurityDeposit: "",
      LateCharges: "",
      payType: "",
      collectedBy: "",
      LeaseStartDate: null,
      leaseaddress: ""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
   
    // Customizable Area Start
 
    // Customizable Area End
  }
  
  // Customizable Area Start

  handleMainItemClick = (labelKey: string) => {
    this.setState({ activeMyproperty: labelKey });
  };

  handleLandlordDrawer = () => {
    this.setState({
      openLandlordDrawer: !this.state.openLandlordDrawer
    });
  };

  handleDateChange = (newValue: Date | null) => {
    this.setState({ dateOfBirthDate: newValue }, ()=> {
      this.validateDateField("dateOfBirthDate", newValue)
    });
  };

  handleLeaseDateChange = (newValue: Date | null) => {
    this.setState({ LeaseStartDate: newValue }, () => {
      this.validateDateField("LeaseStartDate",newValue)
    });
  };

  handledateOffirstPayment = (newValue: Date | null) => {
    this.setState({ dateOffirstPayment: newValue },() => {
      this.validateDateField("dateOffirstPayment", newValue)
    });
  };

  handleModelYearChange = (newYearValue: Date | null) => {
    this.setState({ modelYear: newYearValue }, () => {
      this.validateDateField("modelYear",newYearValue )
    });
  };

  handleFilesChange = (name: string, files: FileWithPreview[]) => {
    this.setState({
      formFilesInsurance: files,
    } as unknown as Pick<S, keyof S>,
    () => {
      this.validateDateField("formFilesInsurance",files )
      return name
    });
  };

  handleFilesPetChange = (name: string, files: FileWithPreview[]) => {
    this.setState({
      formFilesPet: files,
    } as unknown as Pick<S, keyof S>,
    () => {
      this.validateDateField("formFilesPet",files )
      return name
    });
  };

  handleAgeYearChange = (newAgeYearValue: Date | null) => {
    this.setState({ ageYear: newAgeYearValue }, () => {
      this.validateDateField("ageYear", newAgeYearValue)
    });
  };

  handleRenewalDateChange = (newdateValue: Date | null) => {
    this.setState({ renewalDate: newdateValue }, ()=> {
      this.validateDateField("renewalDate",newdateValue)
    });
  };

  handleSelect = (vehicle: string) => {
    this.setState({ vehicleType: vehicle})
  }

  handlePet = (petName: string) => {
    this.setState({ petType: petName})
  }

  handlePayment = (payName: string) => {
    this.setState({ payType: payName})
  }
  
   onValueChange = (name: string, value: string) => {

    let isValid = true;

    if (["name", "petName"].includes(name)) {
      const isOnlyCharacters = /^[a-zA-Z]*$/.test(value); 
      isValid = isOnlyCharacters;
    }
  
    if (["areaCode", "phoneNumber", "occupent", "rent", "SecurityDeposit", "LateCharges"].includes(name)) {
      const isOnlyNumbers = /^\d*$/.test(value); 
      isValid = isOnlyNumbers && value.length <= 10;
    }
  
    if (isValid) {
      this.setState({ ...this.state, [name]: value  }, () => {
        this.validateField(name, value);
      });
    }
  };


  validateEmail = (email: string) => {
    const emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return emailRegex.test(email);
  };

  validateField = (field: string, value: string) => {
    const { validationErrors } = this.state;
    switch (field) {
      case 'name':
        validationErrors.name = value ? '' : 'Name is required';
        break;
      case 'address':
        validationErrors.address = value ? '' : 'Address is required';
        break;
      case 'email':
        validationErrors.email = value && this.validateEmail(value) ? '' : 'Valid email is required';
        break;
      case 'areaCode':
        validationErrors.areaCode = this.areaCode(validationErrors.areaCode, value);
        break;
      case 'phoneNumber':
        validationErrors.phoneNumber = this.phoneNumber(validationErrors.phoneNumber, value);
        break;
      case 'brand':
        validationErrors.brand = this.brand(validationErrors.brand, value);
        break;
      case 'vehicleReg':
        validationErrors.vehicleReg = this.vehicleReg(validationErrors.vehicleReg, value);
        break;
      case 'petName':
        validationErrors.petName = this.petName(validationErrors.petName, value);
        break;
      case 'occupent':
        validationErrors.occupent = this.occupent(validationErrors.occupent, value);
        break;
      case 'payPeriod':
        validationErrors.payPeriod = this.payPeriod(validationErrors.payPeriod, value);
        break;
      case 'rent':
        validationErrors.rent = this.rent(validationErrors.rent, value);
        break;
      case 'SecurityDeposit':
        validationErrors.SecurityDeposit = this.SecurityDeposit(validationErrors.SecurityDeposit, value);
        break;
      case 'LateCharges':
        validationErrors.LateCharges = this.LateCharges(validationErrors.LateCharges, value);
        break;
      case 'collectedBy':
        validationErrors.collectedBy = this.collectedBy(validationErrors.collectedBy, value);
        break;
      case 'leaseaddress':
        validationErrors.leaseaddress = this.leaseaddressData(validationErrors.leaseaddress, value);
        break;
      default:
        break;
    }
    this.setState({ validationErrors });
  };

  areaCode = (validationErrors: string | undefined, value: string): string => {
    if (value) {
      return ''; 
    }
    return 'Area code is required';
  }
  phoneNumber = (validationErrors: string | undefined, value: string): string => {
    if (value) {
      return ''; 
    }
    return 'Phone number is required';
  }
    brand = (validationErrors: string | undefined, value: string): string => {
      if (value) {
        return ''; 
      }
      return 'Brand is required';
    }
    vehicleReg = (validationErrors: string | undefined, value: string): string => {
      if (value) {
        return ''; 
      }
      return 'Vehicle registration plate is required';
    }
    petName = (validationErrors: string | undefined, value: string): string => {
      if (value) {
        return ''; 
      }
      return 'Pet name is required';
    }
    occupent = (validationErrors: string | undefined, value: string): string => {
      if (value) {
        return ''; 
      }
      return 'Number of occupent is required';
    }
    payPeriod = (validationErrors: string | undefined, value: string): string => {
      if (value) {
        return ''; 
      }
      return 'Pay Period is required';
    }
    rent = (validationErrors: string | undefined, value: string): string => {
      if (value) {
        return ''; 
      }
      return 'rent is required';
    }
    SecurityDeposit = (validationErrors: string | undefined, value: string): string => {
      if (value) {
        return ''; 
      }
      return 'Security Deposit is required';
    }
    LateCharges = (validationErrors: string | undefined, value: string): string => {
      if (value) {
        return ''; 
      }
      return 'Late Charges is required';
    }
    collectedBy = (validationErrors: string | undefined, value: string): string => {
      if (value) {
        return ''; 
      }
      return 'Collectedby is required';
    }
    leaseaddressData = (validationErrors: string | undefined, value: string): string => {
      if (value) {
        return ''; 
      }
      return 'Lease address is required';
    }

    validateForm = () => {
      const errors: FormErrors = this.getInitialErrors();
      const { activeStep } = this.state;
    
      if (activeStep === 0) {
        this.validateStep0(errors);
      } else if (activeStep === 1) {
        this.validateStep1(errors);
      }
    
      this.setState({ validationErrors: errors });
    
      return this.isValid(errors);
    };
    
    getInitialErrors = (): FormErrors => ({
      name: "",
      dateOfBirthDate: "",
      email: "",
      address: "",
      areaCode: "",
      phoneNumber: "",
      formFilesInsurance: "",
      renewalDate: "",
      brand: "",
      modelYear: "",
      vehicleReg: "",
      petName: "",
      ageYear: "",
      formFilesPet: "",
      vehicleType: "",
      petType: "",
      occupent: "",
      dateOffirstPayment: "",
      payPeriod: "",
      rent: "",
      SecurityDeposit: "",
      LateCharges: "",
      payType: "",
      collectedBy: "",
      LeaseStartDate: "",
      leaseaddress: ""
    });
    
    validateStep0 = (errors: FormErrors) => {
      const {
        name, dateOfBirthDate, address, email, areaCode, phoneNumber,
        formFilesInsurance, renewalDate, brand, modelYear, vehicleReg,
        petName, ageYear, formFilesPet, vehicleType, petType, occupent
      } = this.state;
    
      this.checkRequiredField(errors, 'name', name, 'Name is required');
      this.checkRequiredField(errors, 'dateOfBirthDate', dateOfBirthDate, 'Date of birth is required');
      this.checkRequiredField(errors, 'address', address, 'Address is required');
      this.checkEmailField(errors, 'email', email);
      this.checkRequiredField(errors, 'areaCode', areaCode, 'Area code is required');
      this.checkRequiredField(errors, 'phoneNumber', phoneNumber, 'Phone number is required');
      this.checkRequiredArrayField(errors, 'formFilesInsurance', formFilesInsurance, 'Insurance file is required');
      this.checkRequiredField(errors, 'renewalDate', renewalDate, 'Renewal date is required');
      this.checkRequiredField(errors, 'brand', brand, 'Brand is required');
      this.checkRequiredField(errors, 'modelYear', modelYear, 'Model year is required');
      this.checkRequiredField(errors, 'vehicleReg', vehicleReg, 'Vehicle registration plate is required');
      this.checkRequiredField(errors, 'petName', petName, 'Pet name is required');
      this.checkRequiredField(errors, 'ageYear', ageYear, 'Age year is required');
    
      this.petId(formFilesPet, errors);
      this.vehicleType(vehicleType, errors);
      this.petType(petType, errors);
      this.occupant(occupent, errors);
    };
    
    validateStep1 = (errors: FormErrors) => {
      const {
        dateOffirstPayment, payPeriod, rent, SecurityDeposit, LateCharges,
        payType, collectedBy, LeaseStartDate, leaseaddress
      } = this.state;
    
      this.checkRequiredField(errors, 'dateOffirstPayment', dateOffirstPayment, 'Date of first payment is required');
      this.checkRequiredField(errors, 'payPeriod', payPeriod, 'Pay Period is required');
      this.checkRequiredField(errors, 'rent', rent, 'Rent is required');
      this.checkRequiredField(errors, 'SecurityDeposit', SecurityDeposit, 'Security Deposit is required');
      this.checkRequiredField(errors, 'LateCharges', LateCharges, 'Late Charges is required');
      this.checkRequiredField(errors, 'payType', payType, 'Payment type is required');
      this.checkRequiredField(errors, 'collectedBy', collectedBy, 'Collected by is required');
      this.checkRequiredField(errors, 'LeaseStartDate', LeaseStartDate, 'Lease Start Date is required');
      this.checkRequiredField(errors, 'leaseaddress', leaseaddress, 'Lease address is required');
    };
    
    checkRequiredField = (errors: FormErrors, field: keyof FormErrors, value: string | Date | null, errorMessage: string) => {
      if (!value) errors[field] = errorMessage;
    };
    
    checkRequiredArrayField = (errors: FormErrors, field: keyof FormErrors, value: string | FileWithPreview[], errorMessage: string) => {
      if (!value.length) errors[field] = errorMessage;
    };
    
    checkEmailField = (errors: FormErrors, field: keyof FormErrors, value: string) => {
      if (!value && !this.validateEmail(value)) errors[field] = 'Valid email is required';
    };
    
    
    isValid = (errors: FormErrors) => {
      return (Object.keys(errors) as Array<keyof FormErrors>).every((key) => !errors[key]);
    };
  assignError = (errors: { [key: string]: string }, key: string, message: string) => {
    errors[key] = message;
  };
  
  petId = (formFilesPet: FileWithPreview[], errors: { formFilesPet: string }) => {
    if (formFilesPet.length === 0) {
      this.assignError(errors, 'formFilesPet', 'Pet ID is required');
    }
  };

  vehicleType = (data: string, errors: { vehicleType: string }) => {
    if (data.length === 0) {
      this.assignError(errors, 'vehicleType', 'Vehicle type is required');
    }
  };
  
  petType = (data: string, errors: { petType: string }) => {
    if (data.length === 0) {
      this.assignError(errors, 'petType', 'Pet type is required');
    }
  };

  occupant = (data: string, errors: { occupent: string }) => {
    if (data.length === 0) {
      this.assignError(errors, 'occupent', 'Number of occupent is required');
    }
  };
  handleSubmit = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    this.validateForm()
  };

  handleNext = () => {
    const isValid = this.validateForm();
    
    if (isValid) {
      this.setState((prevState) => ({
        activeStep: prevState.activeStep + 1,
      }));
    }
  };

  handleBackMain = () => {
    this.setState(prevState => ({
      activeStep: prevState.activeStep - 1
    }));
  };

  validateDateField = (field: string, value: ValidatedFieldValue) => {
    const { validationErrors } = this.state;
    switch (field) {
      case 'dateOfBirthDate':
        validationErrors.dateOfBirthDate = this.dateOfBirthDate(validationErrors.dateOfBirthDate, value);
        break;
      case 'renewalDate':
        validationErrors.renewalDate = value ? '' : 'Renewal date is required';
        break; 
      case 'modelYear':
        validationErrors.modelYear = this.modelYear(validationErrors.modelYear, value);
        break; 
      case 'ageYear':
        validationErrors.ageYear = this.ageYear(validationErrors.ageYear, value);
        break; 
      case 'dateOffirstPayment':
        validationErrors.dateOffirstPayment = this.dateOffirstPayment(validationErrors.dateOffirstPayment, value);
        break; 
      case 'LeaseStartDate':
        validationErrors.LeaseStartDate = this.LeaseStartDate(validationErrors.LeaseStartDate, value);
        break; 
      case 'formFilesInsurance':
        validationErrors.formFilesInsurance = this.formFilesInsurance(validationErrors.formFilesInsurance, value);
        break; 
      case 'formFilesPet':
        validationErrors.formFilesPet = this.formFilesPet(validationErrors.formFilesPet, value);
        break; 
      default:
        break;
    }
    this.setState({ validationErrors });
  };

  dateOfBirthDate = (validationErrors: string | undefined, value: ValidatedFieldValue): string => {
    if (value) {
      return ''; 
    }
    return 'Date of birth is required';
  }

  modelYear = (validationErrors: string | undefined, value: ValidatedFieldValue): string => {
    if (value) {
      return ''; 
    }
    return 'Model year is required';
  }

  ageYear = (validationErrors: string | undefined, value: ValidatedFieldValue): string => {
    if (value) {
      return ''; 
    }
    return 'Age year is required';
  }

  dateOffirstPayment = (validationErrors: string | undefined, value: ValidatedFieldValue): string => {
    if (value) {
      return ''; 
    }
    return 'Date of first payment is required';
  }

  LeaseStartDate = (validationErrors: string | undefined, value: ValidatedFieldValue): string => {
    if (value) {
      return ''; 
    }
    return 'Lease Start Date is required';
  }

  formFilesInsurance = (validationErrors: string | undefined, value: ValidatedFieldValue): string => {
    if (value?.toString()) {
      return ''; 
    }
    return 'Insurance file is required';
  }

  formFilesPet = (validationErrors: string | undefined, value: ValidatedFieldValue): string => {
    if (value?.toString()) {
      return ''; 
    }
    return 'Pet ID is required';
  }
  // Customizable Area End

}
