import React from "react";

// Customizable Area Start
import { Container, Box, Typography } from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";
import { slider1 } from "./assets";
// Customizable Area End

import CarouselDisplayController, {
  Props,
  configJSON,
} from "./CarouselDisplayController";

export default class CarouselDisplay extends CarouselDisplayController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderThumbnails() {
    return (
      <Box sx={webStyle.ellipseWrapper}>
      {this.state.imgData.map((item: any, index: number) => (
        <img
          key={index}
          src={index === this.state.index
            ? require("../assets/I135852476398241194KmTObgfmr1gx8VdhoxvtGF.svg")
            : require("../assets/I135852476398241193KmTObgfmr1gx8VdhoxvtGF.svg")}
          alt=""
          style={index === this.state.index ? webStyle.ellipseSelected : webStyle.ellipse}
        />
      ))}
    </Box>
    );
  }
  // Customizable Area End

  render() {
    console.log(slider1);
    return (
      // Customizable Area Start
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
        <Container
          sx={{ ...webStyle.carouselWrapper, backgroundImage: `url(${this.state.backgroundImage})` }}
          onTouchStart={this.handleTouchStart}
          onTouchMove={this.handleTouchMove}
          onTouchEnd={this.handleTouchEnd}
        >
            <Box sx={webStyle.carousel}>
            <button style={webStyle.carouselButton} onClick={this.handlePrev} test-id={"left-btn-test-id"}>
              <img style={webStyle.carouselImage} 
                src={require("../assets/1395231006KmTObgfmr1gx8VdhoxvtGF.svg")} 
                alt="Previous" 
              />
            </button>
              <Box sx={webStyle.carouselSlides} >
                <Box sx={webStyle.carouselContent}>
                {this.renderThumbnails()}
                  <Typography sx={webStyle.carouselHeading} test-id={"heading-test-id"}>
                    {this.state.imgData[this.state.index].heading}
                  </Typography>
                  <Typography sx={webStyle.carouselText} test-id={"text-test-id"}>
                    {this.state.imgData[this.state.index].text}
                  </Typography>
                </Box>
              </Box>
              <button style={webStyle.carouselButton} onClick={this.handleNext} test-id={"right-btn-test-id"}>
                <img
                  style={webStyle.carouselImage}
                  src={require("../assets/1395231002KmTObgfmr1gx8VdhoxvtGF.svg")}
                  alt="Next"
                />
              </button>
            </Box>
          </Container>
        </ThemeProvider>
      </StyledEngineProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const webStyle = {
  carouselWrapper: {
    height: "150%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    padding: '20px',
    backgroundPosition: "0% -40",
    "@media (width:375px)": {
      marginTop:"8rem",
    },
    "@media (max-width:600px)": {
      backgroundSize: "100% 100%",
      backgroundPosition: "center",
      // padding: '3px',
    },
    "@media (max-width:1700px)": {
      height: "150%",
    },
  },
  carousel: {
    width: '100%',
    hieght: '150%',
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  
  },
  carouselSlides: {
    backgroundColor: "#ffffff",
    width: "507px",
    height: "250px",
    borderRadius: "24px",
    textAlign: 'center',
    padding: '33px 42px 50px 42px',
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: 'column',
    "@media (max-width:600px)": {
      width: "287px",
      height: "166px",
      padding: "16px",
      margin: "0.5rem",
    },
  },
  carouselContent: {
    textAlign: 'center',
 
  "@media (width:375px)": {
    padding: "0rem",
    marginBottom: "1rem"
  },
  },
  carouselHeading: {
    fontFamily: "Outfit, sans-serif",
    fontWeight: 700,
    fontSize: '36px',
    lineHeight: '44px',
    "@media (max-width:600px)": {
      fontSize: '24px',
      lineHeight: '32px',
    },
  },
  carouselText: {
    fontFamily: "Outfit, sans-serif",
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '26px',
    marginBottom: '12px',
    "@media (max-width:600px)": {
      fontSize: '16px',
      lineHeight: '18px',
    },
  },
  ellipseWrapper: {
    width: '100%',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: '24px',
    flexDirection: 'row',
  
    "@media (max-width:600px)": {
      marginBottom: '0px',
      marginTop:'1.5rem'
    },
  },
  ellipse: {
    backgroundColor: "#d1cbc7",
    width: '4px',
    height: '4px',
    marginLeft: '5px',
    borderRadius: '50%',
  },
  ellipseSelected: {
    backgroundColor: "#cc9200",
    width: '5px',
    height: '5px',
    marginLeft: '5px',
    borderRadius: '50%',
  },
  carouselButton: {
    height: "48px",
    width: "48px",
    backgroundColor: "white",
    borderRadius: 50,
    border: "none",
  
    "@media (max-width:600px)": {
      width: "32px",
      height: "32px",
    },
  },
  carouselImage: {
    width: "24px",
    height: "24px",
  },
}