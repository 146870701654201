import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import firebase from "firebase";

// Customizable Area Start
import { SelectChangeEvent } from '@mui/material/Select'; 
import { getStorageData } from "../../../../packages/framework/src/Utilities";
import { AlertColor } from "@mui/lab";

export interface Transaction {
  id: number;
  ledger_type: string;
  amount: number;
  reference_number: string;
  details: string;
  transaction_status: string;
  transaction_date: string;
  property_id: number;
  property_name: string;
  unit_id: number;
  unit_name: string;
  sender:string;
}
interface Property {
  property_id: string,
  unit_id: number,
  name: string,
  tenants: any[],
  vendors: any[]
}
interface Unit {
  id: number;
  unit_name: string;
}
interface PropertyData {
  id: string;
  attributes: {
    property_name: string;
    units: Unit[]
  }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtInputValue:string;
  activeOrderItem: string;
  openWorkOrder: boolean;
  startDateOpen: boolean;
  endDateOpen: boolean;
  sortState: {
    [key: number]: {
      sortColumn: string;
      sortDirection: "asc" | "desc";
    };
  },
  startDate: Date | null;
  endDate: Date | null;
  allProperties: any[];
  property: string[];
  selectedDescriptions: string[];
  anchorElement: { [key: number]: HTMLElement | null };
  transactions: Transaction[];
  expanded: number | null;
  messageOpen: boolean;
  messageType: AlertColor;
  message: string;
  selectedProperty: Property;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AnalyticsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getTransactionlistCallId: string = "";
  reversePaymentId: string = "";
  completePaymentId:string="";
  getPropertyListApiCallId:string ="";
  async componentDidMount() { 
    this.getTransactionlist();
    this.getProperty();
  }
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess)
      // Customizable Area Start
      ,
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      sortState: {},
      txtInputValue:"",
      activeOrderItem: "",
      openWorkOrder: false,
      startDate:null,
      endDate:null,
      startDateOpen:false,
      endDateOpen:false,
      allProperties:[],
      property: [],
      selectedDescriptions:[],
      anchorElement: {},
      transactions: [],
      expanded: null,
      messageOpen: false,
      messageType: "success",
      message: "",
      selectedProperty: {
        property_id: "",
        unit_id: 0,
        name: "",
        tenants: [],
        vendors: []
      },
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
    if (firebase.apps.length !== 0) {
      const defaultAnalytics = firebase.app().analytics();
      defaultAnalytics.logEvent("Analytics::Web::Load");
    }
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    ); 
    if (apiRequestCallId === this.getTransactionlistCallId) {
      if (!responseJson.errors && responseJson) {
        this.setState({
          transactions:responseJson.transactions
         });
      } else if (responseJson && responseJson.errors) {
        
        alert(responseJson.errors)
      }
      };
      
        if (apiRequestCallId === this.getPropertyListApiCallId) {
          if (responseJson?.data?.length) {
            let allProperties:object[] = [];
    
            responseJson.data.map((prop: PropertyData) => {
              const prop_id = prop.id;
              const propName = prop.attributes.property_name;
              let propertyArray:object[] = [];
    
              prop.attributes.units.forEach(unit => {
                const unit_id = unit.id;
                const propertyObject = {
                  property_id: prop_id,
                  unit_id: unit_id,
                  name: propName + ' - ' + unit.unit_name,
                
                };
                
                propertyArray.push(propertyObject);
              });
              allProperties = allProperties.concat(propertyArray);
            });
            this.setState({ allProperties: allProperties });
          } else {
            //Check Error Response
            if (
              responseJson.errors &&
              responseJson.errors.length > 0 &&
              responseJson.errors[0].token
            ) {
              this.setState({
                messageOpen: true,
                messageType: "error",
                message: "Session Expired, Please Log in again."
              });
            } else {
              this.setState({
                messageOpen: true,
                messageType: "error",
                message: "Error while fetching properties."
              });
            }
          }
        }
        if (apiRequestCallId === this.completePaymentId || apiRequestCallId === this.reversePaymentId) {
          if (!responseJson.errors) {
              this.setState((prevState) => ({
                  transactions: prevState.transactions.map(transaction =>
                      transaction.id === responseJson.data.attributes.id 
                          ? { ...transaction, transaction_status: responseJson.data.attributes.transaction_status }
                          : transaction
                  )
              }));
          }
      }
      
    
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  async doButtonPressed() {
    const defaultAnalytics = firebase.app().analytics();
    defaultAnalytics.logEvent("Analytics::Web::button_clicked");
  }

  // Customizable Area Start
  handleSortRequest = (property: string, direction: "asc" | "desc", unitIndex: number) => {
    this.setState(prevState => ({
      sortState: {
        ...prevState.sortState,
        [unitIndex]: {
          sortColumn: property,
          sortDirection: direction
        }
      }
    }));
  };
  handleWorkOrderMenu = () => {
    const { activeOrderItem } = this.state;
    const toMsgWork = new Message(getName(MessageEnum.NavigationMessage));
    toMsgWork.addData(
      getName(MessageEnum.NavigationTargetMessage),
      activeOrderItem
    );
    toMsgWork.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(toMsgWork);
  };
  handleWordDrawer = () => {
    this.setState({
      openWorkOrder: !this.state.openWorkOrder
    });
  };
  handleSideNav = (navKey: string) => {
    this.setState({ activeOrderItem: navKey }, () => {
      this.handleWorkOrderMenu();
    });
  };
  
  handleDateChange =(newDate: Date | null,field:string)=>{
    if(newDate){
       if(field==="startDate"){
      this.setState({ startDate: newDate });
       }else{
      this.setState({ endDate: newDate });
       }
    }
  }
  handleOpen = (field: string) => {
    if (field === "startDate") {
        this.setState({ startDateOpen: true, endDateOpen: false });
    } else if (field === "endDate") {
        this.setState({ startDateOpen: false, endDateOpen: true });
    }
};
  handleClose = () => {
    this.setState({ startDateOpen: false,endDateOpen:false});
  };
  handleDropdown = (event: SelectChangeEvent<string[]>) => {
    const { value } = event.target;
    const selectedValues = typeof value === 'string' ? value.split(',') : value;
    this.setState({ property: selectedValues });
    const selectedProperty = this.state.allProperties.find((prop) => selectedValues.includes(prop.name));
    if (selectedProperty) {
      this.setState({ selectedProperty });
    }
  };

  handleFilter = () => {
    
  };
  handleSelectAllClick = (eventClick: React.ChangeEvent<HTMLInputElement>, unitId: number) => {
      
  };
  handleReversePayment = async(transactionId:number) => {
    const token = await getStorageData("authToken")
    const header = {
      token: token,
     'Content-Type': 'application/json',
    };
    const body = {
      data: {
        type: "reverse_payment",
        attributes: {
          transaction_id: transactionId
        }
      }
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.reversePaymentId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.reversePaymentIdAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiDataMethod
    );
   
    runEngine.sendMessage(requestMessage.id, requestMessage);

  };
  handleMarkAsComplete = async(transactionId:number) => {
    const token = await getStorageData("authToken")
    const header = {
      token: token,
      'Content-Type': 'application/json',
    };
    const body = {
      data: {
        type: "accept_payment",
        attributes: {
          transaction_id: transactionId
        }
      }
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.completePaymentId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.reversePaymentIdAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiDataMethod
    );
   
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState((prevState) => ({
      transactions: prevState.transactions.map(transaction =>
          transaction.id === transactionId ? { ...transaction, transaction_status: 'completed' } : transaction
      )
  }));
  };
  handleDeliquency = () => {
    const toMsgOpenWork = new Message(getName(MessageEnum.NavigationMessage));
    toMsgOpenWork.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "VisualAnalytics"
    );
    toMsgOpenWork.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(toMsgOpenWork);
  };
  getTransactionlist = async () => {
    const token = await getStorageData("authToken")

    const header = {
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTransactionlistCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTransactionlistAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiDataMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getProperty = async  () => {
    const token = await getStorageData("authToken")

    const header = {
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPropertyListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPropertyListAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiDataMethod
    );
 

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleNewTransaction=()=>{
    const toMsgOpenWork = new Message(getName(MessageEnum.NavigationMessage));
    toMsgOpenWork.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "NewTransaction"
    );
    toMsgOpenWork.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(toMsgOpenWork);
  }
  handleExpandClick = (rowId: number) => {
    this.setState((prevState) => ({
      expanded: prevState.expanded === rowId ? null : rowId
    }));
  };
  // Customizable Area End
}
